export default class NewCardDetails {
    constructor(
        nameOnCard,
        cardNumber,
        expirationMonth,
        expirationYear,
        cvv,
        billingAddress
    ) {
        this.name_on_card = nameOnCard;
        this.card_number = cardNumber;
        this.expiration_month = Number(expirationMonth);
        this.expiration_year = Number(expirationYear) + 2000;
        this.cvv = cvv;
        this.billing_address = billingAddress;
    }

    getDisplayName(t) {
        const cardNumber = this.card_number.toString();
        let displayName = t('billing.addCard.cardDisplayNamePrefix');
        if (cardNumber.length > 4) {
            displayName += '-';
            displayName += cardNumber.substr(cardNumber.length - 4);
        }

        return displayName;
    }
}
