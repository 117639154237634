const defaultSkip = () => {
    return false;
};

export default class WizardStep {
    static LINK_MEMBERSHIP = {
        name: 'wizard.linkMembership.header',
        shouldSkip: defaultSkip
    };

    static LINK_ACCOUNT = {
        name: 'wizard.linkAccount.header',
        shouldSkip: defaultSkip
    };

    static ALIASES = {
        name: 'wizard.aliases.header',
        path: '/wizard/step/aliases',
        shouldSkip: defaultSkip
    };

    static NOTIFICATIONS = {
        name: 'wizard.notifications.header',
        path: '/wizard/step/notifications',
        shouldSkip: defaultSkip
    };

    static TAP_ENROLLMENT = {
        name: 'wizard.tapEnrollment.header',
        path: '/wizard/step/tap',
        shouldSkip: wizardContext => {
            return !wizardContext.isAtLeastOneAccountTapEligible;
        }
    };

    static NEWSLETTER = {
        name: 'wizard.newsletter.header',
        path: '/wizard/step/newsletter',
        shouldSkip: wizardContext => {
            return wizardContext.isUserSubscribedToNewsletter;
        }
    };

    static GO_PAPERLESS = {
        name: 'wizard.goPaperless.header',
        path: '/wizard/step/paperless',
        shouldSkip: wizardContext => {
            return wizardContext.isMembershipPaperless;
        }
    };

    static AUTOMATIC_PAYMENTS = {
        name: 'wizard.automaticPayments.header',
        path: '/wizard/step/automatic-payments',
        shouldSkip: wizardContext => {
            return wizardContext.areAllAccountsEnrolledInAutomaticPayments;
        }
    };

    static COMPLETE = {
        name: 'wizard.complete.header',
        path: '/wizard/step/complete',
        shouldSkip: defaultSkip
    };
}
