import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const DialogBasic = ({ message, onCloseDialog, open, title }) => {
    const { t } = useTranslation();

    return (
        <Dialog
            open={open}
            onClose={onCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCloseDialog} color="primary">
                    {t('common.ok')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

DialogBasic.propTypes = {
    message: PropTypes.string.isRequired,
    onCloseDialog: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    title: PropTypes.string
};
