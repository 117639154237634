import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ProgressButton from 'common/components/ProgressButton';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';

class ResendEmailDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            showSuccess: false,
            processing: false
        };
    }

    render() {
        const { t } = this.props;

        const {
            open,
            processing,
            showSuccess,
        } = this.state;

        return (
            <Dialog
                open={open}
                onClose={this.close}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {showSuccess
                        ? t('login.resendVerifyEmailMessage.headerSuccess')
                        : t('login.resendVerifyEmailMessage.header')
                    }
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {showSuccess
                            ? t('login.resendVerifyEmailMessage.success')
                            : t('login.resendVerifyEmailMessage.message')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {!showSuccess && (
                        <ProgressButton onClick={this.onResend} color="primary" loading={processing}>
                            {t('login.resendVerifyEmailMessage.resendButton')}
                        </ProgressButton>
                    )}
                    <Button onClick={this.close} color="primary" variant="contained" disabled={processing}>
                        {t('common.ok')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    open() {
        this.setState({ open: true });
    }

    onResend = () => {
        const { onResend } = this.props;

        this.setState({ processing: true });
        onResend()
            .then(() => {
                this.setState({
                    showSuccess: true,
                    processing: false
                });
            })
            .catch(() => {
                this.close();
            });
    }

    close = () => {
        this.setState({
            open: false,
            processing: false,
            showSuccess: false
        });
    }
}

ResendEmailDialog.propTypes = {
    onResend: PropTypes.func.isRequired
};

export default withTranslation('translation', { withRef: true })(ResendEmailDialog);
