import { EmptyContainer, EmptyImage, EmptyMessage, ThemedFabButton, ThemedListHeader } from 'common/styles';
import AddIcon from '@material-ui/icons/Add';
import { connect } from 'react-redux';
import ContainerBase from 'common/containers/ContainerBase';
import LeftNav from 'common/components/LeftNav';
import { mapStateToProps } from 'reducers';
import MembershipList from 'features/settings/components/MembershipList';
import { navigations } from 'features/settings/containers/SettingsContainer';
import Page from 'common/components/Page';
import React from 'react';
import SuccessSnackbar from 'common/components/SuccessSnackbar';
import { withTranslation } from 'react-i18next';
import { WizardLaunchSource } from 'features/wizard/WizardContextBuilder';

class MembershipsContainer extends ContainerBase {
    constructor(props) {
        const {
            appState = props.appState || {},
            location = props.location || {}
        } = props;

        let isSnackbarOpen = false;
        let snackbarMessage = '';

        if (location.state && location.state.membershipDidUpdate) {
            isSnackbarOpen = true;
            snackbarMessage = 'settings.memberships.membershipDetails.updateSuccess';
        } else if (location.state && location.state.unlinkMembershipSuccess) {
            isSnackbarOpen = true;
            snackbarMessage = 'settings.memberships.membershipDetails.unlinkSuccess';
        }
        super(props, {
            memberships: appState.membershipsSorted && appState.membershipsSorted(props.t),
            isSnackbarOpen: isSnackbarOpen,
            snackbarMessage: snackbarMessage
        });
    }

    handleAdd = () => {
        const { history } = this.props;

        history.push('/wizard/link-membership', { launchSource: WizardLaunchSource.SETTINGS_ADD_MEMBERSHIP });
    }

    handleMembershipSelection = memberNumber => {
        const { history } = this.props;

        history.push('/settings/memberships/' + memberNumber);
    }

    handleSnackbarClose = () => {
        this.setState({ isSnackbarOpen: false });
    }

    render() {
        const { t } = this.props;

        const {
            isSnackbarOpen,
            memberships = this.state.memberships || [],
            snackbarMessage
        } = this.state;

        const hasMembershipsToDisplay = memberships.length > 0;

        return (
            <Page>
                <LeftNav title="settings.header" to="/settings" navigations={navigations}>
                    <ThemedListHeader>{t('settings.memberships.header')}</ThemedListHeader>

                    <ThemedFabButton
                        size="small"
                        color="primary"
                        aria-label={t('settings.memberships.aria.addMembership')}
                        onClick={this.handleAdd}
                    >
                        <AddIcon />
                    </ThemedFabButton>

                    {hasMembershipsToDisplay ? (
                        <MembershipList
                            memberships={memberships}
                            onClickMembership={this.handleMembershipSelection}
                            t={t}
                        />
                    ) : (
                        <EmptyContainer>
                            <EmptyImage src="/img/empty_state_default.svg" />
                            <EmptyMessage>
                                {t('common.noMembershipsLinkedMessage')}
                            </EmptyMessage>
                        </EmptyContainer>
                    )}
                    <SuccessSnackbar
                        id="snackbar"
                        open={isSnackbarOpen}
                        onClose={this.handleSnackbarClose}
                        message={t(snackbarMessage)}
                    />
                </LeftNav>
            </Page>
        );
    }
}

export default connect(mapStateToProps)(withTranslation()(MembershipsContainer));
