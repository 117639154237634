import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export const DueDate = ({ amountDueDate, amountDueDateType }) => {
    const { t } = useTranslation();

    let amountDueLabel;

    if (amountDueDateType === 'past_due') {
        amountDueLabel = t('billing.balanceMessages.pastDue');
    } else if (amountDueDateType === 'due' && !!amountDueDate) {
        amountDueLabel = t('billing.balanceMessages.upcoming', {
            dueDate: new Date(amountDueDate).toLocaleDateString('en-US', {
                month: 'short',
                day: 'numeric'
            })
        });
    } else if (amountDueDateType === 'due') {
        amountDueLabel = '';
    } else {
        amountDueLabel = t('billing.balanceMessages.noBalance');
    }

    return (
        <span>{amountDueLabel}</span>
    );
};

DueDate.propTypes = {
    amountDueDate: PropTypes.string,
    amountDueDateType: PropTypes.string
};
