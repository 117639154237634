import { ThemedInformation, ThemedTextField } from '../../../common/styles';
import BillingAddress from '../../../model/BillingAddress';
import cardValidator from 'card-validator';
import Checkbox from '@material-ui/core/Checkbox';
import CreditCardNumberField from '../../../common/components/CreditCardNumberField';
import CvvField from '../../../common/components/CvvField';
import DividerWithSubheader from '../../../common/components/DividerWithSubheader';
import ErrorMessage from '../../../common/components/ErrorMessage';
import ExpirationDateField from '../../../common/components/ExpirationDateField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import NewCardDetails from '../../../model/NewCardDetails';
import PropTypes from 'prop-types';
import React from 'react';
import { StateList } from '../../../model/StateList';
import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';
import ZipField from '../../../common/components/ZipField';

const CheckboxRow = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 16px;
`;

export const cardInputFieldNames = {
    nameOnCard: '',
    nameOnCardError: null,
    creditCard: '',
    formattedCreditCard: '',
    creditCardError: null,
    expirationDate: '',
    expirationDateError: null,
    cvv: '',
    cvvError: null,
    cardAlias: '',
    addressLine1: '',
    addressLine1Error: null,
    addressLine2: '',
    addressLine2Error: null,
    city: '',
    cityError: null,
    state: 'FL',
    stateError: null,
    zip: '',
    zipError: null,
    saveCard: false
};

export function createCardPaymentMethod(parent) {
    const {
        nameOnCard,
        creditCard,
        expirationDate,
        cvv,
        addressLine1,
        addressLine2,
        city,
        state,
        zip
    } = parent.state;

    const expirationDateObject = cardValidator.expirationDate(expirationDate);

    const expirationMonth = expirationDateObject.month;
    const expirationYear = expirationDateObject.year;

    const billingAddress = new BillingAddress(
        addressLine1,
        addressLine2,
        city,
        state,
        zip
    );

    return new NewCardDetails(
        nameOnCard,
        creditCard,
        expirationMonth,
        expirationYear,
        cvv,
        billingAddress
    );
}

export default function CardTextFields(props) {
    const { parent, t, alwaysShowAlias, showSaveCheckbox } = props;

    /**
     * As the value of the credit card input changes, capture both the
     * formatted and raw values and update the parent state.
     *
     * @param event The React SyntheticEvent.
     */
    function creditCardValueChange(event) {
        parent.setState({
            formattedCreditCard: event.target.value,
            creditCard: event.target.rawValue
        });
    }

    /**
     * As the value of the credit card CVV input changes, capture the value and
     * update the parent state.
     *
     * @param event The React SyntheticEvent.
     */
    function cvvValueChange(event) {
        parent.setState({
            cvv: event.target.value
        });
    }

    /**
     * As the value of the expiration date input changes, capture the value and
     * update the parent state.
     *
     * @param event The React SyntheticEvent.
     */
    function expirationDateValueChange(event) {
        parent.setState({
            expirationDate: event.target.value
        });
    }

    /**
     * As the value of the credit card CVV input changes, capture the value and
     * update the parent state.
     *
     * @param event The React SyntheticEvent.
     */
    function zipValueChange(event) {
        parent.setState({
            zip: event.target.value
        });
    }

    const handleInputChange = name => event => {
        parent.setState({ [name]: event.target.value });
    };

    const handleCheckboxChange = name => event => {
        parent.setState({ [name]: event.target.checked });
    };

    return (
        <>
            <ThemedTextField
                id="nameOnCard"
                inputRef={parent.nameOnCardFocus}
                label={t('billing.paymentMethods.creditCard.nameOnCard')}
                type="text"
                value={parent.state.nameOnCard}
                disabled={parent.state.processing}
                onChange={handleInputChange('nameOnCard')}
                error={parent.state.nameOnCardError !== null}
            />
            <ErrorMessage resourceId={parent.state.nameOnCardError} />

            <CreditCardNumberField
                id="creditCard"
                inputRef={parent.creditCardFocus}
                label={t('billing.paymentMethods.creditCard.card')}
                value={parent.state.creditCard}
                onChange={creditCardValueChange}
                disabled={parent.state.processing}
                error={parent.state.creditCardError !== null}
            />
            <ErrorMessage resourceId={parent.state.creditCardError} />

            <ExpirationDateField
                id="expirationDate"
                inputRef={parent.expirationDateFocus}
                label={t('billing.paymentMethods.creditCard.expirationDate')}
                value={parent.state.expirationDate}
                onChange={expirationDateValueChange}
                disabled={parent.state.processing}
                error={parent.state.expirationDateError !== null}
            />
            <ErrorMessage resourceId={parent.state.expirationDateError} />

            <CvvField
                id="cvv"
                inputRef={parent.cvvFocus}
                label={t('billing.paymentMethods.creditCard.cvv')}
                value={parent.state.cvv}
                disabled={parent.state.processing}
                onChange={cvvValueChange}
                error={parent.state.cvvError !== null}
            />
            <ErrorMessage resourceId={parent.state.cvvError} />

            {showSaveCheckbox && (
                <CheckboxRow>
                    <FormControlLabel
                        control={
                            <Checkbox
                                color="primary"
                                checked={parent.state.saveCard}
                                disabled={parent.state.processing}
                                onChange={handleCheckboxChange('saveCard')}
                                value="true"
                            />
                        }
                        label={t('billing.paymentMethods.saveDetails.label')}
                    />
                    <Tooltip
                        title={t('billing.paymentMethods.saveDetails.info')}
                        placement="right"
                    >
                        <ThemedInformation />
                    </Tooltip>
                </CheckboxRow>
            )}

            {(alwaysShowAlias || parent.state.saveCard) && (
                <ThemedTextField
                    id="cardAlias"
                    inputRef={parent.cardAliasFocus}
                    label={t('billing.paymentMethods.creditCard.alias')}
                    type="text"
                    value={parent.state.cardAlias}
                    disabled={parent.state.processing}
                    onChange={handleInputChange('cardAlias')}
                />
            )}

            <DividerWithSubheader resourceId="Billing Address" />

            <ThemedTextField
                id="addressLine1"
                inputRef={parent.addressLine1Focus}
                label={t('billing.paymentMethods.address.line1')}
                type="text"
                value={parent.state.addressLine1}
                disabled={parent.state.processing}
                onChange={handleInputChange('addressLine1')}
                error={parent.state.addressLine1Error !== null}
                inputProps={{ maxLength: 150 }}
            />
            <ErrorMessage resourceId={parent.state.addressLine1Error} />

            <ThemedTextField
                id="addressLine2"
                inputRef={parent.addressLine2Focus}
                label={t('billing.paymentMethods.address.line2')}
                type="text"
                value={parent.state.addressLine2}
                disabled={parent.state.processing}
                onChange={handleInputChange('addressLine2')}
                error={parent.state.addressLine2Error !== null}
                inputProps={{ maxLength: 150 }}
            />
            <ErrorMessage resourceId={parent.state.addressLine2Error} />

            <ThemedTextField
                id="city"
                inputRef={parent.cityFocus}
                label={t('billing.paymentMethods.address.city')}
                value={parent.state.city}
                disabled={parent.state.processing}
                onChange={handleInputChange('city')}
                error={parent.state.cityError !== null}
                inputProps={{ maxLength: 150 }}
            />
            <ErrorMessage resourceId={parent.state.cityError} />

            <ThemedTextField
                select
                id="state"
                inputRef={parent.stateFocus}
                label={t('billing.paymentMethods.address.state')}
                value={parent.state.state}
                disabled={parent.state.processing}
                onChange={handleInputChange('state')}
                error={parent.state.stateError !== null}
            >
                {StateList.map(it => (
                    <MenuItem key={it.code} value={it.code}>
                        {it.state}
                    </MenuItem>
                ))}
            </ThemedTextField>
            <ErrorMessage resourceId={parent.state.stateError} />

            <ZipField
                id="zip"
                inputRef={parent.zipFocus}
                label={t('billing.paymentMethods.address.zip')}
                type="text"
                value={parent.state.zip}
                disabled={parent.state.processing}
                onChange={zipValueChange}
                error={parent.state.zipError !== null}
            />
            <ErrorMessage resourceId={parent.state.zipError} />
        </>
    );
}

CardTextFields.propTypes = {
    parent: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    alwaysShowAlias: PropTypes.bool.isRequired,
    showSaveCheckbox: PropTypes.bool.isRequired
};
