import CheckTextFields, { checkingInputFieldNames } from './CheckTextFields';
import styled, { materialUiTheme } from '../../../theme';
import Button from '@material-ui/core/Button';
import ContainerBase from '../../../common/containers/ContainerBase';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import NewCheckDetails from '../../../model/NewCheckDetails';
import PropTypes from 'prop-types';
import React from 'react';
import Validator from '../../../validation/Validator';
import { withTranslation } from 'react-i18next';

const SingleColumnForm = styled.div`
    width: 360px;
    ${materialUiTheme.breakpoints.down('xs')} {
        max-width: 360px;
        width: auto;
    }
`;

class NewCheckDialog extends ContainerBase {
    constructor(props) {
        super(props, {
            open: false,
            ...checkingInputFieldNames
        });

        this.validator = new Validator(this);
    }

    componentDidMount() {
        this.validator.maybeFocus();
    }

    componentDidUpdate() {
        this.validator.maybeFocus();
    }

    render() {
        const { t } = this.props;
        const { open } = this.state;

        return (
            <Dialog
                open={open}
                onClose={this.close}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{t('billing.enterCheck.header')}</DialogTitle>
                <DialogContent>
                    <SingleColumnForm>
                        <CheckTextFields
                            parent={this}
                            t={t}
                            alwaysShowAlias={false}
                            showSaveCheckbox={false}
                        />
                    </SingleColumnForm>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.close} color="primary">
                        {t('common.cancel')}
                    </Button>
                    <Button onClick={this.onSave} variant="contained" color="primary">
                        {t('common.saveButton')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    open() {
        this.setState({ open: true });
    }

    onSave = () => {
        const { onSave } = this.props;

        if (!this.validator.validate()) {
            return;
        }

        const {
            nameOnAccount,
            accountNumber,
            routingNumber,
            accountType
        } = this.state;

        const newCheckDetail = new NewCheckDetails(
            nameOnAccount,
            accountNumber,
            routingNumber,
            accountType
        );

        this.setState({ open: false });
        onSave(newCheckDetail);
    }

    configureValidations(config) {
        config.requireNotEmpty('nameOnAccount');
        config.requireRoutingNumber('routingNumber');
        config.requireConfirmRoutingNumber(
            'routingNumberConfirm',
            'routingNumber'
        );
        config.requireNotEmpty('accountNumber');
        config.requireConfirmAccountNumber(
            'accountNumberConfirm',
            'accountNumber'
        );
        config.requireNotEmpty('accountType');
    }

    close = () => {
        this.setState({ open: false });
    }
}
NewCheckDialog.propTypes = {
    onSave: PropTypes.func.isRequired
};

export default withTranslation('translation', { withRef: true })(NewCheckDialog);
