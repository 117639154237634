import React, { Fragment } from 'react';
import { SingleColumnForm, ThemedTextField } from 'common/styles';
import Account from 'model/Account';
import Checkbox from '@material-ui/core/Checkbox';
import { connect } from 'react-redux';
import ContainerBase from 'common/containers/ContainerBase';
import { DialogBasic } from 'common/components/dialogs';
import DividerWithSubheader from 'common/components/DividerWithSubheader';
import ErrorMessage from 'common/components/ErrorMessage';
import FireflyAuthenticatedApi from 'api/FireflyAuthenticatedApi';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import { mapStateToProps } from 'reducers';
import Membership from 'model/Membership';
import { NavButton } from 'common/components/nav-button';
import ProgressButton from 'common/components/ProgressButton';
import SettingsPage from 'features/settings/components/SettingsPage';
import styled from 'styled-components';
import Validator from 'validation/Validator';
import { withTranslation } from 'react-i18next';

const ButtonRow = styled.div`
    display: flex;
    justify-content: flex-start;
    margin-top: 16px;
`;
const CancelButton = styled(NavButton)`
    && {
        margin-left: 20px;
    }
`;
const SaveButton = styled(ProgressButton)`
    && {
        width: 100px;
    }
`;

class AddAuthorizedUserContainer extends ContainerBase {
    constructor(props) {
        const {
            appState = props.appState || {},
            dispatch,
        } = props;

        super(props, {
            email: '',
            emailError: null,
            processing: false,
            errorMessageTitle: '',
            errorMessageBody: ''
        });

        const memberships = appState.memberships;
        const accounts = appState.accounts || [];
        const selectableAccounts = accounts.filter(it => it.canGrantToOthers);

        this.state.membershipSelections = memberships.map(() => false);
        this.state.accountSelections = selectableAccounts.map(() => false);

        this.api = new FireflyAuthenticatedApi({ appState, dispatch });

        this.validator = new Validator(this);
        this.memberships = memberships || [];
        this.accounts = selectableAccounts;
    }

    componentDidMount() {
        this.validator.maybeFocus();
    }

    componentDidUpdate() {
        this.validator.maybeFocus();
    }

    configureValidations(config) {
        config.requireEmail('email');
    }

    render() {
        const { t } = this.props;

        const {
            accountSelections,
            basicDialogIsOpen,
            email,
            emailError,
            errorMessageBody,
            errorMessageTitle,
            membershipSelections,
            processing
        } = this.state;

        return (
            <SettingsPage title="settings.addAuthorizedUsers.header" select="/settings/authorized-users">
                <Fragment>
                    <SingleColumnForm>
                        <ThemedTextField
                            id="email"
                            inputRef={this.emailFocus}
                            label={t('createUser.labelNewEmail')}
                            type="email"
                            value={email}
                            disabled={processing}
                            onChange={this.handleInputChange('email')}
                            error={emailError !== null}
                        />
                        <ErrorMessage resourceId={emailError} />

                        <DividerWithSubheader resourceId="settings.memberships.header" />
                        <FormGroup>
                            {this.memberships.map((it, i) => (
                                <FormControlLabel
                                    key={it.memberNumber}
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={membershipSelections[i]}
                                            onChange={this.handleMembershipCheckboxChange(i)}
                                            value="true"
                                            disabled={processing}
                                        />
                                    }
                                    label={this.getMembershipAlias(t, it)}
                                />
                            ))}
                        </FormGroup>

                        <DividerWithSubheader resourceId="settings.accounts.header" />

                        <FormGroup>
                            {this.accounts.map((it, i) => (
                                <FormControlLabel
                                    key={it.accountNumber}
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={accountSelections[i]}
                                            onChange={this.handleAccountCheckboxChange(i)}
                                            value="true"
                                            disabled={processing}
                                        />
                                    }
                                    label={this.getAccountAlias(t, it)}
                                />
                            ))}
                        </FormGroup>
                        <ButtonRow>
                            <SaveButton
                                variant="contained"
                                color="primary"
                                type="submit"
                                onClick={this.handleOnSubmit}
                                loading={processing}
                            >
                                {t('common.saveButton')}
                            </SaveButton>

                            <CancelButton color="primary" to="/settings/authorized-users" disabled={processing}>
                                {t('common.cancel')}
                            </CancelButton>
                        </ButtonRow>
                    </SingleColumnForm>
                    <DialogBasic
                        message={t(errorMessageBody)}
                        onCloseDialog={this.closeBasicDialog}
                        open={basicDialogIsOpen}
                        title={t(errorMessageTitle)}
                    />
                </Fragment>
            </SettingsPage>
        );
    }

    handleInputChange = name => ({ target } = {}) => {
        this.setState({ [name]: target.value });
    }

    handleMembershipCheckboxChange = name => ({ target } = {}) => {
        const { membershipSelections } = this.state;

        const updated = membershipSelections;
        updated[name] = target.checked;
        this.setState({ memberships: updated });
    }
    handleAccountCheckboxChange = name => ({ target } = {}) => {
        const { accountSelections } = this.state;

        const updated = accountSelections;
        updated[name] = target.checked;
        this.setState({ accounts: updated });
    }

    getMembershipAlias(t, membership) {
        const _membership = new Membership(membership);
        return _membership.getDisplayName(t);
    }

    getAccountAlias(t, account) {
        const _account = new Account(account);
        return _account.getDisplayName(t);
    }

    handleOnSubmit = () => {
        const { history } = this.props;

        const {
            accountSelections,
            email,
            membershipSelections,
        } = this.state;

        if (!this.validator.validate()) {
            return;
        }

        if (!membershipSelections.some(it => it) && !accountSelections.some(it => it)) {
            this.setState({
                errorMessageTitle:
                    'settings.authorizedUsers.errors.accountSelectionRequiredTitle',
                errorMessageBody:
                    'settings.authorizedUsers.errors.accountSelectionRequiredBody'
            });
            this.showBasicDialog();
            return;
        }

        this.setState({
            processing: true,
            emailError: null
        });

        const promises = [];

        membershipSelections.forEach((it, i) => {
            if (it) {
                promises.push(
                    this.api.addAuthorizedUser(
                        email,
                        this.memberships[i].memberNumber,
                        null
                    )
                );
            }
        });

        accountSelections.forEach((it, i) => {
            if (it) {
                promises.push(
                    this.api.addAuthorizedUser(
                        email,
                        null,
                        this.accounts[i].accountNumber
                    )
                );
            }
        });

        Promise.all(promises)
            .then(responses => {
                this.setState({ processing: false });
                const someAlreadyLinked =
                    responses.indexOf(204) >= 0;
                if (someAlreadyLinked) {
                    this.setState({
                        processing: false,
                        errorMessageTitle:
                            'settings.authorizedUsers.errors.alreadyLinkedTitle',
                        errorMessageBody:
                            'settings.authorizedUsers.errors.alreadyLinkedBody'
                    });
                    this.showBasicDialog();
                } else {
                    history.push('/settings/authorized-users', { isAddSnackbarOpen: true });
                }
            })
            .catch(error => {
                this.setState({ processing: false });
                if (
                    error.response.code === 422 &&
                    error.response.errors.some(
                        it =>
                            it.field === 'target_email' &&
                            it.error_code === 'not_registered'
                    )
                ) {
                    this.setState({ emailError: 'settings.authorizedUsers.errors.userNotRegistered' });
                } else {
                    this.showErrorDialog(error);
                }
            });
    }
}

export default connect(mapStateToProps)(withTranslation()(AddAuthorizedUserContainer));
