import { ContinueButton, Intro } from 'features/wizard/styles';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import ContainerBase from 'common/containers/ContainerBase';
import FireflyAuthenticatedApi from 'api/FireflyAuthenticatedApi';
import { mapStateToProps } from 'reducers';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'theme';
import { withTranslation } from 'react-i18next';
import WizardStep from 'features/wizard/components/WizardStep';

const PaperlessImage = styled.img`
    height: 154px;
    margin-top: 10px;
    margin-bottom: 20px;
`;

const NoThanksButton = styled(Button)`
    && {
        min-width: 180px;
        margin-top: 16px;
    }
`;

class WizardGoPaperlessContainer extends ContainerBase {
    onContinue = () => {
        const { onContinue } = this.props;

        onContinue();
    };

    render() {
        const { t } = this.props;
        const { loading } = this.state;

        return (
            <WizardStep heading="wizard.goPaperless.header">
                <PaperlessImage src="/img/wizard_paperless.svg" />
                <Intro>{t('wizard.goPaperless.intro')}</Intro>
                <ContinueButton
                    key="continue"
                    variant="contained"
                    color="primary"
                    loading={loading}
                    onClick={this.saveAndContinue}
                >
                    {t('wizard.goPaperless.buttonContinue')}
                </ContinueButton>
                <NoThanksButton
                    key="skip"
                    variant="contained"
                    color="secondary"
                    onClick={this.onContinue}
                >
                    {t('wizard.goPaperless.buttonSkip')}
                </NoThanksButton>
            </WizardStep>
        );
    };

    saveAndContinue = () => {
        const {
            appState = this.props.appState || {},
            dispatch,
            onContinue
        } = this.props;
        this.setState({ loading: true });
        const api = new FireflyAuthenticatedApi({ appState, dispatch });

        const wizardContext = appState.wizardContext || {};
        const memberNumber = wizardContext.memberNumberAddedByWizard;
        api.updatePaperlessBillingSetting(memberNumber, true)
            .then(() => {
                onContinue();
            })
            .catch(error => {
                this.setState({ loading: false });
                this.showErrorDialog(error);
            });
    }
}

WizardGoPaperlessContainer.propTypes = {
    onContinue: PropTypes.func.isRequired,
    wizardContext: PropTypes.object.isRequired,
    orientation: PropTypes.string.isRequired
};

export default connect(mapStateToProps)(withTranslation()(WizardGoPaperlessContainer));
