import { detect } from 'detect-browser';

// these are the minimum supported major versions of browsers.
// browser identifiers are defined here:
// https://github.com/DamonOehlman/detect-browser/blob/master/src/index.ts
const minSupportedBrowserVersions = {
    chrome: 21,
    edge: 12,
    firefox: 28,
    ie: 11,
    opera: 12,
    safari: 7
};

function checkBrowser() {
    const browser = detect();
    const version = browser.version.split('.');
    const majorVersion = Number(version[0]) || 0;

    let unsupported = false;

    if (browser.name) {
        const minSupportedVersion = minSupportedBrowserVersions[browser.name];
        if (minSupportedVersion && majorVersion < minSupportedVersion) {
            unsupported = true;
        }
    }

    return {
        ...browser,
        majorVersion,
        unsupported
    };
}

export default checkBrowser;
