import { SingleColumnForm, ThemedPageHeader, ThemedTextField } from 'common/styles';
import { updateProfile, updateSmsNumber } from 'actions';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Checkbox from '@material-ui/core/Checkbox';
import { connect } from 'react-redux';
import ContainerBase from 'common/containers/ContainerBase';
import { DialogConfirm } from 'common/components/dialogs';
import DividerWithSubheader from 'common/components/DividerWithSubheader';
import ErrorMessage from 'common/components/ErrorMessage';
import FireflyAuthenticatedApi from 'api/FireflyAuthenticatedApi';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import IconButton from '@material-ui/core/IconButton';
import LeftNav from 'common/components/LeftNav';
import { Link } from 'react-router-dom';
import LoadingIndicator from 'common/components/LoadingIndicator';
import { mapStateToProps } from 'reducers';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { NavButton } from 'common/components/nav-button';
import { navigations } from 'features/settings/containers/SettingsContainer';
import Page from 'common/components/Page';
import PencilIcon from '@material-ui/icons/Edit';
import ProgressButton from 'common/components/ProgressButton';
import React from 'react';
import styled from 'theme';
import SuccessSnackbar from 'common/components/SuccessSnackbar';
import { ThemedInformation } from '../../../common/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Validator from 'validation/Validator';
import { withTranslation } from 'react-i18next';

const ChangeButton = styled(NavButton)``;
const ButtonRow = styled.div`
    display: flex;
    justify-content: flex-start;
    margin-top: 16px;
`;
const CancelButton = styled(NavButton)`
    && {
        margin-left: 20px;
    }
`;
const SaveButton = styled(ProgressButton)`
    && {
        width: 100px;
    }
`;

const CheckboxRow = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

function SmsActionButton(props) {
    const {
        smsPhoneNumber,
        container = props.container || {},
        t
    } = props;

    if (smsPhoneNumber !== null) {
        return (
            <div>
                <Tooltip title={t('common.tooltips.edit')} placement="bottom">
                    <IconButton
                        aria-owns="sms-menu"
                        aria-label={t('settings.userProfile.aria.editSmsPhoneNumberLabel')}
                        color="default"
                        onClick={container.handleSmsButtonClick}
                    >
                        <PencilIcon />
                    </IconButton>
                </Tooltip>

                <span>{smsPhoneNumber}</span>
            </div>
        );
    } else {
        return (
            <div>
                <Tooltip title={t('common.tooltips.add')} placement="bottom">
                    <IconButton
                        aria-owns="sms-menu"
                        aria-label={t('settings.userProfile.aria.addSmsPhoneNumberLabel')}
                        color="default"
                        component={React.forwardRef((props, ref) => (<Link {...props} ref={ref}/>))}
                        to="/settings/user-profile/update-sms"
                    >
                        <AddCircleIcon />
                    </IconButton>
                </Tooltip>

                <span>{t('settings.userProfile.smsPhoneNumberNotSet')}</span>
            </div>
        );
    }
}

class UserProfileContainer extends ContainerBase {
    constructor(props) {
        const {
            appState = props.appState || {},
            dispatch,
            location = props.location || {},
            t
        } = props;

        super(props, {
            isSnackbarOpen: false,
            snackBarMessage: '',
            smsAnchorEl: null,
            firstNameError: null,
            lastNameError: null,
            loadingNotificationPreferences: true,
            pushNotifications: false,
            emailNotifications: false,
            smsMenuValue: null
        });

        const user = appState.user || {};
        this.state.firstName = user.firstName;
        this.state.lastName = user.lastName;
        this.state.newsletter = user.newsletter;
        this.state.smsPhoneNumber = user.smsPhoneNumberFormatted;

        this.validator = new Validator(this);
        this.api = new FireflyAuthenticatedApi({ appState, dispatch });

        if (location.state) {
            if (location.state && location.state.showSmsUpdateSuccessSnackbar) {
                this.state.isSnackbarOpen = true;
                this.state.snackBarMessage = t('settings.userProfile.smsUpdatedSuccessMessage');
            }

            if (location.state && location.state.showChangePasswordSuccessSnackbar) {
                this.state.isSnackbarOpen = true;
                this.state.snackBarMessage = t('settings.changePassword.successMessage');
            }

            if (location.state && location.state.showChangeEmailSuccessSnackbar) {
                this.state.isSnackbarOpen = true;
                this.state.snackBarMessage = t('settings.changeEmail.successMessage');
            }
        }
    }

    componentDidMount() {
        this.loadNotificationPreferences();
    }

    componentDidUpdate() {
        this.validator.maybeFocus();
    }

    render() {
        const { t } = this.props;

        const {
            confirmDialogIsOpen,
            emailNotifications,
            firstName,
            firstNameError,
            isSnackbarOpen,
            lastName,
            lastNameError,
            loading,
            loadingNotificationPreferences,
            newsletter,
            pushNotifications,
            smsAnchorEl,
            smsPhoneNumber,
            snackBarMessage
        } = this.state;

        return (
            <Page>
                <LeftNav title="settings.header" to="/settings" navigations={navigations}>
                    <ThemedPageHeader>{t('settings.userProfile.header')}</ThemedPageHeader>
                    {loadingNotificationPreferences ? (
                        <LoadingIndicator />
                    ) : (
                        <SingleColumnForm>
                            <ThemedTextField
                                id="firstName"
                                inputRef={this.firstNameFocus}
                                type="text"
                                label={t('createUser.labelFirstName')}
                                disabled={loading}
                                value={firstName}
                                onChange={this.handleInputChange('firstName')}
                                error={firstNameError !== null}
                            />
                            <ErrorMessage resourceId={firstNameError} />

                            <ThemedTextField
                                id="lastName"
                                inputRef={this.lastNameFocus}
                                type="text"
                                label={t('createUser.labelLastName')}
                                disabled={loading}
                                value={lastName}
                                onChange={this.handleInputChange('lastName')}
                                error={lastNameError !== null}
                            />
                            <ErrorMessage resourceId={lastNameError} />

                            <div>
                                <ChangeButton
                                    color="primary"
                                    disabled={loading}
                                    to="/settings/user-profile/change-email"
                                >
                                    {t('settings.changeEmail.header')}
                                </ChangeButton>
                            </div>

                            <div>
                                <ChangeButton
                                    color="primary"
                                    disabled={loading}
                                    to="/settings/user-profile/change-password"
                                >
                                    {t('settings.changePassword.header')}
                                </ChangeButton>
                            </div>

                            <DividerWithSubheader resourceId="settings.userProfile.notificationsHeader" />

                            <FormGroup>
                                <CheckboxRow>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                color="primary"
                                                checked={pushNotifications}
                                                disabled={loading}
                                                onChange={this.handleCheckboxChange('pushNotifications')}
                                                value="true"
                                            />
                                        }
                                        label={t('notificationDeliveryMethods.push')}
                                    />
                                    <Tooltip title={t('settings.pushNotifications')} placement="right">
                                        <ThemedInformation />
                                    </Tooltip>
                                </CheckboxRow>

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={emailNotifications}
                                            disabled={loading}
                                            onChange={this.handleCheckboxChange('emailNotifications')}
                                            value="true"
                                        />
                                    }
                                    label={t('notificationDeliveryMethods.email')}
                                />
                            </FormGroup>

                            <DividerWithSubheader resourceId="settings.userProfile.newsletterHeader" />

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={newsletter}
                                        disabled={loading}
                                        onChange={this.handleCheckboxChange('newsletter')}
                                        value="true"
                                    />
                                }
                                label={
                                    <span>
                                        {/*TODO: support producing the string: Email me <em>The Current</em> */}
                                        {t('settings.userProfile.newsletterLabel')}
                                    </span>
                                }
                            />

                            <DividerWithSubheader resourceId="settings.userProfile.smsNotificationsHeader" />

                            <SmsActionButton container={this} smsPhoneNumber={smsPhoneNumber} t={t}/>

                            <ButtonRow>
                                <SaveButton
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    loading={loading}
                                    onClick={this.submit}
                                >
                                    {t('common.saveButton')}
                                </SaveButton>

                                <CancelButton color="primary" disabled={loading} to="/settings">
                                    {t('common.cancel')}
                                </CancelButton>
                            </ButtonRow>

                            <Menu
                                id="sms-menu"
                                anchorEl={smsAnchorEl}
                                open={Boolean(smsAnchorEl)}
                                onClose={this.handleSmsMenuClose}
                            >
                                <MenuItem
                                    value="update"
                                    onClick={e => this.handleSmsMenuItemClick('update', e)}
                                >
                                    {t('settings.userProfile.smsMenuUpdateOption')}
                                </MenuItem>
                                <MenuItem
                                    value="delete"
                                    onClick={e => this.handleSmsMenuItemClick('delete', e)}
                                >
                                    {t('settings.userProfile.smsMenuDeleteOption')}
                                </MenuItem>
                            </Menu>

                            <SuccessSnackbar
                                id="user-profile-snackbar"
                                open={isSnackbarOpen}
                                onClose={this.handleSnackbarClose}
                                message={snackBarMessage}
                            />
                        </SingleColumnForm>
                    )}
                </LeftNav>
                <DialogConfirm
                    confirmButtonLabel={t('settings.userProfile.confirmDeleteSmsModal.confirmButton')}
                    message={t('settings.userProfile.confirmDeleteSmsModal.message')}
                    onCloseDialog={this.closeConfirmDialog}
                    open={confirmDialogIsOpen}
                    title={t('settings.userProfile.confirmDeleteSmsModal.title')}
                />
            </Page>
        );
    }

    handleInputChange = name => ({ target } = {}) => {
        this.setState({ [name]: target.value });
    }

    handleCheckboxChange = name => ({ target } = {}) => {
        this.setState({ [name]: target.checked });
    }

    handleSmsButtonClick = ({ currentTarget } = {}) => {
        this.setState({ smsAnchorEl: currentTarget });
    }

    handleSmsMenuItemClick = value => {
        const { history } = this.props;

        this.setState({ smsAnchorEl: null });

        if (value === 'update') {
            history.push('/settings/user-profile/update-sms');
        } else if (value === 'delete') {
            this.showConfirmDialog();
        }
    }

    handleSmsMenuClose = () => {
        this.setState({ smsAnchorEl: null });
    }

    handleSnackbarClose = () => {
        this.setState({
            isSnackbarOpen: false,
            snackBarMessage: ''
        });
    }

    configureValidations(config) {
        config.requireNotEmpty('firstName');
        config.requireNotEmpty('lastName');
    }

    onConfirmDialogClosed(wasConfirmed) {
        if (wasConfirmed) {
            this.deleteSmsPhoneNumber();
        }
    }

    loadNotificationPreferences = () => {
        this.api
            .getAnnouncementNotifications()
            .then(response => {
                const deliveyMethods = response[0].selected_delivery_methods;

                this.setState({
                    loadingNotificationPreferences: false,
                    pushNotifications: deliveyMethods.includes('push'),
                    emailNotifications: deliveyMethods.includes('email')
                });
            })
            .catch(error => {
                this.setState({ loadingNotificationPreferences: false });
                this.showErrorDialog(error);
            });
    }

    submit = e => {
        const {
            emailNotifications,
            firstName,
            lastName,
            newsletter,
            pushNotifications,
        } = this.state;

        const {
            dispatch,
            t
        } = this.props;

        e.preventDefault();

        if (!this.validator.validate()) {
            return;
        }

        this.setState({ loading: true });
        this.api
            .updateProfile(firstName, lastName, newsletter, navigator.language)
            .then(() => {
                this.api.updateAnnouncementNotifications(pushNotifications, emailNotifications);
            })
            .then(() => {
                dispatch(
                    updateProfile(firstName, lastName, newsletter)
                );

                this.setState({
                    loading: false,
                    isSnackbarOpen: true,
                    snackBarMessage: t('settings.userProfile.successMessage')
                });
            })
            .catch(error => {
                // sb: there's no real validation for updating a profile, as the
                // API will only update the fields specified. our client-side
                // validation should cover everything.
                this.setState({ loading: false });
                this.showErrorDialog(error);
            });
    }

    deleteSmsPhoneNumber = () => {
        const {
            dispatch,
            t
        } = this.props;

        this.setState({ loading: true });
        this.api
            .removePhoneNumber()
            .then(() => {
                dispatch(updateSmsNumber(null));

                this.setState({
                    smsPhoneNumber: null,
                    loading: false,
                    isSnackbarOpen: true,
                    snackBarMessage: t('settings.userProfile.smsDeletedSuccessMessage')
                });
            })
            .catch(error => {
                // sb: there isn't any validation for removing a phone number.
                // it either works or it doesn't. :)
                this.setState({ loading: false });
                this.showErrorDialog(error);
            });
    }
}

export default connect(mapStateToProps)(withTranslation()(UserProfileContainer));
