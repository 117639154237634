import styled, { materialUiTheme } from 'theme';
import { ThemedPageHeader, ThemedTextField } from 'common/styles';
import Card from '@material-ui/core/Card';
import { connect } from 'react-redux';
import ContainerBase from 'common/containers/ContainerBase';
import ErrorMessage from 'common/components/ErrorMessage';
import FireflyApi from 'api/FireflyApi';
import GridContainer from 'common/components/GridContainer';
import GridItem from 'common/components/GridItem';
import HelperText from 'common/components/HelperText';
import Hidden from '@material-ui/core/Hidden';
import { mapStateToProps } from 'reducers';
import MessageCard from 'common/components/MessageCard';
import { NavButton } from 'common/components/nav-button';
import Page from 'common/components/Page';
import ProgressButton from 'common/components/ProgressButton';
import React from 'react';
import Validator from 'validation/Validator';
import { withTranslation } from 'react-i18next';

const PageWithBackground = styled(Page)`
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    align-items: center;
    ${materialUiTheme.breakpoints.up('sm')} {
        background-image: url('/img/login_bg.jpg');
    }
`;
const FormCard = styled(Card)`
    padding: 50px;
`;

const ButtonRow = styled.div`
    display: flex;
    justify-content: flex-end;
`;
const CancelButton = styled(NavButton)`
    && {
        margin-right: 20px;
        text-transform: inherit;
    }
`;
const SubmitButton = styled(ProgressButton)`
    width: 160px;
`;

function ResponsiveFormCard(props) {
    return (
        <div>
            <Hidden xsDown={true}>
                <FormCard>{props.children}</FormCard>
            </Hidden>
            <Hidden smUp={true}>{props.children}</Hidden>
        </div>
    );
}

class ResetPasswordContainer extends ContainerBase {
    constructor(props) {
        const {
            match = props.match || {}
        } = props;
        super(props, {
            token: match.params && match.params.token,
            newPassword: '',
            newPasswordError: null,
            confirmPassword: '',
            confirmPasswordError: null,
            view: 'showForm' // showForm, invalidToken, success
        });

        this.validator = new Validator(this);
    }

    componentDidMount() {
        this.validator.maybeFocus();
    }

    componentDidUpdate() {
        this.validator.maybeFocus();
    }

    redirectToLogin = () => {
        const { history } = this.props;

        history.push('/login');
    }

    render() {
        const {
            confirmPassword,
            confirmPasswordError,
            loading,
            newPassword,
            newPasswordError,
            view
        } = this.state;

        const { t } = this.props;

        switch (view) {
            case 'invalidToken':
                return (
                    <MessageCard
                        container={this}
                        message={t('resetPassword.errorInvalidToken')}
                        src="/img/disconnected_plugs.svg"
                        onClickHandler={this.redirectToLogin}
                    />
                );
            case 'success':
                return (
                    <MessageCard
                        container={this}
                        message={t('resetPassword.success')}
                        src="/img/success_trophy.svg"
                        onClickHandler={this.redirectToLogin}
                    />
                );
            default:
                return (
                    <PageWithBackground>
                        <GridContainer>
                            <GridItem xs={false} md={2} lg={3} />
                            <GridItem xs={12} md={8} lg={6}>
                                <ResponsiveFormCard>
                                    <ThemedPageHeader>
                                        {t('resetPassword.header')}
                                    </ThemedPageHeader>

                                    <form onSubmit={this.submit}>
                                        <ThemedTextField
                                            id="newPassword"
                                            inputRef={this.newPasswordFocus}
                                            label={t('common.labelPassword')}
                                            type="password"
                                            value={newPassword}
                                            disabled={loading}
                                            onChange={this.handleInputChange('newPassword')}
                                            error={newPasswordError !== null}
                                        />
                                        <ErrorMessage resourceId={newPasswordError} />
                                        <HelperText resourceId="common.hintPasswordRequirements" />
                                        <ThemedTextField
                                            id="confirmPassword"
                                            inputRef={this.confirmPasswordFocus}
                                            label={t('common.labelConfirmPassword')}
                                            type="password"
                                            value={confirmPassword}
                                            disabled={loading}
                                            onChange={this.handleInputChange('confirmPassword')}
                                            error={confirmPasswordError !== null}
                                        />
                                        <ErrorMessage resourceId={confirmPasswordError} />
                                        <ButtonRow>
                                            <CancelButton color="primary" disabled={loading} to="/login">
                                                {t('common.cancel')}
                                            </CancelButton>
                                            <SubmitButton
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                                loading={loading}
                                            >
                                                {t('resetPassword.buttonSubmit')}
                                            </SubmitButton>
                                        </ButtonRow>
                                    </form>
                                </ResponsiveFormCard>
                            </GridItem>
                        </GridContainer>
                    </PageWithBackground>
                );
        }
    }

    handleInputChange = name => ({ target } = {} ) => {
        this.setState({ [name]: target.value });
    }

    configureValidations(config) {
        config.requirePassword('newPassword');
        config.requireConfirmPassword('confirmPassword', 'newPassword');
    }

    submit = e => {
        const {
            confirmPassword,
            newPassword,
            token
        } = this.state;

        e.preventDefault();

        if (!this.validator.validate()) {
            return;
        }

        this.setState({ loading: true });
        const api = new FireflyApi();
        api.resetPassword(token, newPassword, confirmPassword)
            .then(() => {
                this.setState({
                    loading: false,
                    view: 'success'
                });
            })
            .catch(error => {
                switch (error.status) {
                    case 422:
                        // sb: just checking if there are _any_ validation
                        // errors for token. e.g., missing_field or invalid.
                        if (error.response.errors[0].field === 'token') {
                            this.setState({
                                loading: false,
                                view: 'invalidToken'
                            });
                        } else {
                            this.setState({ loading: false });
                            this.showErrorDialog(error);
                        }
                        break;
                    default:
                        this.setState({ loading: false });
                        this.showErrorDialog(error);
                }
            });
    }
}

export default connect(mapStateToProps)(withTranslation()(ResetPasswordContainer));
