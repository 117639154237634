import HttpMethodNotSupportedError from 'api/HttpMethodNotSupportedError';
import HttpMethods from 'api/HttpMethods';
import HttpStatus from './HttpStatus';
import RequestTypes from 'api/RequestTypes';

const apiMixin = Base =>
    class extends Base {
        applyHeaders(requestType, headers) {
            if (super.applyHeaders) {
                headers = super.applyHeaders(requestType, headers);
            }

            const newHeaders = requestType.applyHeaders();

            return { ...headers, ...newHeaders };
        }

        /**
         * Performs an HTTP request to the specified URL using the specified
         * method.
         *
         * @param method The HTTP method.
         * @param url The URL.
         * @param queryString An optional query string to append to the URL.
         * @param body The optional request body to send.
         * @param requestType The type of request.
         * @param handleEmpty200 Some API methods may return a 200 OK but with
         * an empty response body. In those cases, set this to true to return an
         * empty promise, otherwise you'll get a
         * "SyntaxError: Unexpected end of JSON input" when we call
         * `response.json()`.
         */
        executeRequest(
            method,
            url,
            queryString,
            body,
            requestType,
            handleEmpty200 = false
        ) {
            return new Promise((resolve, reject) => {
                let initBlock = null;
                switch (method) {
                    case HttpMethods.GET:
                    case HttpMethods.DELETE:
                        initBlock = {
                            method: method,
                            headers: this.applyHeaders(requestType)
                        };
                        break;

                    case HttpMethods.POST:
                    case HttpMethods.PUT:
                        initBlock = {
                            method: method,
                            headers: this.applyHeaders(requestType),
                            body: requestType.prepareBody(body)
                        };
                        break;

                    default:
                        throw new HttpMethodNotSupportedError(method);
                }

                const fullUrl = process.env.REACT_APP_API_DOMAIN + requestType.prepareUrl(url, queryString);

                fetch(fullUrl, initBlock)
                    .then(response => {
                        switch (response.status) {
                            case HttpStatus.OK:
                                if (handleEmpty200) {
                                    return resolve(response.status);
                                }

                                return response.json();

                            case HttpStatus.CREATED:
                            case HttpStatus.NO_CONTENT:
                                return Promise.resolve(response.status);

                            case HttpStatus.NOT_FOUND:
                                const err = new Error('The specified resource was not found.');
                                err.status = response.status;
                                return Promise.reject(err);

                            default:
                                // This promise will always reject
                                // after parsing the error body.
                                return new Promise((_, reject) => {
                                    response.json().then(errorData => {
                                        const err = new Error(errorData.message);
                                        err.response = errorData;
                                        err.status = response.status;
                                        reject(err);
                                    });
                                });
                        }
                    })
                    .then(jsonData => resolve(jsonData))
                    .catch(error => reject(error));
            });
        }

        executeGet(url, queryString = null) {
            // Adding IE11 cache buster on GET calls.
            // See https://stackoverflow.com/q/32261000/454470
            const today = new Date();
            const time = today.getTime();

            if (queryString === null) {
                queryString = { t: time };
            } else {
                queryString.t = time;
            }

            return this.executeRequest(
                HttpMethods.GET,
                url,
                queryString,
                null,
                RequestTypes.EMPTY
            );
        }

        postEmpty(url) {
            return this.executeRequest(
                HttpMethods.POST,
                url,
                null,
                null,
                RequestTypes.EMPTY
            );
        }

        postForm(url, formData) {
            return this.executeRequest(
                HttpMethods.POST,
                url,
                null,
                formData,
                RequestTypes.FORM
            );
        }

        postJson(url, data) {
            return this.executeRequest(
                HttpMethods.POST,
                url,
                null,
                data,
                RequestTypes.JSON
            );
        }

        /**
         * Performs an HTTP PUT to the specified URL.
         *
         * @param url The URL.
         * @param data The data object to PUT.
         * @param handleEmpty200 Some API methods may return a 200 OK but with
         * an empty response body. In those cases, set this to true to return an
         * empty promise, otherwise you'll get a
         * "SyntaxError: Unexpected end of JSON input" when we call
         * `response.json()`.
         */
        putJson(url, data, handleEmpty200 = false) {
            return this.executeRequest(
                HttpMethods.PUT,
                url,
                null,
                data,
                RequestTypes.JSON,
                handleEmpty200
            );
        }

        delete(url) {
            return this.executeRequest(
                HttpMethods.DELETE,
                url,
                null,
                null,
                RequestTypes.JSON
            );
        }
    };

export default apiMixin;
