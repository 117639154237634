import User from 'model/User';

export default class GetUserTask {
    constructor(api) {
        this.api = api;
    }

    execute() {
        return new Promise((resolve, reject) => {
            this.api
                .getUser()
                .then(userJson => {
                    const user = new User(userJson);
                    const accountDetailsPromises = user.accounts.map(it =>
                        this.api.getAccountDetails(it.account_number)
                    );

                    Promise.all(accountDetailsPromises)
                        .then(accountDetails => {
                            resolve({
                                user: user,
                                accountDetails: accountDetails
                            });
                        })
                        .catch(error => reject(error));
                })
                .catch(error => reject(error));
        });
    }
}
