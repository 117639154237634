import { ContinueButton, Intro } from 'features/wizard/styles';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import ContainerBase from 'common/containers/ContainerBase';
import FireflyAuthenticatedApi from 'api/FireflyAuthenticatedApi';
import { mapStateToProps } from 'reducers';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'theme';
import { updateProfile } from 'actions';
import { withTranslation } from 'react-i18next';
import WizardStep from 'features/wizard/components/WizardStep';

const NewsletterImage = styled.img`
    height: 154px;
    margin-top: 10px;
    margin-bottom: 20px;
`;

const NewsletterIntro = styled(Intro)`
    margin-bottom: 30px;
`;

const NoThanksButton = styled(Button)`
    && {
        min-width: 180px;
        margin-top: 16px;
    }
`;

class WizardNewsletterContainer extends ContainerBase {
    onContinue = () => {
        const { onContinue } = this.props;

        onContinue();
    };

    render() {
        const { t } = this.props;
        const { loading } = this.state;

        return (
            <WizardStep heading="wizard.newsletter.header">
                <NewsletterImage src="/img/wizard_newsletter.svg" />
                <NewsletterIntro>
                    {t('wizard.newsletter.intro')}
                </NewsletterIntro>
                <ContinueButton
                    key="continue"
                    variant="contained"
                    color="primary"
                    loading={loading}
                    onClick={this.saveAndContinue}
                >
                    {t('wizard.newsletter.buttonContinue')}
                </ContinueButton>
                <NoThanksButton
                    key="skip"
                    variant="contained"
                    color="secondary"
                    onClick={this.onContinue}
                >
                    {t('wizard.newsletter.buttonSkip')}
                </NoThanksButton>
            </WizardStep>
        );
    }

    saveAndContinue = () => {
        const {
            appState = this.props.appState || {},
            dispatch,
            onContinue
        } = this.props;

        this.setState({ loading: true });
        const api = new FireflyAuthenticatedApi({ appState, dispatch });
        const user = appState.user || {};
        const firstName = user.firstName;
        const lastName = user.lastName;
        api.updateProfile(firstName, lastName, true, navigator.language)
            .then(() => {
                dispatch(updateProfile(firstName, lastName, true));
                onContinue();
            })
            .catch(error => {
                this.setState({ loading: false });
                this.showErrorDialog(error);
            });
    };
}

WizardNewsletterContainer.propTypes = {
    onContinue: PropTypes.func.isRequired,
    wizardContext: PropTypes.object.isRequired,
    orientation: PropTypes.string.isRequired
};

export default connect(mapStateToProps)(withTranslation()(WizardNewsletterContainer));
