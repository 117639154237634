import { connect } from 'react-redux';
import ContainerBase from 'common/containers/ContainerBase';
import { mapStateToProps } from 'reducers';
import MessageCard from 'common/components/MessageCard';
import React from 'react';
import { withTranslation } from 'react-i18next';

class ExpiredSessionContainer extends ContainerBase {
    redirectToLogin = () => {
        const { history } = this.props;
        history.push('/login');
    }

    render() {
        const { t } = this.props;

        return (
            <MessageCard
                container={this}
                message={t('login.expiredSession')}
                src="/img/disconnected_plugs.svg"
                onClickHandler={this.redirectToLogin}
            />
        );
    }
}

export default connect(mapStateToProps)(withTranslation()(ExpiredSessionContainer));
