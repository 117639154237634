import validator from 'validator';

export default class ValidationConfiguration {
    static isValid = password => {
        let hasDigit = false;
        let hasLower = false;
        let hasUpper = false;
        let hasWhitespace = false;
        for (let i = 0; i < password.length; i++) {
            const c = password.charAt(i);
            // Is empty needs to be first since whitespace is considered lowercase
            if (validator.isEmpty(c.trim())) {
                hasWhitespace = true;
            }
            if (validator.isNumeric(c)) {
                hasDigit = true;
                continue;
            }
            if (validator.isLowercase(c)) {
                hasLower = true;
                continue;
            }
            if (validator.isUppercase(c)) {
                hasUpper = true;
                continue;
            }
        }
        if (
            password.length >= 8 &&
            password.length <= 128 &&
            hasDigit &&
            hasLower &&
            hasUpper &&
            !hasWhitespace
        ) {
            return true;
        }
        return false;
    }
}
