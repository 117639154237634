import PropTypes from 'prop-types';

/**
 * @return {string}
 */
function FormattedDate(props) {
    const { dateString, format } = props;

    return new Date(dateString).toLocaleString('en-US', format);
}

FormattedDate.propTypes = {
    dateString: PropTypes.string.isRequired,
    format: PropTypes.object.isRequired
};

FormattedDate.defaultProps = {
    format: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
    }
};

export default FormattedDate;
