import OnboardingWizardContext from 'features/wizard/OnboardingWizardContext';
import WizardContext from 'features/wizard/WizardContext';
import WizardStep from 'features/wizard/WizardStep';

export class WizardLaunchSource {
    static SETTINGS_ADD_ACCOUNT = 'settings_add_account';
    static SETTINGS_ADD_MEMBERSHIP = 'settings_add_membership';
    static WIZARD_LINK_ACCOUNT = 'wizard_link_account';
    static WIZARD_LINK_MEMBERSHIP = 'wizard_link_membership';
}

/**
 * Restores a previous wizard context from app state.
 *
 * @param appState The application state.
 * @return {WizardContext} The previous wizard context.
 */
export function wizardContextFromAppState(appState) {
    return new WizardContext(
        null,
        null,
        appState.wizardContext
    );
}

/**
 * Creates a new wizard context from a launch source.
 *
 * @param {string} launchSource The area of the app that the wizard process is
 * being launched from.
 * @return {WizardContext} The new wizard context.
 */
export function wizardContextFromLaunchSource(launchSource) {
    let steps;
    switch (launchSource) {
        case WizardLaunchSource.SETTINGS_ADD_ACCOUNT:
            steps = [
                WizardStep.LINK_ACCOUNT,
                WizardStep.NOTIFICATIONS,
                WizardStep.COMPLETE
            ];
            break;

        case WizardLaunchSource.SETTINGS_ADD_MEMBERSHIP:
            steps = [
                WizardStep.LINK_MEMBERSHIP,
                WizardStep.ALIASES,
                WizardStep.NOTIFICATIONS,
                WizardStep.TAP_ENROLLMENT,
                WizardStep.GO_PAPERLESS,
                WizardStep.AUTOMATIC_PAYMENTS,
                WizardStep.COMPLETE
            ];
            break;

        case WizardLaunchSource.WIZARD_LINK_ACCOUNT:
            steps = [
                WizardStep.LINK_ACCOUNT,
                WizardStep.NOTIFICATIONS,
                WizardStep.NEWSLETTER,
                WizardStep.COMPLETE
            ];
            break;

        default:
            steps = [
                WizardStep.LINK_MEMBERSHIP,
                WizardStep.ALIASES,
                WizardStep.NOTIFICATIONS,
                WizardStep.TAP_ENROLLMENT,
                WizardStep.NEWSLETTER,
                WizardStep.GO_PAPERLESS,
                WizardStep.AUTOMATIC_PAYMENTS,
                WizardStep.COMPLETE
            ];
    }

    return new WizardContext(steps, launchSource);
}

export function wizardContextFromMembershipOnboarding(
    memberNumber,
    accountNumbers,
    isMembershipPaperless,
    accountDetails
) {
    const steps = [
        WizardStep.ALIASES,
        WizardStep.NOTIFICATIONS,
        WizardStep.TAP_ENROLLMENT,
        WizardStep.GO_PAPERLESS,
        WizardStep.AUTOMATIC_PAYMENTS,
        WizardStep.COMPLETE
    ];

    let isAtLeastOneAccountTapEligible = false;
    let areAllAccountsEnrolledInAutomaticPayments = true;
    const wizardAccounts = accountDetails.filter(accountDetail =>
        accountNumbers.includes(accountDetail.account_number)
    );

    wizardAccounts.forEach(wizardAccount => {
        if (wizardAccount.type !== 'other') {
            isAtLeastOneAccountTapEligible = true;
        }

        if (wizardAccount.automatic_payment === false) {
            areAllAccountsEnrolledInAutomaticPayments = false;
        }
    });

    return new OnboardingWizardContext(
        steps,
        memberNumber,
        accountNumbers,
        isAtLeastOneAccountTapEligible,
        areAllAccountsEnrolledInAutomaticPayments,
        isMembershipPaperless
    );
}

export function wizardContextFromAccountOnboarding(accountNumber, accountDetails) {
    const steps = [
        WizardStep.NOTIFICATIONS,
        WizardStep.COMPLETE
    ];

    const account = accountDetails.find(it => it.account_number === accountNumber);
    const isAtLeastOneAccountTapEligible = account.type !== 'other';
    const areAllAccountsEnrolledInAutomaticPayments = account.automatic_payment === false;

    return new OnboardingWizardContext(
        steps,
        null,
        [accountNumber],
        isAtLeastOneAccountTapEligible,
        areAllAccountsEnrolledInAutomaticPayments,
        null
    );
}
