import AppBar from '@material-ui/core/AppBar';
import AppConstants from 'AppConstants.js';
import React from 'react';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { withStyles } from '@material-ui/core/styles';

// Using JSS instead of CSS here because that is what withStyles supports
const styles = () => ({
    tabsIndicator: {
        backgroundColor: '#ffffff'
    },
    tabRoot: {
        maxWidth: 'none',
        width: '50%',
        paddingTop: 0
    },
    tabSelected: {
        backgroundColor: AppConstants.COLOR_TAB_SELECTED_BACKGROUND
    },
    typography: {
        fontSize: 50
    }
});

function CustomTabs(props) {
    const {
        children,
        classes = props.classes || {},
        ...other
    } = props;

    return (
        <AppBar position="static">
            <Tabs
                {...other}
                classes={{
                    indicator: classes.tabsIndicator
                }}
            >
                {children}
            </Tabs>
        </AppBar>
    );
}

export const TabBar = withStyles(styles)(CustomTabs);

function CustomTab(props) {
    const {
        children,
        classes = props.classes || {},
        ...other
    } = props;

    return (
        <Tab
            {...other}
            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
        >
            {children}
        </Tab>
    );
}

export const TabItem = withStyles(styles)(CustomTab);
