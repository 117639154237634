import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid/Grid';
import mapStateToProps from 'reducers';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';

class EnrolledInTap extends React.Component {
    render() {
        const {
            className,
            enrolledInTap,
            t
        } = this.props;

        return (
            <Grid container direction="row" justifyContent="space-between" className={className}>
                <div>{t('tap.title')}</div>
                <div>{t(`${enrolledInTap === true ? 'common.yes' : 'common.no'}`)}</div>
            </Grid>
        );
    }
}

EnrolledInTap.propTypes = {
    enrolledInTap: PropTypes.bool.isRequired
};

export default connect(mapStateToProps)(withTranslation()(EnrolledInTap));
