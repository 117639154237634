import { connect } from 'react-redux';
import { mapStateToProps } from 'reducers';
import PropTypes from 'prop-types';
import React from 'react';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hasError: false,
            error: null
        };
    }

    componentDidCatch(error, errorInfo) {
        const {
            onDidCatch,
            appState
        } = this.props;

        this.setState({});
        onDidCatch && onDidCatch(error, errorInfo);

        if (appState && appState.isAuthenticated === true) {
            const user = appState.user;

            window.bugsnagClient.user = {
                id: user.id,
                name: user.fullName,
                email: user.email
            };

            window.bugsnagClient.metaData = {
                react: {
                    appState
                }
            };

            window.bugsnagClient.notify(error, {
                beforeSend: function(report) {
                    Object.keys(errorInfo).forEach(key => {
                        report.metaData['react'][key] = errorInfo[key];
                    });
                }
            });
        }
    }

    render() {
        const { hasError, error } = this.state;
        const { render, children, renderError } = this.props;

        if (hasError) {
            return renderError ? renderError({ error }) : null;
        }

        return render ? render() : children | null;
    }
}

ErrorBoundary.propTypes = {
    onError: PropTypes.func,
    onDidCatch: PropTypes.func,
    render: PropTypes.func,
    children: PropTypes.func,
    renderError: PropTypes.func,
    appState: PropTypes.object
};

export default connect(mapStateToProps)(ErrorBoundary);
