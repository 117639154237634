import Hidden from '@material-ui/core/Hidden';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'theme';
import { ThemedPageHeader } from 'common/styles';
import { useTranslation } from 'react-i18next';

const StepContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

function WizardStep(props) {
    const { t } = useTranslation();
    const {
        children,
        heading
    } = props;

    return (
        <StepContainer>
            {/* Using only because tablet view uses vertical stepper
              * where step name acts as the heading.
              */}
            {heading && (
                <Hidden only="sm">
                    <ThemedPageHeader center={true}>
                        {t(heading)}
                    </ThemedPageHeader>
                </Hidden>
            )}
            {children}
        </StepContainer>
    );
}

WizardStep.propTypes = {
    heading: PropTypes.string
};

export default WizardStep;
