import CardTextFields, { cardInputFieldNames } from './CardTextFields';
import styled, { materialUiTheme } from '../../../theme';
import BillingAddress from '../../../model/BillingAddress';
import Button from '@material-ui/core/Button';
import cardValidator from 'card-validator';
import ContainerBase from '../../../common/containers/ContainerBase';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import NewCardDetails from '../../../model/NewCardDetails';
import PropTypes from 'prop-types';
import React from 'react';
import Validator from '../../../validation/Validator';
import { withTranslation } from 'react-i18next';

const SingleColumnForm = styled.div`
    width: 360px;
    ${materialUiTheme.breakpoints.down('xs')} {
        max-width: 360px;
        width: auto;
    }
`;

class NewCardDialog extends ContainerBase {
    constructor(props) {
        super(props, {
            open: false,
            processing: false,
            ...cardInputFieldNames
        });

        this.validator = new Validator(this);
    }

    componentDidMount() {
        this.validator.maybeFocus();
    }

    componentDidUpdate() {
        this.validator.maybeFocus();
    }

    render() {
        const { t } = this.props;
        const { open } = this.state;

        return (
            <Dialog
                open={open}
                onClose={this.close}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{t('billing.enterCard.header')}</DialogTitle>
                <DialogContent>
                    <SingleColumnForm>
                        <CardTextFields
                            parent={this}
                            t={t}
                            alwaysShowAlias={false}
                            showSaveCheckbox={false}
                        />
                    </SingleColumnForm>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.close} color="primary">
                        {t('common.cancel')}
                    </Button>
                    <Button onClick={this.onSave} variant="contained" color="primary">
                        {t('common.saveButton')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    open() {
        this.setState({ open: true });
    }

    onSave = () => {
        const { onSave } = this.props;

        if (!this.validator.validate()) {
            return;
        }

        const {
            nameOnCard,
            creditCard,
            expirationDate,
            cvv,
            addressLine1,
            addressLine2,
            city,
            state,
            zip
        } = this.state;

        const expirationDateObject = cardValidator.expirationDate(
            expirationDate
        );

        const expMonth = expirationDateObject.month;
        const expYear = expirationDateObject.year;

        const billingAddress = new BillingAddress(
            addressLine1,
            addressLine2,
            city,
            state,
            zip
        );

        const newCardDetail = new NewCardDetails(
            nameOnCard,
            creditCard,
            expMonth,
            expYear,
            cvv,
            billingAddress
        );

        this.setState({ open: false });
        onSave(newCardDetail);
    }

    configureValidations(config) {
        config.requireNotEmpty('nameOnCard');
        config.requireCreditCard('creditCard');
        config.requireExpirationDate('expirationDate');
        config.requireCvv('cvv', 'creditCard');
        config.requireNotEmpty('addressLine1');
        config.requireNotEmpty('city');
        config.requireNotEmpty('state');
        config.requireNotEmpty('zip');
    }

    close = () => {
        this.setState({ open: false });
    }
}
NewCardDialog.propTypes = {
    onSave: PropTypes.func.isRequired
};

export default withTranslation('translation', { withRef: true })(NewCardDialog);
