import Cleave from 'cleave.js/react';
import PropTypes from 'prop-types';
import React from 'react';
import { ThemedTextField } from 'common/styles';

function RoutingNumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <Cleave
            {...other}
            htmlRef={inputRef}
            options={{blocks: [9], numericOnly: true}}
            onChange={onChange}
        />
    );
}

RoutingNumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired
};

export default function RoutingNumberField(props) {
    return (
        <ThemedTextField
            {...props}
            type="text"
            InputProps={{ inputComponent: RoutingNumberFormatCustom }}
        />
    );
}
