import { Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import FireflyApi from 'api/FireflyApi';
import FireflyAuthenticatedApi from 'api/FireflyAuthenticatedApi';
import LoadingIndicator from 'common/components/LoadingIndicator';
import { login } from 'actions';
import { mapStateToProps } from 'reducers';
import Page from 'common/components/Page';
import React from 'react';

class PrivateRoute extends React.Component {
    componentDidMount() {
        this.authenticateAppState();
    }

    /**
     * This is required because the logout function is in the header,
     * which only changes the appstate but never navigates. The change
     * in the app state triggers an update on this component.
     */
    componentDidUpdate() {
        this.authenticateAppState();
    }

    render() {
        const { component: Component, appState, ...rest } = this.props;

        return (
            <Route
                {...rest}
                render={props => appState.isAuthenticated === true ? (
                    <Component {...props} />
                ) : (
                    <Page>
                        <LoadingIndicator />
                    </Page>
                )}
            />
        );
    }

    authenticateAppState() {
        const {
            appState = this.props.appState || {},
            history,
        } = this.props;

        // Re-authenticate with refresh token if not authenticated but have a
        // refresh token.
        if (appState.isAuthenticated === false && appState.refreshToken) {
            this.reauthenticate(appState.refreshToken);
            return;
        }

        if (appState.hasSessionExpired === true) {
            history.push('/expired-session');
            return;
        }

        // Go to login if still not authenticated
        if (appState.isAuthenticated === false) {
            history.push('/login');
        }
    }

    reauthenticate(refreshToken) {
        const {
            dispatch,
            history
        } = this.props;
        const api = new FireflyApi();

        api.refreshToken(refreshToken)
            .then(refreshResponse => {
                const authenticatedApi = new FireflyAuthenticatedApi({
                    accessToken: refreshResponse.access_token,
                    dispatch
                });

                authenticatedApi.getUser().then(user => {
                    const accountDetailsPromises = user.accounts.map(it =>
                        authenticatedApi.getAccountDetails(it.account_number)
                    );

                    Promise.all(accountDetailsPromises)
                        .then(accountDetails => {
                            dispatch(
                                login(
                                    refreshResponse.access_token,
                                    refreshResponse.refresh_token,
                                    user,
                                    accountDetails
                                )
                            );
                        })
                        .catch(() => {
                            throw new Error('Encountered an error fetching details for one or more accounts.');
                        });
                });
            })
            .catch(() => {
                history.push('/login');
            });
    }
}

export default withRouter(connect(mapStateToProps)(PrivateRoute));
