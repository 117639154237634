import { ContinueButton, Intro } from 'features/wizard/styles';
import { connect } from 'react-redux';
import ContainerBase from 'common/containers/ContainerBase';
import FireflyAuthenticatedApi from 'api/FireflyAuthenticatedApi';
import { mapStateToProps } from 'reducers';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'theme';
import { ThemedTextField } from 'common/styles';
import { updateAccountAliases } from 'actions';
import { withTranslation } from 'react-i18next';
import WizardStep from 'features/wizard/components/WizardStep';

const AliasForm = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

class WizardAliasesContainer extends ContainerBase {
    constructor(props) {
        super(props);
        const {
            appState = this.props.appState || {},
            wizardContext = this.props.wizardContext || {}
        } = this.props;

        this.accountNumbers = wizardContext.accountNumbersAddedByWizard || [];

        this.state.aliases = this.accountNumbers.map((accountNumber) => {
            const accounts = appState.accounts || [];
            const account = accounts.find(it => it.accountNumber === accountNumber) || {};

            let alias = account.alias;
            if (alias == null) {
                alias = '';
            }

            return alias;
        });

        this.state.aliasesError = this.accountNumbers.map(() => null);
    }

    render() {
        const {
            appState = this.props.appState || {},
            t
        } = this.props;

        const {
            aliases = this.state.aliases || [],
            loading
        } = this.state;

        const accountNumbersAndTypes = this.accountNumbers.map(accountNumber => {
            const accounts = appState.accounts || [];
            const account = accounts.find(it => it.accountNumber === accountNumber) || {};

            return {
                accountNumber,
                type: account.type
            };
        });

        return (
            <WizardStep heading="wizard.aliases.header">
                <Intro>{t('wizard.aliases.intro')}</Intro>
                <AliasForm autoComplete="off" onSubmit={this.saveAndContinue}>
                    {accountNumbersAndTypes.map((accountNumbersAndType, i) => (
                        <ThemedTextField
                            key={i}
                            id={`alias${i}`}
                            label={t('common.accounts.titleNoAlias', {
                                accountNumber: accountNumbersAndType.accountNumber,
                                type: t('common.accounts.types.' + accountNumbersAndType.type)
                            })}
                            type="text"
                            value={aliases[i]}
                            disabled={loading}
                            onChange={this.handleAliasChange(i)}
                        />
                    ))}
                    <ContinueButton
                        key="continue"
                        variant="contained"
                        color="primary"
                        type="submit"
                        loading={loading}
                    >
                        {t('common.continue')}
                    </ContinueButton>
                </AliasForm>
            </WizardStep>
        );
    }

    handleAliasChange = i => ({ target } = {}) => {
        const { aliases } = this.state;

        aliases[i] = target.value;
        this.setState({ aliases });
    };

    saveAndContinue = e => {
        const {
            appState,
            dispatch,
            onContinue
        } = this.props;

        const {
            aliases = this.state.aliases || []
        } = this.state;

        e.preventDefault();

        this.setState({ loading: true });
        const api = new FireflyAuthenticatedApi({ appState, dispatch});

        const aliasUpdates = [];
        const promises = this.accountNumbers.map((accountNumber, i) => {
            let newAlias = aliases[i];
            if (newAlias != null) {
                newAlias = newAlias.trim();
            }

            aliasUpdates.push({ accountNumber: accountNumber, newAlias });

            return api.saveAccountAlias(accountNumber, { alias: newAlias });
        });

        Promise.all(promises)
            .then(() => {
                dispatch(updateAccountAliases(aliasUpdates));
                onContinue();
            })
            .catch(error => {
                this.setState({ loading: false });
                this.showErrorDialog(error);
            });
    }
}

WizardAliasesContainer.propTypes = {
    onContinue: PropTypes.func.isRequired,
    wizardContext: PropTypes.object.isRequired,
    orientation: PropTypes.string.isRequired
};

export default connect(mapStateToProps)(withTranslation()(WizardAliasesContainer));
