import { DialogBasic, DialogConfirm } from 'common/components/dialogs';
import { EmptyContainer, EmptyImage, EmptyMessage, ThemedFabButton, ThemedListHeader } from 'common/styles';
import AddIcon from '@material-ui/icons/Add';
import AuthorizedUserList from 'features/settings/components/AuthorizedUserList';
import { connect } from 'react-redux';
import ContainerBase from 'common/containers/ContainerBase';
import FireflyAuthenticatedApi from 'api/FireflyAuthenticatedApi';
import LeftNav from 'common/components/LeftNav';
import LoadingIndicator from 'common/components/LoadingIndicator';
import { mapStateToProps } from 'reducers';
import { navigations } from 'features/settings/containers/SettingsContainer';
import Page from 'common/components/Page';
import React from 'react';
import SuccessSnackbar from 'common/components/SuccessSnackbar';
import { withTranslation } from 'react-i18next';

class AuthorizedUsersContainer extends ContainerBase {
    constructor(props) {
        const {
            appState,
            dispatch,
            location = props.location || {}
        } = props;

        const isAddSnackbarOpen = !!(location.state && location.state.isAddSnackbarOpen);

        super(props, {
            loadingAuthorizedUsers: true,
            authorizedUsers: [],
            isDeleteSnackbarOpen: false,
            selectedAuthorizedUserId: null,
            selectedAuthorizedUserName: null,
            isAddSnackbarOpen: isAddSnackbarOpen
        });

        this.api = new FireflyAuthenticatedApi({ appState, dispatch });
    }

    componentDidMount() {
        this.loadAuthorizedUsers();
    }

    handleAdd = () => {
        const {
            appState = this.props.appState || {},
            history
        } = this.props;

        const memberships = appState.memberships || [];
        const accounts = appState.accounts || [];
        const hasMembershipsOrAccounts = memberships.length > 0 || accounts.length > 0;

        if (hasMembershipsOrAccounts) {
            history.push('/settings/authorized-users/new');
        } else {
            this.showBasicDialog();
        }
    }

    render() {
        const {
            appState = this.props.appState || {},
            t
        } = this.props;

        const {
            authorizedUsers = this.state.authorizedUsers || [],
            basicDialogIsOpen,
            confirmDialogIsOpen,
            isAddSnackbarOpen,
            isDeleteSnackbarOpen,
            loadingAuthorizedUsers,
            selectedAuthorizedUserName
        } = this.state;

        const hasUsersToDisplay = authorizedUsers.length > 0;

        // Disable add if no membership accounts to share.
        // AUT.110 - Users may grant access to individual
        //   service accounts to others if the user has access
        //   to those accounts via their membership.
        const isAddUserEnabled = appState.memberships.length > 0;

        return (
            <Page>
                <LeftNav title="settings.header" to="/settings" navigations={navigations}>
                    <ThemedListHeader>{t('settings.authorizedUsers.header')}</ThemedListHeader>

                    {isAddUserEnabled && (
                        <ThemedFabButton
                            size="small"
                            color="primary"
                            aria-label={t('settings.authorizedUsers.aria.addUserButton')}
                            onClick={this.handleAdd}
                        >
                            <AddIcon />
                        </ThemedFabButton>
                    )}

                    {loadingAuthorizedUsers ? (
                        <LoadingIndicator />
                    ) : !hasUsersToDisplay ? (
                        <EmptyContainer>
                            <EmptyImage src="/img/empty_state_authorized_users.svg" />
                            <EmptyMessage>
                                {isAddUserEnabled
                                    ? t('settings.authorizedUsers.emptyStateMessage')
                                    : t('settings.authorizedUsers.membershipRequiredMessage')}
                            </EmptyMessage>
                        </EmptyContainer>
                    ) : (
                        <AuthorizedUserList authorizedUsers={authorizedUsers} onClickDelete={this.onClickDelete} t={t} />
                    )}
                </LeftNav>
                <SuccessSnackbar
                    id="delete-user"
                    open={isDeleteSnackbarOpen}
                    onClose={this.handleDeleteSnackbarClose}
                    message={t('settings.authorizedUsers.deleteUserSuccessMessage')}
                />
                <SuccessSnackbar
                    id="add-user"
                    open={isAddSnackbarOpen}
                    onClose={this.handleAddSnackbarClose}
                    message={t('settings.authorizedUsers.addUserSuccessMessage')}
                />
                <DialogBasic
                    message={t('settings.authorizedUsers.noMembershipsOrAccountsError.message')}
                    onCloseDialog={this.closeBasicDialog}
                    open={basicDialogIsOpen}
                    title={t('settings.authorizedUsers.noMembershipsOrAccountsError.title')}
                />
                <DialogConfirm
                    confirmButtonLabel={t('settings.authorizedUsers.confirmDeleteModal.confirmButton')}
                    message={t('settings.authorizedUsers.confirmDeleteModal.message', {
                        user: selectedAuthorizedUserName
                    })}
                    onCloseDialog={this.closeConfirmDialog}
                    open={confirmDialogIsOpen}
                    title={t('settings.authorizedUsers.confirmDeleteModal.title', {
                        user: selectedAuthorizedUserName
                    })}
                />
            </Page>
        );
    }

    handleDeleteSnackbarClose = () => {
        this.setState({ isDeleteSnackbarOpen: false });
    }

    handleAddSnackbarClose = () => {
        this.setState({ isAddSnackbarOpen: false });
    }

    loadAuthorizedUsers = () => {
        this.api
            .getAuthorizedUsers()
            .then(response => {
                this.setState({
                    loadingAuthorizedUsers: false,
                    authorizedUsers: response
                });
            })
            .catch(error => {
                this.setState({ loadingAuthorizedUsers: false });
                this.showErrorDialog(error);
            });
    }

    onClickDelete = authorizedUser => {
        this.setState({
            selectedAuthorizedUserId: authorizedUser.id,
            selectedAuthorizedUserName:
                authorizedUser.user.first_name +
                ' ' +
                authorizedUser.user.last_name,
            confirmDialogIsOpen: true
        });
    }

    onConfirmDialogClosed(wasConfirmed) {
        const { selectedAuthorizedUserId } = this.state;

        if (wasConfirmed) {
            this.deleteAuthorizedUser(selectedAuthorizedUserId);
        }
    }

    deleteAuthorizedUser = authorizedUserId => {
        const {
            authorizedUsers = this.state.authorizedUsers || []
        } = this.state;

        this.setState({ loading: true });
        this.api
            .removeAuthorizedUser(authorizedUserId)
            .then(() => {
                this.setState({
                    loading: false,
                    isDeleteSnackbarOpen: true,
                    selectedAuthorizedUserId: null,
                    selectedAuthorizedUserName: null,
                    authorizedUsers: authorizedUsers.filter(au => au.id !== authorizedUserId)
                });
            })
            .catch(error => {
                this.setState({ loading: false });
                this.showErrorDialog(error);
            });
    }
}

export default connect(mapStateToProps)(withTranslation()(AuthorizedUsersContainer));
