import { connect } from 'react-redux';
import LeftNav from 'common/components/LeftNav';
import { mapStateToProps } from 'reducers';
import { navigations } from 'features/settings/containers/SettingsContainer';
import Page from 'common/components/Page';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'theme';
import { ThemedPageHeader } from 'common/styles';
import { withTranslation } from 'react-i18next';

const CustomHeader = styled(ThemedPageHeader)`
    margin-bottom: 4px;
`;
const SubTitle = styled.div`
    display: flex;
    justify-content: flex-start;
    margin-bottom: 16px;
`;

class SettingsPage extends React.Component {
    render() {
        const {
            children,
            title,
            select,
            subtitle,
            t
        } = this.props;

        return (
            <Page>
                <LeftNav
                    title="settings.header"
                    to="/settings"
                    select={select}
                    navigations={navigations}
                >
                    <CustomHeader>{t(title)}</CustomHeader>
                    <SubTitle>{t(subtitle)}</SubTitle>
                    {children}
                </LeftNav>
            </Page>
        );
    }
}

SettingsPage.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string
};

export default connect(mapStateToProps)(withTranslation()(SettingsPage));
