/**
 * See https://github.com/chriso/validator.js#validators
 * for a list of available validators.
 */
import cardValidator from 'card-validator';
import PasswordRule from 'validation/PasswordRule';
import { validateRoutingNumber } from 'validation/validateRoutingNumber';
import validator from 'validator';

export default class ValidationConfiguration {
    constructor() {
        this.validations = [];
    }

    addValidation(isValid, fieldName, formName, errorMessageResource) {
        this.validations.push({
            isValid: isValid,
            fieldName: fieldName,
            formName: formName,
            errorMessageResource: errorMessageResource
        });
    }

    requireEmail(fieldName, formName = null) {
        this.addValidation(
            container => {
                return validator.isEmail(container.state[fieldName]);
            },
            fieldName,
            formName,
            'validation.email'
        );
    }

    requireConfirmEmail(fieldName, confirmFieldName, formName = null) {
        this.addValidation(
            container => {
                return (
                    container.state[fieldName] ===
                    container.state[confirmFieldName]
                );
            },
            fieldName,
            formName,
            'validation.confirmEmail'
        );
    }

    requireMobilePhone(fieldName, formName = null) {
        this.addValidation(
            container => {
                return validator.isMobilePhone(
                    container.state[fieldName],
                    'en-US'
                );
            },
            fieldName,
            formName,
            'settings.verifyNumber.error'
        );
    }

    requireNotEmpty(fieldName, formName = null) {
        this.addValidation(
            container => {
                return !validator.isEmpty(container.state[fieldName]);
            },
            fieldName,
            formName,
            'validation.notEmpty'
        );
    }

    requirePassword(fieldName, formName = null) {
        this.addValidation(
            container => {
                return PasswordRule.isValid(container.state[fieldName]);
            },
            fieldName,
            formName,
            'validation.password'
        );
    }

    requireConfirmPassword(fieldName, confirmFieldName, formName = null) {
        this.addValidation(
            container => {
                return (
                    container.state[fieldName] ===
                    container.state[confirmFieldName]
                );
            },
            fieldName,
            formName,
            'validation.confirmPassword'
        );
    }

    requireConfirmRoutingNumber(fieldName, confirmFieldName, formName = null) {
        this.addValidation(
            container => {
                return (
                    container.state[fieldName] ===
                    container.state[confirmFieldName]
                );
            },
            fieldName,
            formName,
            'validation.confirmRoutingNumber'
        );
    }

    requireConfirmAccountNumber(fieldName, confirmFieldName, formName = null) {
        this.addValidation(
            container => {
                return (
                    container.state[fieldName] ===
                    container.state[confirmFieldName]
                );
            },
            fieldName,
            formName,
            'validation.confirmAccountNumber'
        );
    }

    requireCreditCard(fieldName, formName = null) {
        this.addValidation(
            container => {
                const card = cardValidator.number(container.state[fieldName]);
                return card.isValid;
            },
            fieldName,
            formName,
            'validation.creditCard'
        );
    }

    requireExpirationDate(fieldName, formName = null) {
        this.addValidation(
            container => {
                const expDate = cardValidator.expirationDate(
                    container.state[fieldName]
                );
                return expDate.isValid;
            },
            fieldName,
            formName,
            'validation.expirationDate'
        );
    }

    requireCvv(fieldName, ccNumberFieldName, formName = null) {
        this.addValidation(
            container => {
                const card = cardValidator.number(
                    container.state[ccNumberFieldName]
                );

                if (!card.isValid) {
                    return false;
                }

                const cvv = cardValidator.cvv(
                    container.state[fieldName],
                    card.card.code.size
                );

                return cvv.isValid;
            },
            fieldName,
            formName,
            'validation.cvv'
        );
    }

    requireRoutingNumber(fieldName, formName = null) {
        this.addValidation(
            container => {
                return validateRoutingNumber(container.state[fieldName]);
            },
            fieldName,
            formName,
            'validation.routingNumber'
        );
    }
}
