import { updateErrorDialogError, updateErrorDialogState } from 'actions';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { mapStateToProps } from 'reducers';
import React from 'react';
import { withTranslation } from 'react-i18next';

class DialogError extends React.Component {
    close = () => {
        this.props.dispatch(updateErrorDialogState(false));
    }

    exited = () => {
        this.props.dispatch(updateErrorDialogError(null));
    }

    render() {
        const {
            appState = this.props.appState || {},
            t
        } = this.props;

        const error = appState.error || {};
        const title = error.title ?? t('dialog.oops.title');
        const message = error.message ?? t('dialog.oops.message');

        return (
            <Dialog
                open={!!appState.errorDialogIsOpen}
                onClose={this.close}
                TransitionProps={{
                    onExited: this.exited
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.close} color="primary">
                        {t('common.ok')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

DialogError.propTypes = {};

export default connect(mapStateToProps)(withTranslation()(DialogError));
