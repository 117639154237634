import dayjs from 'dayjs';

export default class BillingHistoryBarChartAdapter {
    static adapt(
        selectedAccounts,
        temperatureAndBillingResponses,
        t,
        startDate,
        endDate
    ) {
        const [
            temperatureResponse,
            ...billingResponses
        ] = temperatureAndBillingResponses;

        const xaxis = [];
        const days = [];
        let sequenceDate = dayjs(startDate);
        const format = 'MMM YYYY';
        const unit = 'month';

        // Sometimes next month's bill should be displayed.
        for (const bills of billingResponses) {
            for (const bill of bills) {
                const billDate = Date.parse(bill.bill_date);
                if (billDate > endDate) {
                    endDate = billDate;
                }
            }
        }

        while (!sequenceDate.isAfter(endDate)) {
            xaxis.push(sequenceDate.format(format));
            days.push(sequenceDate.toISOString());
            sequenceDate = sequenceDate.add(1, unit);
        }

        const legend = [];
        const series = selectedAccounts.map((it, i) => {
            const name = it.getDisplayName(t);
            legend.push(name);
            const data = days.map(day => {
                const billingMetric = billingResponses[i].find(
                    searchMetric =>
                        searchMetric.bill_date.substring(0, 7) ===
                        day.substring(0, 7)
                );
                if (billingMetric) {
                    return billingMetric.current_charges;
                }
                return 0;
            });
            return {
                name: name,
                data: data
            };
        });

        const temps = days.map(day => {
            const temperatureMetric = temperatureResponse.find(
                searchMetric =>
                    searchMetric.date.substring(0, 10) === day.substring(0, 10)
            );
            if (temperatureMetric) {
                return {
                    high: temperatureMetric.high,
                    low: temperatureMetric.low
                };
            }
            return {
                high: 0,
                low: 0
            };
        });

        return {
            xaxis: xaxis,
            series: series,
            legend: legend,
            temperatureSeries: temps
        };
    }
}
