import styled, { materialUiTheme } from 'theme';
import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';

const LoadingIndicatorContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    width: 100%;
`;

export const LoadingContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 140px;
    ${materialUiTheme.breakpoints.down('xs')} {
        padding-top: 60px;
    }
`;

export default function LoadingIndicator(props) {
    return (
        <LoadingIndicatorContainer {...props}>
            <CircularProgress />
        </LoadingIndicatorContainer>
    );
}
