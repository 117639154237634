import { expireToken, updateAccessToken } from 'actions';
import FireflyApi from 'api/FireflyApi';
import InvalidRefreshTokenError from 'api/InvalidRefreshTokenError';

class QueuedCall {
    constructor(api, args, resolve, reject) {
        this.api = api;
        this.args = args;
        this.resolve = resolve;
        this.reject = reject;
    }
}

class AuthorizationRequestHandler {
    constructor() {
        this.isRefreshingToken = false;
        this.callQueue = [];
    }

    executeRequest(api, args) {
        return new Promise((resolve, reject) => {
            if (this.isRefreshingToken) {
                this.callQueue.push(new QueuedCall(api, args, resolve, reject));
            } else {
                api.makeSecuredCall(args)
                    .then(resolve)
                    .catch(error => {
                        if (
                            error.response &&
                            error.response.code === 401 &&
                            api.appState
                        ) {
                            this.refreshTokenAndRetry(
                                api,
                                args,
                                resolve,
                                reject
                            );
                        } else {
                            reject(error);
                        }
                    });
            }
        });
    }

    refreshTokenAndRetry(api, args, resolve, reject) {
        if (this.isRefreshingToken) {
            this.callQueue.push(new QueuedCall(api, args, resolve, reject));
            return;
        }

        this.isRefreshingToken = true;

        this.refreshToken(api, args, resolve, reject);
    }

    refreshToken(api, args, resolve, reject) {
        const fireflyApi = new FireflyApi();
        fireflyApi
            .refreshToken(api.appState.refreshToken)
            .then(refreshResponse => {
                this.callQueue.unshift(
                    new QueuedCall(api, args, resolve, reject)
                );
                this.executeCallsInQueue(refreshResponse.access_token);
                this.isRefreshingToken = false;
                api.dispatch(
                    updateAccessToken(
                        refreshResponse.access_token,
                        refreshResponse.refresh_token
                    )
                );
            })
            .catch(error => {
                if (
                    error.response &&
                    error.response.error === 'invalid_grant'
                ) {
                    this.callQueue = [];
                    this.isRefreshingToken = false;
                    api.dispatch(expireToken());
                    reject(new InvalidRefreshTokenError());
                } else {
                    reject(error);
                    this.rejectCallQueue(error);
                }
            });
    }

    executeCallsInQueue(newToken) {
        while (this.callQueue.length > 0) {
            const call = this.callQueue.shift();
            call.api.accessToken = newToken;
            call.api
                .makeSecuredCall(call.args)
                .then(call.resolve)
                .catch(error => {
                    call.reject(error);
                });
        }
    }

    rejectCallQueue(error) {
        while (this.callQueue.length > 0) {
            const call = this.callQueue.shift();
            call.reject(error);
        }
    }
}

// Doing this to implement the singleton pattern.
const instance = new AuthorizationRequestHandler();
export default instance;
