import { EmptyContainer } from 'common/styles';
import GridContainer from 'common/components/GridContainer';
import GridItem from 'common/components/GridItem';
import LeftNav from 'common/components/LeftNav';
import Page from 'common/components/Page';
import React from 'react';
import styled from 'theme';
import { ThemedPageHeader } from 'common/styles';
import { withTranslation } from 'react-i18next';

export const navigations = [
    { to: '/settings/user-profile', name: 'settings.userProfile.header' },
    { to: '/settings/memberships', name: 'settings.memberships.header' },
    { to: '/settings/accounts', name: 'settings.accounts.header' },
    {
        to: '/settings/authorized-users',
        name: 'settings.authorizedUsers.header'
    }
];

const StatusImage = styled.img`
    height: 154px;
    margin-top: 20px;
    margin-bottom: 20px;
`;
const StatusMessage = styled.p`
    font-size: 16px;
    padding-top: 10px;
    padding-bottom: 25px;
    text-align: center;
    max-width: 350px;
`;

class SettingsContainer extends React.Component {
    render() {
        const { t } = this.props;

        return (
            <Page>
                <LeftNav title="settings.header" to="/settings" navigations={navigations} isRoot={true}>
                    <div>
                        <GridContainer>
                            <GridItem xs={12}>
                                <ThemedPageHeader>
                                    {t('settings.header')}
                                </ThemedPageHeader>
                            </GridItem>
                        </GridContainer>
                        <EmptyContainer>
                            <StatusImage src="/img/settings_gears.svg" />
                            <StatusMessage>
                                {t('settings.landingMessage')}
                            </StatusMessage>
                        </EmptyContainer>
                    </div>
                </LeftNav>
            </Page>
        );
    }
}

export default withTranslation()(SettingsContainer);
