import styled, { materialUiTheme } from 'theme/index';
import AppConstants from 'AppConstants.js';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import PropTypes from 'prop-types';
import React from 'react';
import { ThemedMenuItem } from 'common/styles';
import { withTranslation } from 'react-i18next';

const MenuContainer = styled.div`
    display: flex;
    align-items: center;
    z-index: 1;
    ${materialUiTheme.breakpoints.down('xs')} {
        width: 100%;
    }
`;
const AccountButton = styled(Button)`
    && {
        text-transform: inherit;
        color: ${AppConstants.COLOR_TEXT};
        ${materialUiTheme.breakpoints.down('xs')} {
            width: 100%;
        }
    }
`;
const ChevronDownStyled = styled(KeyboardArrowDownIcon)`
    && {
        font-size: 24px;
    }
`;
const ChevronUpStyled = styled(KeyboardArrowUpIcon)`
    && {
        font-size: 24px;
    }
`;
class AccountSelect extends React.Component {
    state = {
        open: false
    }

    render() {
        const {
            remainingAccounts = this.props.remainingAccounts || [],
            selectedAccount = this.props.selectedAccount || {},
            t
        } = this.props;

        const {
            open
        } = this.state;

        return (
            <ClickAwayListener onClickAway={this.closeAccountSelect}>
                <MenuContainer>
                    <AccountButton
                        aria-controls="account-menu"
                        aria-haspopup="true"
                        aria-label={t('common.aria.profileMenu')}
                        id="account-select"
                        ref={node => { this.menuAnchor = node; }}
                        onClick={this.handleAccountSelectToggle}
                    >
                        {selectedAccount.getDisplayName && selectedAccount.getDisplayName(t)}
                        {open ? (
                            <ChevronUpStyled />
                        ) : (
                            <ChevronDownStyled />
                        )}
                    </AccountButton>
                    <Popper
                        open={open}
                        anchorEl={this.menuAnchor}
                        transition={true}
                        disablePortal={true}
                        placement="bottom-end"
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                id="account-menu"
                                role="menu"
                                aria-labelledby="account-select"
                                style={{
                                    transformOrigin:
                                        placement === 'bottom-end'
                                            ? 'center top'
                                            : 'right bottom'
                                }}
                            >
                                <Paper elevation={2}>
                                    <MenuList>
                                        {remainingAccounts.map((account, i) => (
                                            <ThemedMenuItem
                                                onClick={e =>
                                                    this.onAccountSelect(
                                                        e,
                                                        account
                                                    )
                                                }
                                                key={i}
                                                id={account.accountNumber}
                                            >
                                                {account.getDisplayName(t)}
                                            </ThemedMenuItem>
                                        ))}
                                    </MenuList>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </MenuContainer>
            </ClickAwayListener>
        );
    }

    handleAccountSelectToggle = () => {
        this.setState(state => ({ open: !state.open }));
    }

    onAccountSelect = ({ target } = {}, account) => {
        const { onSelectAccount } = this.props;
        this.setState({ open: false });
        if (target.id) {
           onSelectAccount(account);
        }
    }

    closeAccountSelect = () => {
        this.setState({ open: false });
    }
}
AccountSelect.propTypes = {
    selectedAccount: PropTypes.object.isRequired,
    remainingAccounts: PropTypes.array.isRequired,
    onSelectAccount: PropTypes.func.isRequired
};

export default withTranslation()(AccountSelect);
