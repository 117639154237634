import styled, { materialUiTheme } from 'theme';
import AppConstants from 'AppConstants.js';
import PropTypes from 'prop-types';
import React from 'react';
import Transition from 'react-transition-group/Transition';

const duration = 400;
const verticalPadding = AppConstants.GRID_CONTAINER_PADDING / 2;

const StyledPage = styled.div`
    display: flex;
    min-height: 800px;
    width: 100%; // only required by IE11
    color: ${AppConstants.COLOR_TEXT};
    padding-left: ${props => props.sidepadding}px;
    padding-right: ${props => props.sidepadding}px;
    padding-top: ${props => props.toppadding}px;
    ${materialUiTheme.breakpoints.down('sm')} {
        padding-left: ${props => props.mobilesidepadding}px;
        padding-right: ${props => props.mobilesidepadding}px;
    }
`;

const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0
};

const transitionStyles = {
    entering: { opacity: 0 },
    entered: { opacity: 1 }
};

class Page extends React.Component {
    state = {
        in: false
    }

    render() {
        let sidePadding;
        let mobileSidePadding;
        let topPadding;

        const {
            children,
            padding,
            ...rest
        } = this.props;

        switch (padding) {
            case 'basic':
                sidePadding = verticalPadding + 10;
                mobileSidePadding = verticalPadding;
                topPadding = 20;
                break;
            case 'none':
                sidePadding = 0;
                mobileSidePadding = 0;
                topPadding = 0;
                break;
            default:
                sidePadding = verticalPadding;
                mobileSidePadding = verticalPadding;
                topPadding = verticalPadding;
        }

        return (
            <Transition in={this.state.in} timeout={0}>
                {state => (
                    <StyledPage
                        {...rest}
                        sidepadding={sidePadding}
                        mobilesidepadding={mobileSidePadding}
                        toppadding={topPadding}
                        style={{
                            ...defaultStyle,
                            ...transitionStyles[state]
                        }}
                    >
                        {children}
                    </StyledPage>
                )}
            </Transition>
        );
    }

    componentDidMount() {
        this.setState({ in: true });
    }
}

Page.propTypes = {
    /**
     * - 'none' (default) - absolutely no padding
     * - 'container' - only enough to avoid browser horizontal scrollbar when using a grid
     * - 'basic' - default padding for full white page
     */
    padding: PropTypes.oneOf(['none', 'container', 'basic'])
};

Page.defaultProps = {
    padding: 'none'
};

export default Page;
