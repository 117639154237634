export default class User {
    constructor(rawData) {
        this.id = rawData.id;
        this.firstName = rawData.first_name;
        this.lastName = rawData.last_name;
        this.fullName = `${rawData.first_name} ${rawData.last_name}`;
        this.email = rawData.email;
        this.newsletter = rawData.newsletter;
        this.smsPhoneNumberFormatted = rawData.sms_phone_number_formatted;
        this.accounts = rawData.accounts;
        this.memberships = rawData.memberships;
    }
}
