export default class WizardContext {
    constructor(steps, launchSource, serializedContext = null) {
        if (serializedContext == null) {
            this.steps = steps;
            this.currentStepIndex = 0;
            this.launchSource = launchSource;
            this.accountNumbersAddedByWizard = [];
            this.isAtLeastOneAccountTapEligible = false;
            this.areAllAccountsEnrolledInAutomaticPayments = false;
            this.memberNumberAddedByWizard = null;
            this.isMembershipPaperless = false;
            this.wasFromOnboarding = false;
        } else {
            Object.keys(serializedContext).forEach(key => {
                this[key] = serializedContext[key];
            });
        }
    }

    getAccountsAddedByWizard(appState) {
        return appState.accounts.filter(account =>
            this.accountNumbersAddedByWizard.includes(account.accountNumber)
        );
    }

    // Working with account details before it has been placed in app state.
    // That is why it hasn't been transformed yet.
    _accountsAddedByWizard(accountDetails) {
        return accountDetails.filter(accountDetail =>
            this.accountNumbersAddedByWizard.includes(
                accountDetail.account_number
            )
        );
    }

    _contextUpdated(user, accountDetails) {
        this.isAtLeastOneAccountTapEligible = false;
        this.areAllAccountsEnrolledInAutomaticPayments = true;
        const wizardAccounts = this._accountsAddedByWizard(accountDetails);
        wizardAccounts.forEach(wizardAccount => {
            if (wizardAccount.type !== 'other') {
                this.isAtLeastOneAccountTapEligible = true;
            }

            if (wizardAccount.automatic_payment === false) {
                this.areAllAccountsEnrolledInAutomaticPayments = false;
            }
        });

        this.isUserSubscribedToNewsletter = user.newsletter;

        this.steps = this.steps.filter(step => !step.shouldSkip(this));
    }

    addAccount(user, accountDetails, accountNumberStr) {
        const accountNumber = parseInt(accountNumberStr, 10);
        this.accountNumbersAddedByWizard.push(accountNumber);
        this._contextUpdated(user, accountDetails);
    }

    addMembership(user, accountDetails, memberNumber, isMembershipPaperless) {
        //TODO: DAVE -- This needs to dispatch an action to update memberships.
        this.memberNumberAddedByWizard = memberNumber;
        this.isMembershipPaperless = isMembershipPaperless;
        user.accounts.forEach(account => {
            if (account.member_number === memberNumber) {
                this.accountNumbersAddedByWizard.push(account.account_number);
            }
        });
        this._contextUpdated(user, accountDetails);
    }

    /**
     * Proceeds to the next step in the wizard process.
     *
     * @param {{}} location The current window.location.
     * @return {*} The next step in the wizard process.
     */
    next(location) {
        // Resetting current index based on location, in case user clicked the
        // back button.
        const currentStepIndex = this.steps.findIndex(step => {
            return step.path === location.pathname;
        });
        this.currentStepIndex = currentStepIndex < 0 ? 1 : currentStepIndex + 1;
        return this.steps[this.currentStepIndex];
    }
}
