import formatCurrency from 'utils/formatCurrency';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translation_en from 'locales/en/translation.json';
import translation_es from 'locales/es/translation.json';

const resources = {
    en: {
        translation: translation_en
    },
    es: {
        translation: translation_es
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: navigator.language,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // React already does escaping
            format: function(value, format, _) {
                if (format === 'currency') {
                    return formatCurrency(value);
                }
                return value;
            }
        }
    });

export default i18n;
