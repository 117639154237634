import { SingleColumnForm, ThemedLinkUpper, ThemedTextField } from 'common/styles';
import styled, { materialUiTheme } from 'theme';
import AppConstants from 'AppConstants.js';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import { connect } from 'react-redux';
import ContainerBase from 'common/containers/ContainerBase';
import { DialogConfirm } from 'common/components/dialogs';
import DividerWithSubheader from 'common/components/DividerWithSubheader';
import FireflyAuthenticatedApi from 'api/FireflyAuthenticatedApi';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import LoadingIndicator from 'common/components/LoadingIndicator';
import { mapStateToProps } from 'reducers';
import { NavButton } from 'common/components/nav-button';
import NotFoundPage from 'common/components/NotFoundPage';
import ProgressButton from 'common/components/ProgressButton';
import React from 'react';
import SettingsPage from 'features/settings/components/SettingsPage';
import SuccessSnackbar from 'common/components/SuccessSnackbar';
import { unlinkMembership } from 'actions';
import { updateMembershipAlias } from 'actions';
import { withTranslation } from 'react-i18next';

const ButtonRow = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    width: 100%;
    ${materialUiTheme.breakpoints.down('xs')} {
        flex-direction: column;
        align-items: flex-start;
    }
`;
const CancelButton = styled(NavButton)`
    && {
        margin-left: 20px;
    }
`;
const SaveButton = styled(ProgressButton)`
    && {
        width: 100px;
    }
`;
const DeleteButton = styled(Button)`
    && {
        color: ${AppConstants.COLOR_ERROR};
        ${materialUiTheme.breakpoints.down('xs')} {
            margin-top: 24px;
        }
    }
`;
const StyledFormGroup = styled(FormGroup)`
    margin-bottom: 10px;
`;

class MembershipDetailsContainer extends ContainerBase {
    constructor(props) {
        const {
            appState = props.appState || {},
            dispatch,
            location = props.location || {},
            match = props.match || {}
        } = props;

        const memberNumber = Number(match.params && match.params.member_number);
        const memberships = appState.memberships || [];
        const membership = memberships.filter(it => it.memberNumber === memberNumber)[0];
        const tapEnrollmentURL = `${location.pathname}/tap-enrollment`;
        let tapEnrollmentSuccess = false;

        if (location.state && location.state.tapEnrollmentSuccess) {
            tapEnrollmentSuccess = location.state.tapEnrollmentSuccess;
        }

        super(props, {
            loadingPreference: false,
            processing: false,
            isSnackbarOpen: false,
            membership,
            memberNumber,
            alias: !membership || membership.alias === null
                ? ''
                : membership.alias,
            tapEnrollmentURL: tapEnrollmentURL,
            referrer: '/settings/memberships',
            paperlessBilling: false,
            unlinkMembershipDialogIsOpen: false,
            tapEnrollmentSuccess: tapEnrollmentSuccess
        });

        this.api = new FireflyAuthenticatedApi({ appState, dispatch });
    }

    componentDidMount() {
        const { memberNumber } = this.state;

        this.api
            .getPaperlessBillingSetting(memberNumber)
            .then(response => {
                this.setState({
                    loadingPreference: false,
                    paperlessBilling: response.value
                });
            })
            .catch(error => {
                this.setState({ loadingPreference: false });
                if (error.response && error.response.code === 403) {
                    return;
                }
                this.showErrorDialog(error);
            });
    }

    render() {
        const {
            alias,
            loadingPreference,
            membership,
            paperlessBilling,
            processing,
            tapEnrollmentSuccess,
            tapEnrollmentURL,
            unlinkMembershipDialogIsOpen
        } = this.state;

        const { t } = this.props;

        if (membership === undefined || membership === null) {
            return <NotFoundPage />;
        }

        const loadingOrProcessing = loadingPreference || processing;

        return (
            <SettingsPage
                title="settings.memberships.membershipDetails.header"
                subtitle={membership.getSubTitle(t)}
                select="/settings/memberships"
            >
                {loadingOrProcessing && <LoadingIndicator />}
                {!loadingOrProcessing && (
                    <div>
                        <SingleColumnForm>
                            <ThemedTextField
                                id="alias"
                                inputRef={this.aliasFocus}
                                type="text"
                                label={t('settings.accounts.accountDetails.alias')}
                                value={alias}
                                onChange={this.handleInputChange('alias')}
                                disabled={processing}
                            />

                            <DividerWithSubheader resourceId="settings.memberships.membershipDetails.options" />

                            <StyledFormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={paperlessBilling}
                                            onChange={this.handleCheckboxChange('paperlessBilling')}
                                            value="true"
                                            disabled={processing}
                                        />
                                    }
                                    label={t('settings.memberships.membershipDetails.paperlessBilling')}
                                />
                            </StyledFormGroup>
                            <ThemedLinkUpper to={tapEnrollmentURL}>
                                {t('settings.memberships.membershipDetails.tapEnrollment')}
                            </ThemedLinkUpper>
                        </SingleColumnForm>
                        <ButtonRow>
                            <div>
                                <SaveButton
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    loading={processing}
                                    onClick={this.handleOnSubmit}
                                >
                                    {t('common.saveButton')}
                                </SaveButton>
                                <CancelButton color="primary" to="/settings/memberships" disabled={processing}>
                                    {t('common.cancel')}
                                </CancelButton>
                            </div>
                            <DeleteButton type="button" onClick={this.handleOnDeleteClick}>
                                {t('settings.memberships.membershipDetails.unlinkMembership.title')}
                            </DeleteButton>
                        </ButtonRow>
                        <DialogConfirm
                            confirmButtonLabel={t('settings.memberships.membershipDetails.unlinkMembership.title')}
                            title={t('settings.memberships.membershipDetails.unlinkMembership.title')}
                            message={t('settings.memberships.membershipDetails.unlinkMembership.body')}
                            onCloseDialog={this.closeUnlinkMembershipDialog}
                            open={unlinkMembershipDialogIsOpen}
                        />
                    </div>
                )}
                <SuccessSnackbar
                    id="tapEnrollmentSuccess"
                    open={tapEnrollmentSuccess}
                    onClose={this.handleTapEnrollmentSuccessSnackbarClose}
                    message={t('settings.memberships.membershipDetails.tapEnrollmentSuccess')}
                />
            </SettingsPage>
        );
    }

    handleOnDeleteClick = event => {
        event.preventDefault();

        this.setState({ unlinkMembershipDialogIsOpen: true });
    }

    closeUnlinkMembershipDialog = wasConfirmed => {
        const {
            memberNumber,
            referrer
        } = this.state;

        const {
            dispatch,
            history
        } = this.props;

        if (!wasConfirmed) {
            this.setState({ unlinkMembershipDialogIsOpen: false });
            return;
        }

        this.setState({
            unlinkMembershipDialogIsOpen: false,
            processing: true
        });

        this.api
            .unlinkMembership(memberNumber)
            .then(() => {
                dispatch(unlinkMembership(memberNumber));
                history.push(referrer, { unlinkMembershipSuccess: true });
            })
            .catch(error => {
                this.setState({ processing: false });
                this.showErrorDialog(error);
            });
    }

    handleInputChange = name => ({ target } = {}) => {
        this.setState({ [name]: target.value });
    }

    handleCheckboxChange = name => ({ target } = {}) => {
        this.setState({ [name]: target.checked });
    }

    handleTapEnrollmentSuccessSnackbarClose = () => {
        this.setState({ tapEnrollmentSuccess: false });
    }

    handleOnSubmit = () => {
        const {
            alias = this.state.alias || '',
            memberNumber,
            paperlessBilling,
            referrer
        } = this.state;

        const {
            dispatch,
            history
        } = this.props;

        this.setState({ processing: true });
        const promises = [];
        promises.push(
            this.api.updatePaperlessBillingSetting(memberNumber, paperlessBilling)
        );
        promises.push(
            this.api.saveMembershipAlias(memberNumber, alias)
        );

        Promise.all(promises)
            .then(() => {
                this.setState({ processing: false });
                dispatch(
                    updateMembershipAlias({ memberNumber, alias })
                );
                history.push(referrer, { membershipDidUpdate: true });
            })
            .catch(error => {
                this.setState({ processing: false });
                this.showErrorDialog(error);
            });
    }
}

export default connect(mapStateToProps)(withTranslation()(MembershipDetailsContainer));
