import { addPaymentMethodFromCard, addPaymentMethodFromCheck } from '../addPaymentMethod';
import CardTextFields, { cardInputFieldNames, createCardPaymentMethod } from '../components/CardTextFields';
import CheckTextFields, { checkingInputFieldNames, createCheckPaymentMethod } from '../components/CheckTextFields';
import { DialogBasic, DialogConfirm } from '../../../common/components/dialogs';
import LoadingIndicator, { LoadingContainer } from '../../../common/components/LoadingIndicator';
import { ThemedActionUpper, ThemedListContainer } from '../../../common/styles';
import AppConstants from '../../../AppConstants';
import BillingPage from '../components/BillingPage';
import { comparePaymentMethods } from '../comparators';
import { connect } from 'react-redux';
import ContainerBase from '../../../common/containers/ContainerBase';
import CurrencyField from '../../../common/components/CurrencyField';
import DividerWithSubheader from '../../../common/components/DividerWithSubheader';
import FireflyAuthenticatedApi from '../../../api/FireflyAuthenticatedApi';
import formatCurrency from '../../../utils/formatCurrency';
import GridItem from '../../../common/components/GridItem';
import { mapStateToProps } from '../../../reducers';
import Menu from '@material-ui/core/Menu/Menu';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import { NavButton } from '../../../common/components/nav-button';
import PaymentMethod from '../../../model/PaymentMethod';
import ProgressButton from '../../../common/components/ProgressButton';
import React from 'react';
import styled from '../../../theme';
import Validator from '../../../validation/Validator';
import { withTranslation } from 'react-i18next';

const ButtonRow = styled.div`
    display: flex;
    justify-content: flex-start;
    margin-top: 16px;
`;

const CancelButton = styled(NavButton)`
    && {
        margin-left: 20px;
    }
`;

const SaveButton = styled(ProgressButton)`
    && {
        min-width: 100px;
    }
`;

const SelectMethodLink = styled(ThemedActionUpper)`
    display: flex;
    margin-bottom: 20px;
`;

const GridItemRight = styled(GridItem)`
    text-align: right;
`;

const GridContainerNoPadding = styled.div`
    display: flex;
    padding-bottom: 15px;
`;

const LastMenuItem = styled(MenuItem)`
&& {
    border-bottom: solid 1px ${AppConstants.COLOR_DIVIDER};
}
`;

const FORM_CARD = 'formCard';
const FORM_CHECK = 'formCheck';

class MakePaymentContainer extends ContainerBase {
    constructor(props) {
        const {
            appState = props.appState || {},
            dispatch
        } = props;

        const accounts = {};
        appState.accounts.forEach(it => {
            accounts[it.accountNumber] = it.amountDue > 0 ? it.amountDue : 0;
        });

        super(props, {
            processing: false,
            loadingPaymentMethods: true,
            paymentMethods: [],
            selectedPaymentMethod: null,
            selectedPaymentMethodType: null,
            selectMenuAnchorEl: null,
            basicDialogIsOpen: false,
            errorMessageTitle: '',
            errorMessageBody: '',
            linkText: 'wizard.automaticPayments.selectPaymentMethod',
            ...cardInputFieldNames,
            ...checkingInputFieldNames,
            ...accounts
        });

        this.state.noMoreCharge = appState.noMoreCharge;
        this.state.noMoreChecks = appState.noMoreChecks;

        this.api = new FireflyAuthenticatedApi({ appState, dispatch });
        this.validator = new Validator(this);
    }

    configureValidations(config) {
        // Validations for the credit card form.
        config.requireNotEmpty('nameOnCard', FORM_CARD);
        config.requireCreditCard('creditCard', FORM_CARD);
        config.requireExpirationDate('expirationDate', FORM_CARD);
        config.requireCvv('cvv', 'creditCard', FORM_CARD);
        config.requireNotEmpty('addressLine1', FORM_CARD);
        config.requireNotEmpty('city', FORM_CARD);
        config.requireNotEmpty('state', FORM_CARD);
        config.requireNotEmpty('zip', FORM_CARD);

        // Validations for the bank account form.
        config.requireNotEmpty('nameOnAccount', FORM_CHECK);
        config.requireRoutingNumber('routingNumber', FORM_CHECK);
        config.requireConfirmRoutingNumber(
            'routingNumberConfirm',
            'routingNumber',
            FORM_CHECK
        );
        config.requireNotEmpty('accountNumber', FORM_CHECK);
        config.requireConfirmAccountNumber(
            'accountNumberConfirm',
            'accountNumber',
            FORM_CHECK
        );
        config.requireNotEmpty('accountType', FORM_CHECK);
    }

    componentDidMount() {
        this.loadPaymentMethods();
    }

    componentDidUpdate() {
        this.validator.maybeFocus();
    }

    onSelectMethod = e => {
        e.preventDefault();
        this.setState({ selectMenuAnchorEl: e.currentTarget });
    }

    getPaymentAmount = () => {
        const {
            appState = this.props.appState || {}
        } = this.props;

        let runningTotal = 0;
        const accounts = appState.accounts || [];
        accounts.forEach(it => { runningTotal += Number(this.state[it.accountNumber]); });
        return Math.round(runningTotal * 100) / 100;
    }

    handleOnSubmit = () => {
        if (!this.isFormValid()) {
            return;
        }
        this.setState({ confirmDialogIsOpen: true });
    }

    onConfirmDialogClosed(wasConfirmed) {
        if (wasConfirmed) {
            this.makePayment();
        }
    }

    makePayment = () => {
        const {
            appState = this.props.appState || {},
            history
        } = this.props;

        const {
            saveAccount,
            saveCard,
            selectedPaymentMethod,
            selectedPaymentMethodType,
        } = this.state;

        this.setState({ processing: true });
        const accountAmounts = [];
        const accounts = appState.accounts || [];
        accounts.forEach(it => {
            accountAmounts.push({
                account_number: it.accountNumber,
                amount: Number(this.state[it.accountNumber])
            });
        });

        let paymentMethod;
        switch (selectedPaymentMethodType) {
            case 'card':
                paymentMethod = createCardPaymentMethod(this);
                break;
            case 'checking':
                paymentMethod = createCheckPaymentMethod(this);
                break;
            default:
                paymentMethod = selectedPaymentMethod;
        }

        const accountsGtZero = accountAmounts.filter(it => it.amount > 0);
        this.api
            .makePayment(accountsGtZero, paymentMethod, selectedPaymentMethodType)
            .then(() => {
                let promise = Promise.resolve();
                if (selectedPaymentMethodType === 'card' && saveCard) {
                    promise = addPaymentMethodFromCard(this);
                } else if (selectedPaymentMethodType === 'checking' && saveAccount) {
                    promise = addPaymentMethodFromCheck(this);
                }
                promise
                    .then(() => {
                        history.push('/billing/payment-successful');
                    })
                    .catch(error => {
                        this.showErrorDialog(error);
                    });
            })
            .catch(error => {
                this.setState({ processing: false });
                if (error.response && error.response.error_code) {
                    switch (error.response.error_code) {
                        case 'payment_failure':
                            this.setState({
                                errorMessageTitle: 'billing.makePayment.error.validationFailed.title',
                                errorMessageBody: 'billing.makePayment.error.validationFailed.body'
                            });
                            this.showBasicDialog();
                            break;
                        case 'minimum_payment_too_low':
                            this.setState({
                                errorMessageTitle: 'billing.makePayment.error.paymentTooLow.title',
                                errorMessageBody: 'billing.makePayment.error.paymentTooLow.body'
                            });
                            this.showBasicDialog();
                            break;
                        case 'payment_failed':
                            this.setState({
                                errorMessageTitle: 'billing.makePayment.error.paymentFailed.title',
                                errorMessageBody: 'billing.makePayment.error.paymentFailed.body'
                            });
                            this.showBasicDialog();
                            break;
                        case 'invalid_card':
                            this.setState({
                                errorMessageTitle: 'billing.makePayment.error.paymentFailed.title',
                                errorMessageBody: 'billing.paymentMethods.creditCard.errors.invalidCard'
                            });
                            this.showBasicDialog();
                            break;
                        default:
                            this.showErrorDialog(error);
                    }
                } else if (
                    error.response &&
                    error.response.code &&
                    error.response.code === 422 &&
                    error.response.errors
                ) {
                    let errorShown = false;
                    // eslint-disable-next-line no-unused-vars
                    for (const errorDetail of error.response.errors) {
                        if (errorDetail.field === 'card_details.name_on_card' && errorDetail.error_code === 'invalid') {
                            errorShown = true;
                            this.setState({
                                errorMessageTitle: 'billing.makePayment.error.invalidNameOnCard.title',
                                errorMessageBody: 'billing.makePayment.error.invalidNameOnCard.body'
                            });
                            this.showBasicDialog();
                            break;
                        } else if (errorDetail.field === 'card_number' &&
                            errorDetail.error_code === 'unsupported_card_brand'
                        ) {
                            errorShown = true;
                            this.setState({
                                errorMessageTitle: 'billing.validation.creditCard.unsupportedCardBrand.title',
                                errorMessageBody: 'billing.validation.creditCard.unsupportedCardBrand.message'
                            });
                            this.showBasicDialog();
                            break;
                        } else if (errorDetail.field === 'routing_number' &&
                            errorDetail.error_code === 'unsupported_financial_institution'
                        ) {
                            errorShown = true;
                            this.setState({
                                errorMessageTitle: 'billing.validation.checking.unsupportedFinancialInstitution.title',
                                errorMessageBody: 'billing.validation.checking.unsupportedFinancialInstitution.message'
                            });
                            this.showBasicDialog();
                            break;
                        }
                    }
                    if (!errorShown) {
                        this.showErrorDialog(error);
                    }
                } else {
                    this.showErrorDialog(error);
                }
            });
    }

    isFormValid() {
        const { selectedPaymentMethodType } = this.state;

        if (this.getPaymentAmount() <= 0) {
            this.setState({
                errorMessageTitle: 'billing.makePayment.error.paymentTooLow.title',
                errorMessageBody: 'billing.makePayment.error.paymentTooLow.body'
            });
            this.showBasicDialog();
            return false;
        }

        if (selectedPaymentMethodType === null) {
            this.setState({
                errorMessageTitle: 'billing.makePayment.validation.noPaymentMethodSelected.title',
                errorMessageBody: 'billing.makePayment.validation.noPaymentMethodSelected.body'
            });
            this.showBasicDialog();
            return false;
        }

        if (selectedPaymentMethodType === 'card') {
            return this.validator.validate(FORM_CARD);
        }

        if (selectedPaymentMethodType === 'checking') {
            return this.validator.validate(FORM_CHECK);
        }

        return true;
    }

    loadPaymentMethods = () => {
        this.api
            .getPaymentMethods()
            .then(response => {
                const paymentMethods = response.map(it => new PaymentMethod(it));

                this.setState({
                    loadingPaymentMethods: false,
                    paymentMethods: paymentMethods
                });
            })
            .catch(error => {
                this.setState({ loadingPaymentMethods: false });
                this.showErrorDialog(error);
            });
    }

    handleSelectMenuItemClick = value => {
        const { processing } = this.state;
        const { t } = this.props;

        if (processing === true) {
            return;
        }
        switch (value) {
            case 'enter-check':
                this.setState({
                    selectMenuAnchorEl: null,
                    selectedPaymentMethod: null,
                    selectedPaymentMethodType: 'checking',
                    linkText: t('billing.enterCheck.header')
                });
                break;
            case 'enter-card':
                this.setState({
                    selectMenuAnchorEl: null,
                    selectedPaymentMethod: null,
                    selectedPaymentMethodType: 'card',
                    linkText: t('billing.enterCard.header')
                });
                break;
            default:
                if (!value.id) {
                    this.showErrorDialog(Error('Unsupported menu option.'));
                }
                this.setState({
                    selectMenuAnchorEl: null,
                    selectedPaymentMethod: value,
                    selectedPaymentMethodType: 'saved',
                    linkText: value.getDisplayName(t)
                });
        }
    }

    handleInputChange = accountNumber => ({ target } = {}) => {
        this.setState({ [accountNumber]: target.value });
    }

    filterPaymentMethods = (paymentMethods) => {
        const { t } = this.props;
        const { noMoreCharge, noMoreChecks } = this.state;

        if (noMoreCharge) {
            paymentMethods = paymentMethods.filter(it => it.type === 'check');
        }

        if (noMoreChecks) {
            paymentMethods = paymentMethods.filter(it => it.type === 'card');
        }

        return paymentMethods
            .sort((a, b) => comparePaymentMethods(a, b, t));
    }

    render() {
        const {
            appState,
            t
        } = this.props;

        const {
            basicDialogIsOpen,
            confirmDialogIsOpen,
            errorMessageBody,
            errorMessageTitle,
            linkText,
            loadingPaymentMethods,
            noMoreCharge,
            noMoreChecks,
            paymentMethods,
            processing,
            selectMenuAnchorEl,
            selectedPaymentMethodType,
        } = this.state;

        const accounts = appState.accounts || [];
        const paymentAmount = this.getPaymentAmount();
        const paymentAmountFormatted = formatCurrency(paymentAmount);

        const isPaymentProhibited = noMoreCharge && noMoreChecks;
        const pageTitle = isPaymentProhibited ? 'billing.common.unableToPay.title' : 'billing.makePayment.header';

        return (
            <BillingPage title={pageTitle} select="/billing" indentedHeader={false}>
                {loadingPaymentMethods ? (
                    <LoadingContainer>
                        <LoadingIndicator />
                    </LoadingContainer>
                ) : (
                    <ThemedListContainer>
                        {isPaymentProhibited ? (
                            <p>{t('billing.common.unableToPay.message')}</p>
                        ) : (
                            <>
                                {accounts.map(it => (
                                    <CurrencyField
                                        id={`${it.accountNumber}`}
                                        key={it.accountNumber}
                                        inputRef={this[`${it.accountNumber}Focus`]}
                                        label={it.getDisplayName(t)}
                                        value={this.state[it.accountNumber]}
                                        disabled={processing}
                                        onChange={this.handleInputChange(it.accountNumber)}
                                    />
                                ))}
                                <DividerWithSubheader resourceId={'billing.makePayment.paymentInformation'} />

                                <GridContainerNoPadding>
                                    <GridItem xs={6}>
                                        {t('common.headings.amount')}
                                    </GridItem>
                                    <GridItemRight xs={6}>
                                        {paymentAmountFormatted}
                                    </GridItemRight>
                                </GridContainerNoPadding>

                                <SelectMethodLink onClick={e => this.onSelectMethod(e)}>
                                    {t(linkText)}
                                </SelectMethodLink>
                                <Menu
                                    id="select-menu"
                                    anchorEl={selectMenuAnchorEl}
                                    open={Boolean(selectMenuAnchorEl)}
                                    onClose={() => this.setState({ selectMenuAnchorEl: null })}
                                >
                                    {this.filterPaymentMethods(paymentMethods)
                                        .map((paymentMethod, index) => {
                                            if (index === paymentMethods.length - 1) {
                                                return (
                                                    <LastMenuItem
                                                        key={paymentMethod.id}
                                                        value={paymentMethod.id}
                                                        onClick={e => this.handleSelectMenuItemClick(paymentMethod, e)}
                                                    >
                                                        {t(paymentMethod.getDisplayName(t))}
                                                    </LastMenuItem>
                                                );
                                            }

                                            return (
                                                <MenuItem
                                                    key={paymentMethod.id}
                                                    value={paymentMethod.id}
                                                    onClick={e => this.handleSelectMenuItemClick(paymentMethod, e)}
                                                >
                                                    {t(paymentMethod.getDisplayName(t))}
                                                </MenuItem>
                                            );
                                        })}
                                    {!noMoreCharge && (
                                        <MenuItem
                                            value="enter-card"
                                            onClick={e => this.handleSelectMenuItemClick('enter-card', e)}
                                        >
                                            {t('billing.enterCard.header')}
                                        </MenuItem>
                                    )}
                                    {!noMoreChecks && (
                                        <MenuItem
                                            value="enter-check"
                                            onClick={e => this.handleSelectMenuItemClick('enter-check', e)}
                                        >
                                            {t('billing.enterCheck.header')}
                                        </MenuItem>
                                    )}
                                </Menu>

                                {selectedPaymentMethodType === 'card' && (
                                    <CardTextFields
                                        parent={this}
                                        t={t}
                                        alwaysShowAlias={false}
                                        showSaveCheckbox={true}
                                    />
                                )}

                                {selectedPaymentMethodType === 'checking' && (
                                    <CheckTextFields
                                        parent={this}
                                        t={t}
                                        alwaysShowAlias={false}
                                        showSaveCheckbox={true}
                                    />
                                )}

                                <ButtonRow>
                                    <SaveButton
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        loading={processing}
                                        onClick={this.handleOnSubmit}
                                    >
                                        {t('billing.makePayment.submitPayment')}
                                    </SaveButton>

                                    <CancelButton color="primary" to="/billing" disabled={processing}>
                                        {t('common.cancel')}
                                    </CancelButton>
                                </ButtonRow>
                            </>
                        )}
                    </ThemedListContainer>
                )}
                <DialogBasic
                    message={t(errorMessageBody)}
                    onCloseDialog={this.closeBasicDialog}
                    open={basicDialogIsOpen}
                    title={t(errorMessageTitle)}
                />
                <DialogConfirm
                    confirmButtonLabel={t('billing.makePayment.confirmPayment.confirmButton')}
                    message={t('billing.makePayment.confirmPayment.message', { amount: paymentAmount })}
                    onCloseDialog={this.closeConfirmDialog}
                    open={confirmDialogIsOpen}
                    title={t('billing.makePayment.confirmPayment.title')}
                    loading={processing}
                />
            </BillingPage>
        );
    }
}

export default connect(mapStateToProps)(withTranslation()(MakePaymentContainer));
