import BillingPage from 'features/billing/components/BillingPage';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import ContainerBase from 'common/containers/ContainerBase';
import { mapStateToProps } from 'reducers';
import React from 'react';
import styled from 'theme';
import { ThemedPageHeader } from 'common/styles';
import { withTranslation } from 'react-i18next';

const PageContent = styled.div`
    padding-left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
`;
const Notice = styled.p`
    margin-top: 0;
    margin-bottom: 30px;
`;

class PaymentSuccessfulContainer extends ContainerBase {
    onContinue = () => {
        const { history } = this.props;

        history.push('/billing', { returnedFromSuccessfulPayment: true });
    }

    render() {
        const { t } = this.props;

        return (
            <BillingPage select="/billing" title="" indentedHeader={false}>
                <PageContent>
                    <ThemedPageHeader style={{ textAlign: 'center' }}>
                        {t('billing.makePayment.success.title')}
                    </ThemedPageHeader>
                    <Notice>{t('billing.makePayment.success.body')}</Notice>
                    <Button onClick={this.onContinue} color="primary" variant="contained">
                        {t('common.continue')}
                    </Button>
                </PageContent>
            </BillingPage>
        );
    }
}

export default connect(mapStateToProps)(withTranslation()(PaymentSuccessfulContainer));
