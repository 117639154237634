import apiMixin from 'api/apiMixin';

export default class FireflyApi extends apiMixin(class {}) {
    createUser(firstName, lastName, email, password) {
        return this.postJson('users', {
            first_name: firstName,
            last_name: lastName,
            email: email,
            password: password
        });
    }

    login(username, password) {
        const form = {
            grant_type: 'password',
            username: username,
            password: password
        };

        return this.postForm('oauth2/token', form);
    }

    refreshToken(refreshToken) {
        const form = {
            grant_type: 'refresh_token',
            refresh_token: refreshToken
        };

        return this.postForm('oauth2/token', form);
    }

    forgotPassword(email) {
        return this.postJson('users/recover-password', { email: email });
    }

    resetPassword(token, newPassword, confirmPassword) {
        return this.postJson('users/reset-password', {
            token: token,
            new_password: newPassword,
            confirm_password: confirmPassword
        });
    }

    resendEmailVerification(email) {
        return this.postJson('users/resend-verification-email', {
            email: email
        });
    }

    verifyEmail(token) {
        return this.postJson('users/verify-email', { token: token });
    }
}
