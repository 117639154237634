import * as echarts from 'echarts';
import AppConstants from 'AppConstants';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'theme';
import { withTranslation } from 'react-i18next';

const ChartContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
`;
const Chart = styled.div`
    width: 100%;
    height: 500px;
`;
const MessageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 500px;
`;
const NoData = styled.span`
    color: ${AppConstants.COLOR_TEXT_SUB};
    font-size: 14px;
`;

export class BarChartUnit {
    static KWH = 'kwh'
    static USD = 'usd'
    static GALLONS = 'gallons'
}

class BarChart extends React.Component {
    constructor(props) {
        super(props);

        this.chartRef = React.createRef();
        this.chart = null;
    }

    componentDidMount() {
        this.drawOrUpdateChart();
    }

    componentDidUpdate() {
        this.drawOrUpdateChart();
    }

    componentWillUnmount() {
        if (this.chart) {
            this.chart.dispose();
        }
    }

    drawOrUpdateChart() {
        const {
            series = this.props.series || []
        } = this.props;

        if (series.length > 0) {
            this.chart = echarts.init(this.chartRef.current);
            this.chart.setOption(this.getConfig());
        }
    }

    getConfig() {
        const {
            series = this.props.series || [],
            xaxis,
            xaxisStart,
            showTemperature
        } = this.props;

        const echartSeries = series.map((data, i) => ({
            name: series[i].name,
            type: 'bar',
            stack: 'stack1',
            data: series[i].data,
            itemStyle: { color: AppConstants.CHART_COLORS[i] }
        }));
        if (showTemperature) {
            echartSeries.push(this.createTemperatureSeries('high'));
            echartSeries.push(this.createTemperatureSeries('low'));
        }

        const yAxis = [this.createYAxis('left')];
        if (showTemperature) {
            yAxis.push(this.createYAxis('right'));
        }

        return {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                },
                backgroundColor: '#ffffff',
                textStyle: {
                    color: AppConstants.COLOR_TEXT,
                    fontFamily: 'Soleil'
                },
                extraCssText: 'box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);',
                padding: 16
            },
            legend: {
                data: series.map(data => ({ icon: 'circle', name: data.name })),
                itemGap: 20,
                textStyle: {
                    color: AppConstants.COLOR_TEXT,
                    fontFamily: 'Soleil'
                }
            },
            xAxis: {
                data: xaxis,
                axisLabel: {
                    fontFamily: 'Soleil'
                }
            },
            yAxis: yAxis,
            series: echartSeries,
            dataZoom: xaxisStart
                ? [
                      {
                          startValue: xaxisStart
                      },
                      {
                          type: 'inside'
                      }
                  ]
                : []
        };
    }

    createTemperatureSeries(type) {
        const {
            t,
            temperatureSeries = this.props.temperatureSeries || []
        } = this.props;

        const name = type === 'high'
            ? 'common.charting.barChart.high'
            : 'common.charting.barChart.low';
        const colorIndex = type === 'high' ? 0 : 1;
        return {
            name: t(name),
            type: 'line',
            yAxisIndex: 1,
            smooth: true,
            data: temperatureSeries.map(value => value[type]),
            itemStyle: { color: AppConstants.TEMP_COLORS[colorIndex] },
            lineStyle: {
                width: 4
            },
            symbol: 'none'
        };
    }

    createYAxis(type) {
        const { units } = this.props;

        let yAxisName;
        if (type === 'left') {
            switch (units) {
                case BarChartUnit.KWH:
                    yAxisName = 'kWh            ';
                    break;
                case BarChartUnit.GALLONS:
                    yAxisName = 'Gal          ';
                    break;
                default:
                    yAxisName = '$       ';
            }
        } else {
            yAxisName = '       °F';
        }
        const yAxis = {
            type: 'value',
            name: yAxisName,
            nameTextStyle: {
                color: AppConstants.COLOR_TEXT,
                fontFamily: 'Soleil',
                fontSize: 16
            },
            axisLine: { show: false },
            axisTick: { show: false },
            axisLabel: {
                color: AppConstants.COLOR_TEXT,
                fontFamily: 'Soleil'
            }
        };
        if (type === 'right') {
            yAxis.splitLine = {
                show: false
            };
        }
        return yAxis;
    }

    render() {
        const {
            isLoading,
            series = this.props.series || [],
            t
        } = this.props;

        if (series.length === 0) {
            return (
                <MessageContainer>
                    {isLoading ? (
                        <CircularProgress />
                    ) : (
                        <NoData>{t('common.charting.barChart.noData')}</NoData>
                    )}
                </MessageContainer>
            );
        }

        return (
            <ChartContainer>
                <Chart ref={this.chartRef} />
            </ChartContainer>
        );
    }
}

BarChart.propTypes = {
    series: PropTypes.array.isRequired,
    xaxis: PropTypes.array.isRequired,
    xaxisStart: PropTypes.string,
    temperatureSeries: PropTypes.array,
    showTemperature: PropTypes.bool,
    units: PropTypes.string,
    isLoading: PropTypes.bool
};

BarChart.defaultProps = {
    xaxisStart: 0,
    temperatureSeries: [],
    showTemperature: true,
    units: BarChartUnit.KWH,
    isLoading: false
};

export default withTranslation()(BarChart);
