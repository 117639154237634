import AuthorizationRequesthandler from 'api/AuthorizationRequestHandler';

const authenticatedMixin = superclass =>
    class extends superclass {
        constructor(args) {
            super(args);

            if (args.accessToken) {
                this.accessToken = args.accessToken;

                // ds: this is the redux dispatch method, needed by the
                // AuthorizationRequestHandler to set the app state with the
                // updated access token.
                this.dispatch = args.dispatch;

                if (!this.dispatch) {
                    throw new Error(
                        'AuthenticatedMixin constructor called without passing dispatch as an argument.'
                    );
                }
            }

            if (args.appState) {
                this.accessToken = args.appState.accessToken;
                this.appState = args.appState;

                // ds: this is the redux dispatch method, needed by the
                // AuthorizationRequestHandler to set the app state with the
                // updated access token.
                this.dispatch = args.dispatch;

                if (!this.dispatch) {
                    throw new Error(
                        'AuthenticatedMixin constructor called without passing dispatch as an argument.'
                    );
                }
            }
        }

        /**
         * This makes a secured call (using super class) without the access token
         * refresh logic.
         * @param {*} args
         */
        makeSecuredCall(args) {
            return super.executeRequest(...args);
        }

        executeRequest(
            method,
            url,
            queryString,
            body,
            requestType,
            handleEmpty200 = false
        ) {
            const args = [
                method,
                url,
                queryString,
                body,
                requestType,
                handleEmpty200
            ];

            return AuthorizationRequesthandler.executeRequest(this, args);
        }

        applyHeaders(requestType, headers) {
            if (super.applyHeaders) {
                headers = super.applyHeaders(requestType, headers);
            }

            const newHeaders = {
                Authorization: `Bearer ${this.accessToken}`
            };

            return { ...headers, ...newHeaders };
        }
    };

export default authenticatedMixin;
