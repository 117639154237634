import dayjs from 'dayjs';
import { DEFAULT_APP_STATE } from 'model/AppState';
import { mergeAccountsAndDetails } from 'model/Account';
import sum from 'utils/sum';

/**
 * Returns a cloned object with updated user and account info.
 */
function cloneStateWhenAccountsChange(user, accountDetails) {
    const memberships = convertMemberships(user.memberships);
    const accounts = mergeAccountsAndDetails(user.accounts, accountDetails);
    const financialDetails = calculateFinancialDetails(accounts);

    return {
        user: { ...user },
        memberships: memberships,
        accounts: accounts,
        amountDue: financialDetails.amountDue,
        amountDueDate: financialDetails.amountDueDate,
        amountDueDateType: financialDetails.amountDueDateType,
        noMoreCharge: financialDetails.noMoreCharge,
        noMoreChecks: financialDetails.noMoreChecks
    };
}

/**
 * Converts the memberships array from its API form to it's appState form.
 */
function convertMemberships(memberships) {
    return memberships.map(membership => {
        return {
            memberNumber: membership.member_number,
            alias: membership.alias
        };
    });
}

function calculateFinancialDetails(accountDetails) {
    const noMoreCharge = accountDetails.some(account => account.noMoreCharge);
    const noMoreChecks = accountDetails.some(account => account.noMoreChecks);

    const oldestDueDateAccount =
        accountDetails
            .filter(
                account =>
                    account.displayDueDate !== null &&
                    account.displayDueDate !== undefined
            )
            .sort((a, b) => {
                return new Date(b.displayDueDate) - new Date(a.displayDueDate);
            })[0] || null;
    const oldestDueDate =
        oldestDueDateAccount !== null
            ? oldestDueDateAccount.displayDueDate
            : null;

    const totalBalance = accountDetails
        .filter(account => {
            return !(
                account.prepaid ||
                account.balance === null ||
                account.balance === undefined ||
                account.balance < 0
            );
        })
        .map(it => it.balance)
        .reduce(sum, 0);

    // Purposely not using Number.parseFloat for IE11 compatibility reasons
    const totalPastDue = accountDetails
        .filter(account => {
            return !(
                account.pastDueAmount === null ||
                account.pastDueAmount === undefined
            );
        })
        .map(it => parseFloat(it.pastDueAmount))
        .reduce(sum, 0);

    let amountDue = 0.0;
    let amountDueDate = null;
    let amountDueDateType = 'not_due';

    if (totalPastDue > 0) {
        amountDue = totalPastDue;
        amountDueDateType = 'past_due';
    } else if (totalBalance > 0) {
        amountDue = totalBalance;
        amountDueDate = oldestDueDate;
        amountDueDateType = 'due';
    }

    return {
        amountDue: amountDue,
        amountDueDate: amountDueDate,
        amountDueDateType: amountDueDateType,
        noMoreCharge: noMoreCharge,
        noMoreChecks: noMoreChecks
    };
}

const appStateReducer = (state = DEFAULT_APP_STATE, action) => {
    switch (action.type) {
        case 'CHANGE_EMAIL':
            return {
                ...state,
                ...{
                    user: {
                        ...state.user,
                        email: action.newEmail
                    }
                }
            };
        case 'LINK_ACCOUNTS':
            return {
                ...state,
                ...cloneStateWhenAccountsChange(
                    action.user,
                    action.accountDetails
                ),
                wizardContext: action.wizardContext
            };
        case 'LOGIN':
            return {
                ...state,
                ...cloneStateWhenAccountsChange(
                    action.user,
                    action.accountDetails
                ),
                ...{
                    isAuthenticated: true,
                    accessToken: action.accessToken,
                    refreshToken: action.refreshToken,
                    isImpersonated: action.isImpersonated,
                    hasSessionExpired: false
                }
            };
        case 'LOGOUT':
            return {
                ...state,
                ...{
                    isAuthenticated: false,
                    isImpersonated: false,
                    accessToken: null,
                    refreshToken: null,
                    user: null,
                    memberships: [],
                    accounts: [],
                    amountDue: null,
                    amountDueDate: null,
                    amountDueDateType: null,
                    noMoreCharge: false,
                    noMoreChecks: false,
                    hasSessionExpired: false
                }
            };
        case 'EXPIRE_TOKEN':
            return {
                ...state,
                ...{
                    isAuthenticated: false,
                    isImpersonated: false,
                    accessToken: null,
                    refreshToken: null,
                    user: null,
                    memberships: [],
                    accounts: [],
                    amountDue: null,
                    amountDueDate: null,
                    amountDueDateType: null,
                    noMoreCharge: false,
                    noMoreChecks: false,
                    hasSessionExpired: true
                }
            };
        case 'REFRESH_DATA':
            return {
                ...state,
                ...cloneStateWhenAccountsChange(
                    action.user,
                    action.accountDetails
                ),
                ...{
                    lastAccountsRefresh: dayjs().toISOString()
                }
            };
        case 'UNLINK_ACCOUNT':
            // Using anonymous function to allow for private variable space.
            return (() => {
                const userAccounts = state.user.accounts.filter(
                    it => it.account_number !== action.accountNumber
                );
                const accounts = state.accounts.filter(
                    it => it.accountNumber !== action.accountNumber
                );
                const financialDetails = calculateFinancialDetails(accounts);
                return {
                    ...state,
                    user: {
                        ...state.user,
                        accounts: userAccounts
                    },
                    accounts: accounts,
                    amountDue: financialDetails.amountDue,
                    amountDueDate: financialDetails.amountDueDate,
                    amountDueDateType: financialDetails.amountDueDateType,
                    noMoreCharge: financialDetails.noMoreCharge,
                    noMoreChecks: financialDetails.noMoreChecks
                };
            })();
        case 'UNLINK_MEMBERSHIP':
            // Using anonymous function to allow for private variable space.
            return (() => {
                const userAccounts = state.user.accounts.filter(
                    it => it.member_number !== action.memberNumber
                );
                const accounts = state.accounts.filter(
                    it => it.memberNumber !== action.memberNumber
                );
                const financialDetails = calculateFinancialDetails(accounts);
                const memberships = state.memberships.filter(
                    it => it.memberNumber !== action.memberNumber
                );
                return {
                    ...state,
                    user: {
                        ...state.user,
                        accounts: userAccounts
                    },
                    accounts: accounts,
                    memberships: memberships,
                    amountDue: financialDetails.amountDue,
                    amountDueDate: financialDetails.amountDueDate,
                    amountDueDateType: financialDetails.amountDueDateType,
                    noMoreCharge: financialDetails.noMoreCharge,
                    noMoreChecks: financialDetails.noMoreChecks
                };
            })();
        case 'UPDATE_ACCESS_TOKEN':
            return {
                ...state,
                ...{
                    accessToken: action.accessToken,
                    refreshToken: action.refreshToken
                }
            };
        case 'UPDATE_ACCOUNT_ALIASES':
            action.accountAliasUpdates.forEach(aliasUpdate => {
                const account = state.accounts.find(
                    account =>
                        account.accountNumber === aliasUpdate.accountNumber
                );
                if (account) {
                    account.alias = aliasUpdate.newAlias;
                }
            });
            return {
                ...state
            };
        case 'UPDATE_ACCOUNT_AUTOMATIC_PAYMENT':
            action.automaticPaymentUpdates.forEach(automaticPaymentUpdate => {
                const account = state.accounts.find(account => {
                    return (
                        account.accountNumber ===
                        automaticPaymentUpdate.accountNumber
                    );
                });
                if (account) {
                    account.automaticPayment = automaticPaymentUpdate.newStatus;
                }
            });
            return {
                ...state
            };
        case 'UPDATE_MEMBERSHIP_ALIAS':
            const membership = state.memberships.find(
                it => it.memberNumber === action.aliasUpdate.memberNumber
            );
            if (membership) {
                membership.alias = action.aliasUpdate.alias;
                return { ...state };
            }
            return { ...state };
        case 'UPDATE_OUTAGE_STATUS':
            const account = state.accounts.find(
                account => account.accountNumber === action.accountNumber
            );

            if (account) {
                account.outageStatus = action.outageStatus;
            }

            return {
                ...state
            };
        case 'UPDATE_SMS_NUMBER':
            return {
                ...state,
                ...{
                    user: {
                        ...state.user,
                        smsPhoneNumberFormatted: action.smsNumberFormatted
                    }
                }
            };
        case 'UPDATE_PROFILE':
            return {
                ...state,
                ...{
                    user: {
                        ...state.user,
                        firstName: action.firstName,
                        lastName: action.lastName,
                        fullName: action.firstName + ' ' + action.lastName,
                        newsletter: action.newsletter
                    }
                }
            };
        case 'UPDATE_WIZARD_CONTEXT':
            return {
                ...state,
                ...{ wizardContext: action.wizardContext }
            };
        case 'UPDATE_ERROR_DIALOG_STATE':
            return {
                ...state,
                ...{ errorDialogIsOpen: action.errorDialogIsOpen }
            };
        case 'UPDATE_ERROR_DIALOG_ERROR':
            return {
                ...state,
                ...{ error: action.error }
            };
        default:
            return state;
    }
};

export default appStateReducer;
