/**
 * Removed the following by request from Branden on 11/15/2018
 *
 * { to: '/usage/water', name: 'usage.water.header', type: 'water' }
 *
 *   Dave,
 *   Please remove the water route for usage.
 *   Also, we noticed a bug with that screen as well.
 *   We have tested a few accounts that do not have water, but the nav item still shows.
 *   It confused several people. We need to make that dependent on having water once we have the data to back it.
 *
 *   -B
 */
export const navigations = [
    { to: '/usage/electric', name: 'usage.electric.header', type: 'electric' }
];
