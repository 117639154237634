import styled, { materialUiTheme, themes } from 'theme';
import AppConstants from 'AppConstants.js';
import Fab from '@material-ui/core/Fab';
import InfoIcon from '@material-ui/icons/Info';
import { Link } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

export const EmptyContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const EmptyImage = styled.img`
    height: 154px;
    width: 154px;
    ${props => (props.narrow ? '' : 'margin-top: 30px;')};
`;

export const EmptyMessage = styled.p`
    color: ${AppConstants.COLOR_TEXT_SUB};
    font-size: 16px;
    text-align: center;
`;

export const SingleColumnForm = styled.div`
    width: 100%;
    ${materialUiTheme.breakpoints.up('sm')} {
        max-width: 360px;
    }
`;

/**
 * Add any components that you want to define here simply for styling purposes.
 * Prefix all such components with the text 'Themed'
 */
export const ThemedAction = styled.a`
    ${themes.link};
`;

export const ThemedActionUpper = styled(ThemedAction)`
    text-transform: uppercase;
`;

export const ThemedLink = styled(Link)`
    ${themes.link};
`;

export const ThemedLinkUpper = styled(ThemedLink)`
    text-transform: uppercase;
`;

export const ThemedMenuItem = styled(MenuItem)`
    && {
        color: ${AppConstants.COLOR_TEXT};
    }
`;

export const ThemedPageHeader = styled.h1`
    font-size: 24px;
    text-transform: uppercase;
    margin-top: 0;
    text-align: ${props => (props.center ? 'center' : 'left')};
`;

export const ThemedListHeader = styled(ThemedPageHeader)`
    && {
        margin-left: 70px;
    }
`;

export const ThemedListContainer = styled.div`
    width: 100%;
    ${materialUiTheme.breakpoints.up('sm')} {
        max-width: 360px;
    }
`;

export const ThemedTextField = styled(TextField)`
    width: 100%;
    background-color: ${AppConstants.COLOR_TEXT_INPUT_BACKGROUND};
    && {
        margin-bottom: 20px;
    }
`;

export const ThemedFabButton = styled(Fab)`
    && {
        margin-left: 12px;
        margin-bottom: 4px;
    }
`;

export const ThemedListItem = styled(ListItem)`
    && {
        padding-left: 12px;
        padding-right: 12px;
    }
`;

export const ThemedInformation = styled(InfoIcon)`
    color: ${AppConstants.COLOR_INFO_ICON};
`;
