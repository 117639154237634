import { ContinueButton, Intro } from 'features/wizard/styles';
import { DialogBasic, DialogConfirm } from 'common/components/dialogs';
import AppConstants from 'AppConstants.js';
import Checkbox from '@material-ui/core/Checkbox';
import { connect } from 'react-redux';
import ContainerBase from 'common/containers/ContainerBase';
import FireflyAuthenticatedApi from 'api/FireflyAuthenticatedApi';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import LoadingIndicator from 'common/components/LoadingIndicator';
import { mapStateToProps } from 'reducers';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'theme';
import { ThemedActionUpper } from 'common/styles';
import { withTranslation } from 'react-i18next';
import WizardStep from 'features/wizard/components/WizardStep';

const TapEnrollmentForm = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

const TapFormGroup = styled(FormGroup)`
    margin-bottom: 24px;
`;

const WizardLoadingIndicator = styled(LoadingIndicator)`
    margin-top: 60px;
`;

const CenterCol = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const TapLink = styled(ThemedActionUpper)`
    margin-bottom: 20px;
    font-size: 14px;
`;

class WizardTapEnrollmentContainer extends ContainerBase {
    constructor(props) {
        super(props, {
            loadingTapEnrollmentSettings: true,
            basicDialogIsOpen: false,
            errorMessageTitle: '',
            errorMessageBody: ''
        });

        const {
            appState,
            dispatch,
            wizardContext = this.props.wizardContext || {}
        } = this.props;

        this.accounts = (wizardContext.getAccountsAddedByWizard(props.appState) || [])
            .filter(it => it.type !== 'other');
        this.state.tapSelections = this.accounts.map(() => false);
        this.api = new FireflyAuthenticatedApi({ appState, dispatch });
    }

    componentDidMount() {
        const promises = this.accounts.map(account =>
            this.api.getTapEnrollmentSetting(account.accountNumber)
        );

        Promise.all(promises)
            .then(savedTapSelections => {
                const updatedTapSelections = savedTapSelections.map(
                    it => it.value
                );
                this.setState({
                    loadingTapEnrollmentSettings: false,
                    tapSelections: updatedTapSelections
                });
            })
            .catch(error => {
                this.setState({ loadingTapEnrollmentSettings: false });
                this.showErrorDialog(error);
            });
    };

    render() {
        const { t } = this.props;

        const {
            basicDialogIsOpen,
            errorMessageBody,
            errorMessageTitle,
            confirmDialogIsOpen,
            loading,
            loadingTapEnrollmentSettings
        } = this.state;

        return (
            <WizardStep heading="wizard.tapEnrollment.header">
                {loadingTapEnrollmentSettings ? (
                    <WizardLoadingIndicator />
                ) : (
                    <CenterCol>
                        <Intro>{t('wizard.tapEnrollment.intro')}</Intro>
                        <TapLink
                            href={AppConstants.TAP_INFO_URL}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {t('wizard.tapEnrollment.tapInfoLink')}
                        </TapLink>
                        <TapEnrollmentForm onSubmit={this.onContinue}>
                            <TapFormGroup>
                                {this.accounts.map((account, i) => {
                                    return (
                                        <FormControlLabel
                                            key={i}
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    checked={this.state.tapSelections[i]}
                                                    disabled={this.state.loading}
                                                    onChange={this.handleCheckboxChange(i)}
                                                    value="true"
                                                />
                                            }
                                            label={account.getDisplayName(t)}
                                        />
                                    );
                                })}
                            </TapFormGroup>
                            <ContinueButton
                                key="continue"
                                variant="contained"
                                color="primary"
                                type="submit"
                                loading={loading}
                            >
                                {t('common.continue')}
                            </ContinueButton>
                        </TapEnrollmentForm>
                    </CenterCol>
                )}
                <DialogConfirm
                    confirmButtonLabel={t('common.agree')}
                    message={t('common.tapTerms.content')}
                    messageIsMarkdown={true}
                    onCloseDialog={this.closeConfirmDialog}
                    open={confirmDialogIsOpen}
                    title={t('common.tapTerms.title')}
                />
                <DialogBasic
                    message={t(errorMessageBody)}
                    onCloseDialog={this.closeBasicDialog}
                    open={basicDialogIsOpen}
                    title={t(errorMessageTitle)}
                />
            </WizardStep>
        );
    };

    handleCheckboxChange = index => ({ target } = {}) => {
        const {
            tapSelections = this.state.tapSelections || []
        } = this.state;

        tapSelections[index] = target.checked;
        this.setState({ tapSelections });
    };

    onContinue = e => {
        const {
            tapSelections = this.state.tapSelections || []
        } = this.state;

        e.preventDefault();
        const atLeastOneChecked = tapSelections.some(it => it);

        if (atLeastOneChecked) {
            this.showConfirmDialog();
        } else {
            this.saveTapSelections();
        }
    };

    onConfirmDialogClosed = wasConfirmed => {
        if (wasConfirmed) {
            this.saveTapSelections();
        }
    };

    saveTapSelections() {
        const {
            tapSelections = this.state.tapSelections || []
        } = this.state;

        const { onContinue } = this.props;

        this.setState({ loading: true });
        const promises = this.accounts.map((account, i) =>
            this.api.updateTapEnrollmentSetting(account.accountNumber, tapSelections[i])
        );

        Promise.all(promises)
            .then(() => {
                onContinue();
            })
            .catch(error => {
                this.setState({ loading: false });

                if (
                    error.response.error_code &&
                    error.response.error_code === 'tap_does_not_apply'
                ) {
                    this.setState({
                        errorMessageTitle: 'wizard.tapEnrollment.error.tapDoesNotApply.title',
                        errorMessageBody: 'wizard.tapEnrollment.error.tapDoesNotApply.body'
                    });
                    this.showBasicDialog();
                } else {
                    this.showErrorDialog(error);
                }
            });
    };
}

WizardTapEnrollmentContainer.propTypes = {
    onContinue: PropTypes.func.isRequired,
    wizardContext: PropTypes.object.isRequired,
    orientation: PropTypes.string.isRequired
};

export default connect(mapStateToProps)(withTranslation()(WizardTapEnrollmentContainer));
