import AppConstants from 'AppConstants.js';
import { connect } from 'react-redux';
import { mapStateToProps } from 'reducers';
import UsageContainerBase from 'features/usage/containers/UsageContainerBase';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';

const styles = theme => ({
    tabsIndicator: {
        backgroundColor: AppConstants.COLOR_PRIMARY_LIGHT
    },
    tabRoot: {
        maxWidth: 'none',
        width: '160px',
        paddingTop: 0,
        [theme.breakpoints.down('xs')]: {
            width: '50%'
        }
    },
    tabSelected: {
        color: AppConstants.COLOR_PRIMARY_LIGHT
    },
    typography: {
        fontSize: 50
    }
});

class ElectricUsageContainer extends UsageContainerBase {
    constructor(props) {
        super(props, 'electric', 'kwh');
    }
}

export default connect(mapStateToProps)(withStyles(styles)(withTranslation()(ElectricUsageContainer)));
