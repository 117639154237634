/*
 * This should only be called once in index.js.
 */

import { createGlobalStyle } from 'styled-components';
import SoleilBookWoff from 'fonts/soleil_book.woff';
import SoleilBookWoff2 from 'fonts/soleil_book.woff2';
import SoleilWoff from 'fonts/soleil_regular.woff';
import SoleilWoff2 from 'fonts/soleil_regular.woff2';

const SoleilFonts = createGlobalStyle`
    @font-face {
        font-display: auto;
        font-family: Soleil;
            src: url(${SoleilWoff2}) format('woff2'),
                url(${SoleilWoff}) format('woff');
        }
    @font-face {
        font-display: auto;
        font-family: SoleilBook;
            src: url(${SoleilBookWoff2}) format('woff2'),
                url(${SoleilBookWoff}) format('woff');
        }
`;

export default SoleilFonts;
