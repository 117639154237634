const LOCAL_STORAGE_KEY = 'https://www.talquinelectric.com:state';

const SessionDataAccess = {
    get() {
        try {
            const serializedState = localStorage.getItem(LOCAL_STORAGE_KEY);
            if (serializedState === null) {
                return null;
            }
            return JSON.parse(serializedState);
        } catch (err) {
            return null;
        }
    },

    save(state) {
        try {
            const serializedState = JSON.stringify(state);
            localStorage.setItem(LOCAL_STORAGE_KEY, serializedState);
        } catch (err) {}
    }
};

export default SessionDataAccess;
