import { ThemedPageHeader, ThemedTextField } from 'common/styles';
import { connect } from 'react-redux';
import ContainerBase from 'common/containers/ContainerBase';
import ErrorMessage from 'common/components/ErrorMessage';
import FireflyAuthenticatedApi from 'api/FireflyAuthenticatedApi';
import GridContainer from 'common/components/GridContainer';
import GridItem from 'common/components/GridItem';
import HelperText from 'common/components/HelperText';
import LeftNav from 'common/components/LeftNav';
import { mapStateToProps } from 'reducers';
import { NavButton } from 'common/components/nav-button';
import { navigations } from 'features/settings/containers/SettingsContainer';
import NumberFormat from 'react-number-format';
import Page from 'common/components/Page';
import PhoneField from 'common/components/PhoneField';
import ProgressButton from 'common/components/ProgressButton';
import React from 'react';
import styled from 'theme';
import { updateSmsNumber } from 'actions';
import Validator from 'validation/Validator';
import { withTranslation } from 'react-i18next';

const ButtonRow = styled(GridItem)`
    justify-content: flex-start;
`;
const CancelButton = styled(NavButton)`
    && {
        margin-left: 20px;
    }
`;
const SubmitButton = styled(ProgressButton)`
    && {
        min-width: 120px;
    }
`;

const PhoneDisplay = styled.div`
    margin-top: 0.2em;
    margin-bottom: 1em;
    font-size: 1.4em;
`;

class VerifySmsContainer extends ContainerBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            nextButton: 'common.next',
            phone: '',
            formattedPhone: '',
            phoneError: null,
            verificationCode: '',
            verificationCodeError: null,
            view: 'sms' // 'verificationCode'
        };

        this.validator = new Validator(this);
    }

    componentDidMount() {
        this.validator.maybeFocus();
    }

    componentDidUpdate() {
        this.validator.maybeFocus();
    }

    render() {
        const { t } = this.props;

        const {
            loading,
            nextButton,
            phone,
            phoneError,
            verificationCode,
            verificationCodeError,
            view
        } = this.state;

        return (
            <Page>
                <LeftNav
                    title="settings.header"
                    to="/settings"
                    navigations={navigations}
                    select="/settings/user-profile"
                >
                    <form onSubmit={this.submitPhone}>
                        <GridContainer>
                            <GridItem xs={12}>
                                <ThemedPageHeader>
                                    {t('settings.header')}
                                </ThemedPageHeader>
                            </GridItem>
                            <GridItem xs={12}>
                                {t('settings.verifyNumber.description')}
                            </GridItem>
                            {view === 'sms' && (
                                <GridItem xs={12} md={6}>
                                    <PhoneField
                                        id="phone"
                                        inputRef={this.phoneFocus}
                                        label={t('common.phoneNumber')}
                                        value={phone}
                                        onChange={this.phoneValueChange}
                                        error={phoneError !== null}
                                        disabled={loading}
                                    />
                                    <ErrorMessage resourceId={phoneError} />
                                    <HelperText resourceId="settings.verifyNumber.helperText" />
                                </GridItem>
                            )}
                            {view === 'verificationCode' && (
                                <GridItem xs={12} md={6}>
                                    <div>{t('common.phoneNumber')}</div>
                                    <PhoneDisplay>
                                        <NumberFormat value={phone} format="(###) ###-####" displayType={'text'} />
                                    </PhoneDisplay>
                                    <ThemedTextField
                                        id="verificationCode"
                                        inputRef={this.verificationCodeFocus}
                                        label="Verification Code"
                                        value={verificationCode}
                                        disabled={loading}
                                        onChange={this.handleInputChange('verificationCode')}
                                        error={verificationCodeError !== null}
                                    />
                                    <ErrorMessage resourceId={verificationCodeError}
                                    />
                                </GridItem>
                            )}
                        </GridContainer>
                        <GridContainer>
                            <ButtonRow>
                                <SubmitButton
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    loading={loading}
                                >
                                    {t(nextButton)}
                                </SubmitButton>
                                <CancelButton
                                    color="primary"
                                    disabled={loading}
                                    to="/settings/user-profile"
                                >
                                    {t('common.cancel')}
                                </CancelButton>
                            </ButtonRow>
                        </GridContainer>
                    </form>
                </LeftNav>
            </Page>
        );
    }

    phoneValueChange = (values = {}) => {
        const { formattedValue, value } = values;
        this.setState({ formattedPhone: formattedValue, phone: value });
    }

    handleInputChange = name => ({ target } = {}) => {
        this.setState({ [name]: target.value });
    }

    submitPhone = e => {
        const {
            appState,
            dispatch,
            history
        } = this.props;

        const {
            formattedPhone,
            phone,
            verificationCode,
            view
        } = this.state;

        e.preventDefault();

        const api = new FireflyAuthenticatedApi({ appState, dispatch});

        switch (view) {
            case 'verificationCode':
                if (!this.validator.validate('verificationForm')) {
                    return;
                }

                this.setState({ loading: true });
                api.confirmSmsVerificationCode(formattedPhone, verificationCode)
                    .then(() => {
                        this.setState({ loading: false });
                        dispatch(updateSmsNumber(formattedPhone));
                        history.push('/settings/user-profile', { showSmsUpdateSuccessSnackbar: true });
                    })
                    .catch(error => {
                        this.setState({ loading: false });
                        if (error.status === 422) {
                            if (
                                error.response.errors &&
                                error.response.errors.length > 0 &&
                                error.response.errors[0].error_code === 'invalid'
                            ) {
                                this.showFieldError('verificationCode', 'settings.verifyNumber.invalidCode');
                            }
                        } else {
                            this.showErrorDialog(error);
                        }
                    });
                break;
            default:
                if (!this.validator.validate('smsForm')) {
                    return;
                }

                this.setState({
                    loading: true,
                    nextButton: 'settings.verifyNumber.sendingVerification'
                });
                api.requestSmsVerificationCode(phone)
                    .then(() => {
                        this.setState({
                            loading: false,
                            view: 'verificationCode',
                            nextButton: 'common.saveButton'
                        });
                    })
                    .catch(error => {
                        this.setState({ loading: false });
                        this.showErrorDialog(error);
                    });
        }
    }

    configureValidations(config) {
        config.requireMobilePhone('phone', 'smsForm');
        config.requireNotEmpty('verificationCode', 'verificationForm');
    }
}

export default connect(mapStateToProps)(withTranslation()(VerifySmsContainer));
