import { EmptyImage, EmptyMessage } from 'common/styles';
import React, { Fragment } from 'react';
import styled, { materialUiTheme } from 'theme/index';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import AccountSelect from 'common/components/charting/AccountSelect';
import AppConstants from 'AppConstants.js';
import BarPieToggle from 'common/components/charting/BarPieToggle';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import ChartSettingsDrawer from './ChartSettingsDrawer';
import Hidden from '@material-ui/core/Hidden';
import PropTypes from 'prop-types';
import SettingsIcon from '@material-ui/icons/Settings';
import Slide from '@material-ui/core/Slide';
import { ThemedPageHeader } from 'common/styles';
import { withTranslation } from 'react-i18next';

export const supportedChartingViews = {
    chartBar: 'bar',
    chartPie: 'pie'
};

export const supportedTabs = {
    chartTab: 'CHART',
    dataTab: 'DATA'
};

const ChartPageBodyEmpty = styled.div`
    background-color: ${AppConstants.COLOR_LEFT_NAV_BACKGROUND};
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 30px;
`;

const ChartPageBody = styled.div`
    background-color: ${AppConstants.COLOR_LEFT_NAV_BACKGROUND};
    flex-grow: 1;
    ${materialUiTheme.breakpoints.down('xs')} {
        background-color: #ffffff;
    }
`;

const TabsRow = styled.div`
    border-bottom: 1px solid ${AppConstants.COLOR_DIVIDER};
`;

const SettingsButton = styled(Button)`
    && {
        position: absolute;
        top: 16px;
        right: 16px;
        min-width: 0;
        padding: 8px;
        ${materialUiTheme.breakpoints.down('xs')} {
            top: 0;
            right: 0;
        }
    }
`;

const StyledSettingsIcon = styled(SettingsIcon)`
    && {
        font-size: 32px;
        color: ${AppConstants.COLOR_CHART_INACTIVE_BUTTON};
    }
`;

const TabBody = styled.div`
    padding: 24px 36px 36px;
    ${materialUiTheme.breakpoints.down('xs')} {
        padding: 16px 24px 24px;
    }
`;

const TitleRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    position: relative; /* This is only needed for IE11 for AccountSelect z-index */
    z-index: 1; /* This is only needed for IE11 for AccountSelect z-index */
    ${materialUiTheme.breakpoints.down('xs')} {
        margin-bottom: 16px;
        flex-direction: column;
        justify-content: center;
    }
`;

const ChartHeader = styled(ThemedPageHeader)`
    margin: 0;
`;

const ChartCard = styled(Card)`
    position: relative;
    min-height: 600px;
    padding: ${props => (props.istable === 'true' ? '0px' : '16px')};
`;

const ChartCardPhone = styled.div`
    position: relative;
`;

const ChartControlRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 32px;
`;

class ChartDataTabs extends React.Component {
    state = {
        selectedAccountIndex: 0,
        settingsDrawerVisible: false
    }

    render() {
        const {
            accounts = this.props.accounts || [],
            children,
            selectedAccounts,
            selectedChartView,
            selectedTab,
            showTemperatureData,
            supportsTimeScale,
            t,
            tabs,
            timeScale,
            title,
            topPhoneContent,
            width
        } = this.props;

        const {
            selectedAccountIndex,
            settingsDrawerVisible,
        } = this.state;

        const ChartCardResponsive = isWidthUp('sm', width) ? ChartCard : ChartCardPhone;

        if (accounts.length === 0) {
            return (
                <ChartPageBodyEmpty>
                    <EmptyImage src="/img/empty_state_default.svg" />
                    <EmptyMessage>
                        {t('common.noAccountsLinkedMessage')}
                    </EmptyMessage>
                </ChartPageBodyEmpty>
            );
        }

        const selectedAccount = accounts[selectedAccountIndex];
        const remainingAccounts = accounts.filter((account, i) => i !== selectedAccountIndex);
        const PhoneContent = topPhoneContent;

        return (
            <ChartPageBody>
                <TabsRow>
                    {tabs}
                </TabsRow>
                <TabBody>
                    <TitleRow>
                        <ChartHeader>{title}</ChartHeader>
                        {selectedTab === supportedTabs.dataTab &&
                        (accounts.length === 1 ? (
                            <span>{selectedAccount.getDisplayName(t)}</span>
                        ) : (
                            <AccountSelect
                                selectedAccount={selectedAccount}
                                remainingAccounts={remainingAccounts}
                                onSelectAccount={this.selectAccount}
                            />
                        ))}
                    </TitleRow>
                    <Hidden smUp={true}>
                        {selectedTab === supportedTabs.chartTab &&
                        topPhoneContent !== null && <PhoneContent />}
                    </Hidden>
                    <ChartCardResponsive
                        istable={(selectedTab === supportedTabs.dataTab).toString()}
                    >
                        {selectedTab === supportedTabs.chartTab && (
                            <ChartControlRow>
                                {accounts.length > 1 && (
                                    <BarPieToggle selection={selectedChartView} onSelectChartType={this.selectChartType} />
                                )}
                            </ChartControlRow>
                        )}
                        {children}
                        {selectedTab === supportedTabs.chartTab && (
                            <Fragment>
                                <Slide direction="left" in={settingsDrawerVisible} mountOnEnter unmountOnExit>
                                    <ChartSettingsDrawer
                                        showTemperatureData={showTemperatureData}
                                        supportsTimeScale={supportsTimeScale}
                                        timeScale={timeScale}
                                        accounts={accounts}
                                        selectedAccounts={selectedAccounts}
                                        onUpdate={this.onSettingsUpdated}
                                        onClose={this.closeSettings}
                                    />
                                </Slide>
                                <SettingsButton onClick={this.toggleSettings} id="settings-button">
                                    <StyledSettingsIcon id="settings-button" />
                                </SettingsButton>
                            </Fragment>
                        )}
                    </ChartCardResponsive>
                </TabBody>
            </ChartPageBody>
        );
    }

    selectAccount = (account = {}) => {
        const {
            accounts = this.props.accounts || [],
            onSelectAccount
        } = this.props;

        const newAccountIndex = accounts.findIndex(searchAccount =>
            searchAccount.accountNumber === account.accountNumber
        );
        this.setState({ selectedAccountIndex: newAccountIndex });
        onSelectAccount(account);
    }

    selectChartType = chartType => {
        const { onChangeView } = this.props;

        if (chartType === 'bar') {
            onChangeView(supportedChartingViews.chartBar);
        } else {
            onChangeView(supportedChartingViews.chartPie);
        }
    }

    toggleSettings = () => {
        this.setState(state => ({ settingsDrawerVisible: !state.settingsDrawerVisible }));
    }

    closeSettings = ({ target } = {}) => {
        if (target.id === 'settings-button' || target.tagName === 'path') {
            return;
        }
        this.setState({ settingsDrawerVisible: false });
    }

    onSettingsUpdated = (showTemperatureData, timeScale, selectedAccounts) => {
        const { onSettingsUpdated } = this.props;

        this.setState({ settingsDrawerVisible: false });
        onSettingsUpdated(
            showTemperatureData,
            timeScale,
            selectedAccounts
        );
    }
}

ChartDataTabs.propTypes = {
    title: PropTypes.string.isRequired,
    accounts: PropTypes.array.isRequired,
    onSelectAccount: PropTypes.func,
    onChangeView: PropTypes.func,
    topPhoneContent: PropTypes.func,
    showTemperatureData: PropTypes.bool.isRequired,
    supportsTimeScale: PropTypes.bool.isRequired,
    timeScale: PropTypes.string.isRequired,
    selectedAccounts: PropTypes.array.isRequired,
    onSettingsUpdated: PropTypes.func,
    tabs: PropTypes.object.isRequired,
    selectedChartView: PropTypes.oneOf(Object.keys(supportedChartingViews).map(key => supportedChartingViews[key])).isRequired,
    selectedTab: PropTypes.oneOf(Object.keys(supportedTabs).map(key => supportedTabs[key])).isRequired
};

ChartDataTabs.defaultProps = {
    onSelectAccount: () => {
    },
    onChangeView: () => {
    },
    onSettingsUpdated: () => {
    },
    supportsTimeScale: true,
    topPhoneContent: null
};

export default withWidth()(withTranslation()(ChartDataTabs));
