import { EmptyContainer, EmptyImage, EmptyMessage } from 'common/styles';
import LoadingIndicator, { LoadingContainer } from 'common/components/LoadingIndicator';
import BillingPage from 'features/billing/components/BillingPage';
import { connect } from 'react-redux';
import ContainerBase from 'common/containers/ContainerBase';
import FireflyAuthenticatedApi from 'api/FireflyAuthenticatedApi';
import { mapStateToProps } from 'reducers';
import { PaymentHistoryTable } from '../components';
import React from 'react';
import { withTranslation } from 'react-i18next';

class PaymentHistoryContainer extends ContainerBase {
    constructor(props) {
        const {
            appState,
            dispatch,
            match = props.match || {},
        } = props;

        super(props, {
            accountNumber: Number(match.params && match.params.account_number),
            loadingPaymentHistory: true,
            paymentHistory: []
        });

        this.api = new FireflyAuthenticatedApi({ appState, dispatch });
    }

    componentDidMount() {
        this.loadPaymentHistory();
    }

    render() {
        const { t } = this.props;

        const {
            loadingPaymentHistory,
            paymentHistory = this.state.paymentHistory || []
        } = this.state;

        const hasPaymentHistoryToDisplay = paymentHistory.length > 0;

        return (
            <BillingPage title={'billing.paymentHistory.header'} select="/billing" indentedHeader={false}>
                {loadingPaymentHistory ? (
                    <LoadingContainer>
                        <LoadingIndicator />
                    </LoadingContainer>
                ) : (
                    <div>
                        {!hasPaymentHistoryToDisplay ? (
                            <EmptyContainer>
                                <EmptyImage src="/img/empty_state_payment_methods.svg" />
                                <EmptyMessage>
                                    {t('billing.paymentHistory.emptyStateMessage')}
                                </EmptyMessage>
                            </EmptyContainer>
                        ) : (
                            <PaymentHistoryTable
                                paymentHistory={this.state.paymentHistory} t={t}
                                receiptBaseUrl={this.getReceiptBaseUrl()}
                            />
                        )}
                    </div>
                )}
            </BillingPage>
        );
    }

    loadPaymentHistory = () => {
        const { accountNumber } = this.state;

        this.api
            .getPaymentHistory(accountNumber)
            .then(response => {
                this.setState({
                    loadingPaymentHistory: false,
                    paymentHistory: response
                });
            })
            .catch(error => {
                this.setState({ loadingPaymentHistory: false });
                this.showErrorDialog(error);
            });
    }

    getReceiptBaseUrl = () => {
        const { accountNumber } = this.state;
        const { appState } = this.props;

        const url = `accounts/${accountNumber}/payment-history/<id>/file?access_token=${appState.accessToken}`;
        return process.env.REACT_APP_API_DOMAIN + url;
    }
}

export default connect(mapStateToProps)(withTranslation()(PaymentHistoryContainer));
