import { DialogBasic, DialogConfirm } from 'common/components/dialogs';
import { SingleColumnForm, ThemedTextField } from 'common/styles';
import styled, { materialUiTheme } from 'theme';
import { unlinkAccount, updateAccountAliases } from 'actions';
import AccountType from 'features/settings/components/AccountType';
import AppConstants from 'AppConstants';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import { connect } from 'react-redux';
import ContainerBase from 'common/containers/ContainerBase';
import DividerWithSubheader from 'common/components/DividerWithSubheader';
import EnrolledInTap from 'features/settings/components/EnrolledInTap';
import FireflyAuthenticatedApi from 'api/FireflyAuthenticatedApi';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import InfoIcon from '@material-ui/icons/Info';
import LoadingIndicator from 'common/components/LoadingIndicator';
import { mapStateToProps } from 'reducers';
import { NavButton } from 'common/components/nav-button';
import NotFoundPage from 'common/components/NotFoundPage';
import ProgressButton from 'common/components/ProgressButton';
import React from 'react';
import SettingsPage from 'features/settings/components/SettingsPage';
import Tooltip from '@material-ui/core/Tooltip';
import { withTranslation } from 'react-i18next';

const StyledInformation = styled(InfoIcon)`
    color: ${AppConstants.COLOR_INFO_ICON};
`;

const ButtonRow = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    ${materialUiTheme.breakpoints.down('xs')} {
        flex-direction: column;
        align-items: flex-start;
    }
`;
const CancelButton = styled(NavButton)`
    && {
        margin-left: 20px;
    }
`;
const SaveButton = styled(ProgressButton)`
    && {
        width: 100px;
    }
`;
const DeleteButton = styled(Button)`
    && {
        color: ${AppConstants.COLOR_ERROR};
        ${materialUiTheme.breakpoints.down('xs')} {
            margin-top: 24px;
        }
    }
`;

const StyledAccountType = styled(AccountType)`
    padding-bottom: 15px;
`;

const CheckboxRow = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

class AccountDetailsContainer extends ContainerBase {
    constructor(props) {
        const {
            appState = props.appState || {},
            dispatch,
            match = props.match || {}
        } = props;

        super(props);
        const accountNumber = Number(match.params && match.params.account_number);
        const accounts = appState.accounts || [];
        const account = accounts.filter(it => it.accountNumber === accountNumber)[0];
        const alias = (account && account.alias) || '';

        this.state = {
            ...this.state,
            loading: false,
            processing: false,
            isSnackbarOpen: false,
            accountNumber: accountNumber,
            account: account,
            alias: alias === null ? '' : alias,
            outagePushNotifications: false,
            outageSmsNotifications: false,
            outageEmailNotifications: false,
            billingPushNotifications: false,
            billingSmsNotifications: false,
            billingEmailNotifications: false,
            enrolledInTap: false,
            unlinkAccountDialogIsOpen: false,
            basicDialogIsOpen: false,
            referrer: '/settings/accounts'
        };

        this.api = new FireflyAuthenticatedApi({ appState, dispatch });

        this.billingGroup = [
            'bill_due',
            'bill_generated',
            'bill_past_due',
            'payment_failure',
            'payment_success'
        ];
        this.outagesGroup = ['outages', 'pre_cutoff', 'post_cutoff'];
    }

    componentDidMount() {
        const { accountNumber } = this.state;

        this.setState({ loading: true });

        const promises = [];
        promises.push(this.api.getAccountNotificationPreferences(accountNumber));
        promises.push(this.api.getTapEnrollmentSetting(accountNumber));

        const newState = { ...this.state };

        Promise.all(promises)
            .then(response => {
                response[0].forEach(it => {
                    if (this.billingGroup.includes(it.notification_category)) {
                        it.selected_delivery_methods.forEach(method => {
                            switch (method) {
                                case 'sms':
                                    newState.billingSmsNotifications = true;
                                    break;
                                case 'push':
                                    newState.billingPushNotifications = true;
                                    break;
                                case 'email':
                                    newState.billingEmailNotifications = true;
                                    break;
                                default:
                                    throw new Error('Invalid method.');
                            }
                        });
                    } else if (
                        this.outagesGroup.includes(it.notification_category)
                    ) {
                        it.selected_delivery_methods.forEach(method => {
                            switch (method) {
                                case 'sms':
                                    newState.outageSmsNotifications = true;
                                    break;
                                case 'push':
                                    newState.outagePushNotifications = true;
                                    break;
                                case 'email':
                                    newState.outageEmailNotifications = true;
                                    break;
                                default:
                                    throw new Error('Invalid method.');
                            }
                        });
                    } else {
                        throw new Error('Invalid group.');
                    }
                });

                newState.loading = false;
                newState.enrolledInTap = response[1].value;

                this.setState(newState);
            })
            .catch(error => {
                this.setState({ loading: false });
                if (error.response && error.response.code === 403) {
                    return;
                }
                this.showErrorDialog(error);
            });
    }

    render() {
        const {
            loading,
            processing,
            account,
            outagePushNotifications,
            outageSmsNotifications,
            outageEmailNotifications,
            billingPushNotifications,
            billingSmsNotifications,
            billingEmailNotifications,
            basicDialogIsOpen,
            enrolledInTap,
            unlinkAccountDialogIsOpen,
            alias
        } = this.state;

        const { t } = this.props;
        const loadingOrProcessing = loading || processing;

        if (account === undefined || account === null) {
            return <NotFoundPage />;
        }

        return (
            <SettingsPage
                title="settings.accounts.accountDetails.header"
                subtitle={account.getSubTitle(t)}
                select="/settings/accounts"
            >
                {loadingOrProcessing && <LoadingIndicator />}
                {!loadingOrProcessing && (
                    <div>
                        <SingleColumnForm>
                            <ThemedTextField
                                id="alias"
                                inputRef={this.aliasFocus}
                                type="text"
                                label={t('settings.accounts.accountDetails.alias')}
                                value={alias}
                                onChange={this.handleInputChange('alias')}
                                disabled={processing}
                            />

                            <StyledAccountType account={account} />
                            <EnrolledInTap enrolledInTap={enrolledInTap}/>
                            <DividerWithSubheader resourceId="settings.accounts.accountDetails.outageNotificationsHeader" />
                            <FormGroup>
                                <CheckboxRow>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                color="primary"
                                                checked={outagePushNotifications}
                                                onChange={this.handleCheckboxChange('outagePushNotifications')}
                                                value="true"
                                                disabled={processing}
                                            />
                                        }
                                        label={t('notificationDeliveryMethods.push')}
                                    />
                                    <Tooltip title={t('settings.pushNotifications')} placement="right">
                                        <StyledInformation />
                                    </Tooltip>
                                </CheckboxRow>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={outageSmsNotifications}
                                            onChange={this.handleCheckboxChange('outageSmsNotifications')}
                                            value="true"
                                            disabled={processing}
                                        />
                                    }
                                    label={t('notificationDeliveryMethods.sms')}
                                />

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={outageEmailNotifications}
                                            onChange={this.handleCheckboxChange('outageEmailNotifications')}
                                            value="true"
                                            disabled={processing}
                                        />
                                    }
                                    label={t('notificationDeliveryMethods.email')}
                                />
                            </FormGroup>

                            <DividerWithSubheader resourceId="settings.accounts.accountDetails.billingNotificationsHeader" />

                            <FormGroup>
                                <CheckboxRow>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                color="primary"
                                                checked={billingPushNotifications}
                                                onChange={this.handleCheckboxChange('billingPushNotifications')}
                                                value="true"
                                                disabled={processing}
                                            />
                                        }
                                        label={t('notificationDeliveryMethods.push')}
                                    />
                                    <Tooltip title={t('settings.pushNotifications')} placement="right">
                                        <StyledInformation />
                                    </Tooltip>
                                </CheckboxRow>

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={billingSmsNotifications}
                                            onChange={this.handleCheckboxChange('billingSmsNotifications')}
                                            value="true"
                                            disabled={processing}
                                        />
                                    }
                                    label={t('notificationDeliveryMethods.sms')}
                                />

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={billingEmailNotifications}
                                            onChange={this.handleCheckboxChange('billingEmailNotifications')}
                                            value="true"
                                            disabled={processing}
                                        />
                                    }
                                    label={t('notificationDeliveryMethods.email')}
                                />
                            </FormGroup>
                        </SingleColumnForm>
                        <ButtonRow>
                            <div>
                                <SaveButton
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    loading={processing}
                                    onClick={this.handleOnSubmit}
                                >
                                    {t('common.saveButton')}
                                </SaveButton>
                                <CancelButton color="primary" to="/settings/accounts" disabled={processing}>
                                    {t('common.cancel')}
                                </CancelButton>
                            </div>
                            <DeleteButton type="button" onClick={this.handleOnDeleteClick}>
                                {t('settings.accounts.accountDetails.unlinkAccount.title')}
                            </DeleteButton>
                        </ButtonRow>
                        <DialogBasic
                            message={t('settings.accounts.accountDetails.cannotUnlinkAccount.body')}
                            onCloseDialog={this.closeBasicDialog}
                            open={basicDialogIsOpen}
                            title={t('settings.accounts.accountDetails.cannotUnlinkAccount.title')}
                        />
                        <DialogConfirm confirmButtonLabel={t('settings.accounts.accountDetails.unlinkAccount.title')}
                            title={t('settings.accounts.accountDetails.unlinkAccount.title')}
                            message={t('settings.accounts.accountDetails.unlinkAccount.body')}
                            onCloseDialog={this.closeUnlinkAccountDialog}
                            open={unlinkAccountDialogIsOpen}
                        />
                    </div>
                )}
            </SettingsPage>
        );
    }

    closeUnlinkAccountDialog = wasConfirmed => {
        const {
            accountNumber,
            referrer
        } = this.state;

        const {
            dispatch,
            history,
        } = this.props;
        this.setState({ unlinkAccountDialogIsOpen: false });

        if (!wasConfirmed) {
            return;
        }

        this.setState({ processing: true });

        this.api
            .unlinkAccount(accountNumber)
            .then(() => {
                dispatch(unlinkAccount(accountNumber));
                history.push(referrer, { unlinkAccountSuccess: true });
            })
            .catch(error => {
                this.setState({ processing: false });
                this.showErrorDialog(error);
            });
    }

    handleOnDeleteClick = event => {
        const {
            appState = this.props.appState || {}
        } = this.props;

        const { accountNumber } = this.state;

        event.preventDefault();

        const accounts = appState.accounts || [];
        const account = accounts.find(it => it.accountNumber === accountNumber);
        const canUnlink = account && !account.canGrantToOthers;

        if (canUnlink) {
            this.setState({ unlinkAccountDialogIsOpen: true });
        } else {
            this.setState({ basicDialogIsOpen: true });
        }
    }

    handleInputChange = name => ({ target } = {}) => {
        this.setState({ [name]: target.value });
    }

    handleCheckboxChange = name => ({ target } = {}) => {
        this.setState({ [name]: target.checked });
    }

    handleOnSubmit = () => {
        const {
            accountNumber,
            alias = this.state.alias || ''
        } = this.state;

        const {
            dispatch,
            history
        } = this.props;

        this.setState({ processing: true });

        const notificationPreferences = this.buildBillingGroupToPreferenceMapping()
            .concat(this.buildOutageGroupToPreferenceMapping());
        const promises = [];

        promises.push(
            this.api.saveAccountNotificationPreferences(
                accountNumber,
                notificationPreferences
            )
        );
        promises.push(this.api.saveAccountAlias(accountNumber, { alias }));

        Promise.all(promises)
            .then(() => {
                this.setState({
                    processing: false,
                    isSnackbarOpen: true
                });
                dispatch(
                    updateAccountAliases([
                        {
                            accountNumber: accountNumber,
                            newAlias: alias === '' ? null : alias
                        }
                    ])
                );

                history.push('/settings/accounts', { accountDidUpdate: true });
            })
            .catch(error => {
                this.setState({ processing: false });
                this.showErrorDialog(error);
            });
    }

    buildBillingPreferenceList() {
        const {
            billingEmailNotifications,
            billingPushNotifications,
            billingSmsNotifications
        } = this.state;

        const billingPreferences = [];

        if (billingEmailNotifications) {
            billingPreferences.push('email');
        }
        if (billingPushNotifications) {
            billingPreferences.push('push');
        }
        if (billingSmsNotifications) {
            billingPreferences.push('sms');
        }

        return billingPreferences;
    }

    buildOutagePreferenceList() {
        const {
            outageEmailNotifications,
            outagePushNotifications,
            outageSmsNotifications
        } = this.state;

        const outagePreferences = [];

        if (outageEmailNotifications) {
            outagePreferences.push('email');
        }
        if (outagePushNotifications) {
            outagePreferences.push('push');
        }
        if (outageSmsNotifications) {
            outagePreferences.push('sms');
        }

        return outagePreferences;
    }

    buildBillingGroupToPreferenceMapping() {
        const preferences = this.buildBillingPreferenceList();
        return this.billingGroup.map(it => {
            return {
                notification_category: it,
                selected_delivery_methods: preferences
            };
        });
    }

    buildOutageGroupToPreferenceMapping() {
        const preferences = this.buildOutagePreferenceList();
        return this.outagesGroup.map(it => {
            return {
                notification_category: it,
                selected_delivery_methods: preferences
            };
        });
    }
}

export default connect(mapStateToProps)(withTranslation()(AccountDetailsContainer));
