export default class PaymentMethod {
    constructor(rawData) {
        Object.assign(this, rawData);
    }

    getDisplayName(t) {
        if (this.alias !== null && this.alias !== '') {
            return this.alias;
        } else if (this.type === 'card') {
            return t('billing.paymentMethods.noAlias.card', {
                cardBrand: t('billing.cardBrands.' + this.card.card_brand),
                lastFourDigits: this.card.last_four_digits
            });
        } else if (this.type === 'check') {
            return t('billing.paymentMethods.noAlias.check', {
                financialInstitution: this.check.financial_institution,
                accountType: t(
                    'billing.bankAccountTypes.' + this.check.account_type
                )
            });
        } else {
            return '';
        }
    }
}
