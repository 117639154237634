import CurrencyAmount from '../../../../common/components/CurrencyAmount';
import FormattedDate from '../../../../common/components/FormattedDate';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import React from 'react';
import ReceiptIcon from '@material-ui/icons/Receipt';
import Table from '@material-ui/core/Table/Table';
import TableBody from '@material-ui/core/TableBody/TableBody';
import TableCell from '@material-ui/core/TableCell/TableCell';
import TableHead from '@material-ui/core/TableHead/TableHead';
import TableRow from '@material-ui/core/TableRow/TableRow';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import { useTranslation } from 'react-i18next';

/**
 * Displays the payment history for an account.
 */
export default function PaymentHistoryTable(props) {
    const {
        paymentHistory = props.paymentHistory || [],
        receiptBaseUrl
    } = props;

    const { t } = useTranslation();

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>{t('common.headings.date')}</TableCell>
                    <TableCell align="right">{t('common.headings.amount')}</TableCell>
                    <TableCell>&nbsp;</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {paymentHistory.map((payment, i) => {
                    const formattedDate = (
                        <FormattedDate
                            dateString={payment.paid_on}
                            format={{
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric'
                            }}
                        />
                    );

                    return (
                        <TableRow key={i}>
                            <TableCell>{formattedDate}</TableCell>
                            <TableCell align="right">
                                <CurrencyAmount amount={payment.amount} />
                            </TableCell>
                            <TableCell align="center">
                                <Tooltip title={t('billing.paymentHistory.downloadReceiptButton')} placement="bottom">
                                    <IconButton
                                        aria-label={t('billing.paymentHistory.downloadReceiptButton')}
                                        href={receiptBaseUrl.replace('<id>', payment.id)}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <ReceiptIcon />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    );
}

PaymentHistoryTable.propTypes = {
    paymentHistory: PropTypes.array.isRequired,
    receiptBaseUrl: PropTypes.string.isRequired
};
