import AppConstants from 'AppConstants.js';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'theme';
import { useTranslation } from 'react-i18next';

const ErrorMessageText = styled.p`
    color: ${AppConstants.COLOR_ERROR};
    margin: 0;
    font-size: 12px;
    position: relative;
    top: -17px;
`;

function ErrorMessage({ resourceId }) {
    const { t } = useTranslation();

    return (
        resourceId && ( <ErrorMessageText>{t(resourceId)}</ErrorMessageText> )
    );
}
ErrorMessage.propTypes = {
    resourceId: PropTypes.string
};

export default ErrorMessage;
