import Cleave from 'cleave.js/react';
import PropTypes from 'prop-types';
import React from 'react';
import { ThemedTextField } from 'common/styles';

function CreditCardFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <Cleave
            {...other}
            htmlRef={inputRef}
            options={{creditCard: true}}
            onChange={onChange}
        />
    );
}

CreditCardFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired
};

export default function CreditCardNumberField(props) {
    const { inputRef } = props;

    return (
        <ThemedTextField
            {...props}
            type="text"
            InputProps={{ inputComponent: CreditCardFormatCustom }}
            inputRef={inputRef}
        />
    );
}
