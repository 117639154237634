/**
 * This file contains the styled component theme settings.
 * We also set the material-ui theme here for styling components.
 */
import * as styledComponents from 'styled-components';
import AppConstants from 'AppConstants.js';
import { createTheme } from '@material-ui/core/styles';

const {
    default: styled,
    css,
    keyframes,
    ThemeProvider
} = styledComponents;

/**
 * Add all theme constants here.
 */
const theme = {
    primaryColor: AppConstants.COLOR_PRIMARY
};

/**
 * Add any re-usable css blocks here.
 */
const themes = {
    link: css`
        color: ${AppConstants.COLOR_LINKS};
        text-decoration: none;
        cursor: pointer;
    `
};

/**
 * Setting the Material-UI theme here.
 */
export const materialUiTheme = createTheme({
    palette: {
        primary: {
            contrastText: '#fff',
            dark: AppConstants.COLOR_PRIMARY_DARK,
            light: AppConstants.COLOR_PRIMARY_LIGHT,
            main: AppConstants.COLOR_PRIMARY
        },
        secondary: {
            contrastText: AppConstants.COLOR_PRIMARY,
            dark: AppConstants.COLOR_PRIMARY_LIGHT_BG,
            light: '#fff',
            main: '#fff'
        },
        error: {
            light: AppConstants.COLOR_ERROR_LIGHT,
            main: AppConstants.COLOR_ERROR,
            dark: AppConstants.COLOR_ERROR_DARK,
            contrastText: '#fff'
        },
        background: {
            default: AppConstants.COLOR_FOOTER_BACKGROUND
        }
    },
    typography: {
        fontFamily: ['Soleil', 'sans-serif'].join(','),
        /*fontSize: 14,*/
        button: {
            fontSize: '0.875rem',
            fontWeight: 'bold'
        },
        body1: {
            fontSize: '0.875rem',
        },
        body2: {
            fontSize: '1rem',
        },
        subtitle1: {
            fontSize: '1rem',
            fontWeight: 400,
            lineHeight: 1.5
        },
        subtitle2: {
            fontSize: '0.875rem',
            lineHeight: 1.46
        }
    },
    overrides: {
        MuiButton: {
            root: {
                lineHeight: 1.4,
                padding: '8px 16px'
            },
            outlined: {
                lineHeight: 1.4,
                padding: '8px 16px'
            },
            text: {
                padding: '8px 16px'
            }
        },
        MuiCard: {
            root: {
                lineHeight: 1.5
            }
        },
        MuiFab: {
            root: {
                fontSize: '1.5rem'
            }
        },
        MuiFormControlLabel: {
            root: {
                fontSize: '0.875rem'
            }
        },
        MuiFormLabel: {
            root: {
                fontSize: '1rem'
            }
        },
        MuiIconButton: {
            root: {
                fontSize: '1.5rem'
            }
        },
        MuiInputBase: {
            root: {
                fontSize: '1rem'
            },
            input: {
                height: '1.5rem'
            }
        },
        MuiListItem: {
            root: {
                lineHeight: 1.5,
                paddingTop: 12,
                paddingBottom: 12
            }
        },
        MuiListItemText: {
            root: {
                padding: '0 16px'
            },
            primary: {
                fontSize: '1rem',
                fontWeight: 400
            },
            secondary: {
                fontSize: '0.875rem'
            },
            multiline: {
                marginTop: 0,
                marginBottom: 0
            }
        },
        MuiMenuItem: {
            root: {
                fontSize: '1rem',
                paddingTop: '12px',
                paddingBottom: '12px'
            }
        },
        MuiStepIcon: {
            text: {
                fontSize: 14
            }
        },
        MuiSvgIcon: {
            root: {
                fontSize: '1.5rem'
            }
        },
        MuiTab: {
            root: {
                padding: 0
            }
        },
        MuiTableCell: {
            root: {
                padding: '12px 24px'
            },
            head: {
                color: AppConstants.COLOR_TABLE_HEADER,
                fontSize: '0.75rem'
            },
            body: {
                fontSize: '1rem'
            }
        },
        MuiTableRow: {
            root: {
                height: '48px'
            },
            head: {
                height: '56px'
            }
        },
        MuiTooltip: {
            tooltip: {
                fontSize: '0.75rem'
            }
        }
    }
});

export default styled;
export { themes, theme, css, keyframes, ThemeProvider };
