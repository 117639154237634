import { Link, withRouter } from 'react-router-dom';
import styled, { materialUiTheme } from 'theme';
import AppConstants from 'AppConstants.js';
import { connect } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import GridContainer from 'common/components/GridContainer';
import GridItem from 'common/components/GridItem';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { logout } from 'actions';
import { mapStateToProps } from 'reducers';
import MobileBillingIcon from '@material-ui/icons/AttachMoney';
import MobileHomeIcon from '@material-ui/icons/Home';
import MobileLogoutIcon from '@material-ui/icons/ExitToApp';
import MobileMenuIcon from '@material-ui/icons/Menu';
import MobileNotificationsIcon from '@material-ui/icons/Notifications';
import MobileSettingsIcon from '@material-ui/icons/Settings';
import MobileUsageIcon from '@material-ui/icons/Equalizer';
import { NavButton } from 'common/components/nav-button';
import NotificationsIcon from '@material-ui/icons/Comment';
import { ProfileMenu } from 'common/components/profile-menu';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { withTranslation } from 'react-i18next';

const AppBarBase = styled.div`
    background-color: ${AppConstants.COLOR_PRIMARY};
    color: #ffffff;
    position: fixed;
    display: flex;
    justify-content: center;
    width: 100%;
    top: 0;
    left: 0; // only used by IE11
    z-index: 2;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
        0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    background-image: ${props =>
        props.isImpersonated
            ? "url('/img/impersonation_watermark.png')"
            : 'none'};
    background-size: 200px 72px;
    ${materialUiTheme.breakpoints.down('xs')} {
        box-shadow: none;
    }
`;

const AppBarDesktop = styled.div`
    height: ${AppConstants.APP_BAR_HEIGHT_DESKTOP}px;
    width: 100%;
    max-width: ${AppConstants.MAX_WIDTH}px;
`;

const AppBarDesktopCol1 = styled(GridItem)`
    height: ${AppConstants.APP_BAR_HEIGHT_DESKTOP}px;
    display: flex;
    align-items: center;
`;

const AppBarDesktopCol2 = styled(GridItem)`
    height: ${AppConstants.APP_BAR_HEIGHT_DESKTOP}px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

const AppBarMobile = styled.div`
    height: ${AppConstants.APP_BAR_HEIGHT_MOBILE}px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
`;

const LogoLink = styled(Link)`
    display: flex;
`;

const LogoImage = styled.img`
    height: 25px;
    width: 164px; // only required by IE11
    margin-top: 4px;
    ${materialUiTheme.breakpoints.down('sm')} {
        height: 20px;
    }
`;

const AppBarButton = styled(NavButton)`
    && {
        color: #ffffff;
        padding-right: 30px;
        padding-left: 30px;
    }
`;

const AppBarButtonActive = styled(AppBarButton)`
    && {
        background-color: ${AppConstants.COLOR_PRIMARY_LIGHT};
        &:hover {
            background-color: ${AppConstants.COLOR_PRIMARY_LIGHT};
        }
    }
`;

const NotificationsButton = styled(NavButton)`
    && {
        padding: 9px 10px 7px;
        margin-left: 10px;
        color: #ffffff;
    }
`;

const StyledNotificationsIcon = styled(NotificationsIcon)`
    && {
        font-size: 32px;
    }
`;

const MenuButton = styled(IconButton)`
    && {
        margin-left: 5px;
        color: #ffffff;
        position: absolute;
        left: 0;
    }
`;

const StyledMobileMenuIcon = styled(MobileMenuIcon)`
    && {
        font-size: 24px;
    }
`;

const ListStyled = styled(List)`
    && {
        padding-left: 6px;
        padding-right: 6px;
    }
`;

const ListItemStyled = styled(ListItem)`
    && {
        border-radius: 4px;
        padding-left: 20px;
        min-width: 200px;
        color: ${AppConstants.COLOR_TEXT};
        &:hover {
            background-color: ${AppConstants.COLOR_PRIMARY_LIGHT_BG};
        }
    }
`;

const ListItemHighlighted = styled(ListItemStyled)`
    && {
        color: ${AppConstants.COLOR_PRIMARY}
        background-color: ${AppConstants.COLOR_PRIMARY_LIGHT_BG}
    }
`;

const ListItemText = styled.span`
    font-family: Soleil, sans-serif;
    margin-left: 16px;
`;

const ListItemTextHighlighted = styled(ListItemText)`
    color: ${AppConstants.COLOR_PRIMARY};
`;

const ProfileItem = styled.div`
    background-color: ${AppConstants.COLOR_PRIMARY};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 16px 26px;
    font-family: Soleil, sans-serif;
    color: #ffffff;
`;

const Name = styled.div`
    font-size: 24px;
`;

const Userid = styled.div`
    font-size: 14px;
    line-height: 1;
`;

const SelectableButton = props => {
    const {
        current,
        pathPrefix,
        routes,
        ...rest
    } = props;

    const routeSet = new Set(routes);

    if (
        (pathPrefix && current.startsWith(pathPrefix)) ||
        routeSet.has(current)
    ) {
        return <AppBarButtonActive {...rest} />;
    }
    return <AppBarButton {...rest} />;
};

const DrawerNavItem = props => {
    const {
        current,
        icon,
        label,
        to
    } = props;
    const Icon = icon;
    const ListItemComponent = current === to ? ListItemHighlighted : ListItemStyled;
    const ListItemTextComponent = current === to ? ListItemTextHighlighted : ListItemText;
    return (
        <ListItemComponent button={true} component={React.forwardRef((props, ref) => (<Link {...props} ref={ref} />))} to={to}>
            <Icon />
            <ListItemTextComponent>{label}</ListItemTextComponent>
        </ListItemComponent>
    );
};

class AppBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = { drawerIsOpen: false };
    }
    render() {
        const {
            appState = this.props.appState || {},
            location = this.props.location || {},
            t
        } = this.props;

        const {
            drawerIsOpen
        } = this.state;

        const currentPath = location.pathname;
        const user = appState.user || {};
        return (
            <AppBarBase className="mui-fixed" isImpersonated={appState.isImpersonated}>
                <Hidden smDown={true}>
                    <AppBarDesktop>
                        <GridContainer>
                            <AppBarDesktopCol1 xs={4} lg={3}>
                                <LogoLink to="/">
                                    <LogoImage src="/img/talquin_logo.svg" alt={t('common.aria.talquinLogo')} />
                                </LogoLink>
                            </AppBarDesktopCol1>
                            {appState.isAuthenticated === true && (
                                <AppBarDesktopCol2 xs={8} lg={9}>
                                    <SelectableButton
                                        routes={['/']}
                                        current={currentPath}
                                        color="primary"
                                        to={'/'}
                                    >
                                        {t('appbar.home')}
                                    </SelectableButton>
                                    <SelectableButton
                                        pathPrefix="/billing"
                                        current={currentPath}
                                        color="primary"
                                        to={'/billing'}
                                    >
                                        {t('appbar.billing')}
                                    </SelectableButton>
                                    <SelectableButton
                                        pathPrefix="/usage"
                                        current={currentPath}
                                        color="primary"
                                        to={'/usage'}
                                    >
                                        {t('appbar.usage')}
                                    </SelectableButton>
                                    <NotificationsButton
                                        aria-label={t('notifications.header')}
                                        color="primary"
                                        to={`/notifications`}
                                    >
                                        <Tooltip
                                            title={t('appbar.notifications')}
                                            placement="bottom"
                                        >
                                            <StyledNotificationsIcon />
                                        </Tooltip>
                                    </NotificationsButton>
                                    <ProfileMenu />
                                </AppBarDesktopCol2>
                            )}
                        </GridContainer>
                    </AppBarDesktop>
                </Hidden>
                <Hidden mdUp={true}>
                    <AppBarMobile>
                        {appState.isAuthenticated === true && (
                            <MenuButton color="primary" to={`/notifications`} onClick={this.toggleDrawer}>
                                <StyledMobileMenuIcon />
                            </MenuButton>
                        )}
                        <LogoLink to="/" aria-label={t('common.aria.talquinLogo')}>
                            <LogoImage src="/img/talquin_logo.svg" alt={t('common.aria.talquinLogo')} />
                        </LogoLink>
                    </AppBarMobile>
                </Hidden>
                {appState.isAuthenticated === true && (
                    <Drawer open={drawerIsOpen} onClose={this.toggleDrawer}>
                        <div tabIndex={0} role="button" onClick={this.toggleDrawer} onKeyDown={this.toggleDrawer}>
                            <ProfileItem component="div">
                                <Name>{user.fullName}</Name>
                                <Userid>
                                    {user.email}
                                </Userid>
                            </ProfileItem>
                            <ListStyled component="nav">
                                <DrawerNavItem
                                    to="/"
                                    label={t('appbar.home')}
                                    icon={MobileHomeIcon}
                                    current={currentPath}
                                />
                                <DrawerNavItem
                                    to="/billing"
                                    label={t('appbar.billing')}
                                    icon={MobileBillingIcon}
                                    current={currentPath}
                                />
                                <DrawerNavItem
                                    to="/usage"
                                    label={t('appbar.usage')}
                                    icon={MobileUsageIcon}
                                    current={currentPath}
                                />
                                <DrawerNavItem
                                    to="/notifications"
                                    label={t('appbar.notifications')}
                                    icon={MobileNotificationsIcon}
                                    current={currentPath}
                                />
                                <DrawerNavItem
                                    to="/settings"
                                    label={t('appbar.settings')}
                                    icon={MobileSettingsIcon}
                                    current={currentPath}
                                />
                                <ListItemStyled
                                    button={true}
                                    onClick={this.logout}
                                >
                                    <MobileLogoutIcon />
                                    <ListItemText>
                                        {t('appbar.logOut')}
                                    </ListItemText>
                                </ListItemStyled>
                            </ListStyled>
                        </div>
                    </Drawer>
                )}
            </AppBarBase>
        );
    }

    toggleDrawer = () => {
        this.setState(state => ({ drawerIsOpen: !state.drawerIsOpen }));
    }

    logout = () => {
        const {
            dispatch,
            history
        } = this.props;

        dispatch(logout());
        history.push('/');
    }
}

const AppBarWithRouter = withRouter(AppBar);
const AppBarTranslated = withTranslation()(AppBarWithRouter);
/**
 * See https://github.com/reduxjs/react-redux/blob/master/docs/troubleshooting.md#my-views-arent-updating-when-something-changes-outside-of-redux
 * to see why pure needed to be set to false.
 */
export default connect(
    mapStateToProps,
    null,
    null,
    { pure: false }
)(AppBarTranslated);
