import { ThemedInformation, ThemedTextField } from '../../../common/styles';
import Checkbox from '@material-ui/core/Checkbox';
import { CheckingAccountTypeList } from '../../../model/CheckingAccountTypeList';
import ErrorMessage from '../../../common/components/ErrorMessage';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import NewCheckDetails from '../../../model/NewCheckDetails';
import PropTypes from 'prop-types';
import React from 'react';
import RoutingNumberField from '../../../common/components/RoutingNumberField';
import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';

const CheckboxRow = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 16px;
`;

export const checkingInputFieldNames = {
    nameOnAccount: '',
    nameOnAccountError: null,
    routingNumber: '',
    formattedRoutingNumber: '',
    routingNumberError: null,
    routingNumberConfirm: '',
    routingNumberConfirmError: null,
    accountNumber: '',
    accountNumberError: null,
    accountNumberConfirm: '',
    accountNumberConfirmError: null,
    accountType: 'checking',
    accountTypeError: null,
    accountAlias: '',
    saveAccount: false
};

export function createCheckPaymentMethod(parent) {
    const {
        nameOnAccount,
        accountNumber,
        routingNumber,
        accountType
    } = parent.state;

    return new NewCheckDetails(
        nameOnAccount,
        accountNumber,
        routingNumber,
        accountType
    );
}

export default function CheckTextFields(props) {
    const { parent, t, alwaysShowAlias, showSaveCheckbox } = props;

    const handleInputChange = name => event => {
        parent.setState({ [name]: event.target.value });
    };

    const formattedValueChange = (fieldName, formattedFieldName) => event => {
        parent.setState({
            [fieldName]: event.target.rawValue,
            [formattedFieldName]: event.target.value
        });
    };

    const handleCheckboxChange = name => event => {
        parent.setState({ [name]: event.target.checked });
    };

    return (
        <>
            <ThemedTextField
                id="nameOnAccount"
                inputRef={parent.nameOnAccountFocus}
                label={t('billing.paymentMethods.check.nameOnAccount')}
                type="text"
                value={parent.state.nameOnAccount}
                disabled={parent.state.processing}
                onChange={handleInputChange('nameOnAccount')}
                error={parent.state.nameOnAccountError !== null}
            />
            <ErrorMessage resourceId={parent.state.nameOnAccountError} />

            <RoutingNumberField
                id="routingNumber"
                inputRef={parent.routingNumberFocus}
                label={t('billing.paymentMethods.check.routingNumber')}
                value={parent.state.routingNumber}
                disabled={parent.state.processing}
                onChange={formattedValueChange(
                    'routingNumber',
                    'formattedRoutingNumber'
                )}
                error={parent.state.routingNumberError !== null}
                inputProps={{ maxLength: 9 }}
            />
            <ErrorMessage resourceId={parent.state.routingNumberError} />

            <ThemedTextField
                id="routingNumberConfirm"
                inputRef={parent.routingNumberConfirmFocus}
                label={t('billing.paymentMethods.check.routingNumberConfirm')}
                type="text"
                value={parent.state.routingNumberConfirm}
                disabled={parent.state.processing}
                onChange={handleInputChange('routingNumberConfirm')}
                error={parent.state.routingNumberConfirmError !== null}
                inputProps={{ maxLength: 9 }}
            />
            <ErrorMessage resourceId={parent.state.routingNumberConfirmError} />

            <ThemedTextField
                id="accountNumber"
                inputRef={parent.accountNumberFocus}
                label={t('billing.paymentMethods.check.accountNumber')}
                type="text"
                value={parent.state.accountNumber}
                disabled={parent.state.processing}
                onChange={handleInputChange('accountNumber')}
                error={parent.state.accountNumberError !== null}
                inputProps={{ maxLength: 17 }}
            />
            <ErrorMessage resourceId={parent.state.accountNumberError} />

            <ThemedTextField
                id="accountNumberConfirm"
                inputRef={parent.accountNumberConfirmFocus}
                label={t('billing.paymentMethods.check.accountNumberConfirm')}
                type="text"
                value={parent.state.accountNumberConfirm}
                disabled={parent.state.processing}
                onChange={handleInputChange('accountNumberConfirm')}
                error={parent.state.accountNumberConfirmError !== null}
                inputProps={{ maxLength: 17 }}
            />
            <ErrorMessage resourceId={parent.state.accountNumberConfirmError} />

            <ThemedTextField
                select
                id="accountType"
                inputRef={parent.accountTypeFocus}
                label={t('billing.paymentMethods.check.accountType')}
                value={parent.state.accountType}
                disabled={parent.state.processing}
                onChange={handleInputChange('accountType')}
                error={parent.state.accountTypeError !== null}
            >
                {CheckingAccountTypeList.map(it => (
                    <MenuItem key={it.code} value={it.code}>
                        {t(it.type)}
                    </MenuItem>
                ))}
            </ThemedTextField>
            <ErrorMessage resourceId={parent.state.accountTypeError} />

            {showSaveCheckbox && (
                <CheckboxRow>
                    <FormControlLabel
                        control={
                            <Checkbox
                                color="primary"
                                checked={parent.state.saveAccount}
                                disabled={parent.state.processing}
                                onChange={handleCheckboxChange('saveAccount')}
                                value="true"
                            />
                        }
                        label={t('billing.paymentMethods.saveDetails.label')}
                    />
                    <Tooltip
                        title={t('billing.paymentMethods.saveDetails.info')}
                        placement="right"
                    >
                        <ThemedInformation />
                    </Tooltip>
                </CheckboxRow>
            )}

            {(alwaysShowAlias || parent.state.saveAccount) && (
                <ThemedTextField
                    id="accountAlias"
                    inputRef={parent.accountAliasFocus}
                    label={t('billing.paymentMethods.check.alias')}
                    type="text"
                    value={parent.state.accountAlias}
                    disabled={parent.state.processing}
                    onChange={handleInputChange('accountAlias')}
                />
            )}
        </>
    );
}

CheckTextFields.propTypes = {
    parent: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    alwaysShowAlias: PropTypes.bool.isRequired,
    showSaveCheckbox: PropTypes.bool.isRequired
};
