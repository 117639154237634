import AppConstants from 'AppConstants.js';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'theme';
import { useTranslation } from 'react-i18next';

const HelperTextMessage = styled.p`
    color: ${AppConstants.COLOR_TEXT_SUB};
    margin: 0;
    font-size: 12px;
    position: relative;
    top: -17px;
`;

function HelperText({ resourceId }) {
    const { t } = useTranslation();

    return (
        resourceId && (<HelperTextMessage>{t(resourceId)}</HelperTextMessage>)
    );
}
HelperText.propTypes = {
    resourceId: PropTypes.string.isRequired
};

export default HelperText;
