import { updateErrorDialogError, updateErrorDialogState } from 'actions';
import { ERROR_SUFFIX } from 'validation/Validator';
import InvalidRefreshTokenError from 'api/InvalidRefreshTokenError';
import React from 'react';

export default class ContainerBase extends React.Component {
    constructor(props, state) {
        super(props);
        this.state = {
            ...state,
            loading: false,
            basicDialogIsOpen: false,
            confirmDialogIsOpen: false,
            errorDialogIsOpen: false
        };
    }

    showBasicDialog = () => {
        this.setState({ basicDialogIsOpen: true });
    }

    closeBasicDialog = () => {
        this.setState({ basicDialogIsOpen: false });

        this.onBasicDialogClosed();
    }

    /**
     * Invoked after the basic dialog is closed.
     */
    onBasicDialogClosed() {}

    showConfirmDialog = () => {
        this.setState({ confirmDialogIsOpen: true });
    }

    closeConfirmDialog = wasConfirmed => {
        this.setState({ confirmDialogIsOpen: false });

        this.onConfirmDialogClosed(wasConfirmed);
    }

    onConfirmDialogClosed() {}

    showErrorDialog = error => {
        const {
            appState = this.props.appState || {},
            dispatch
        } = this.props;

        if (error instanceof InvalidRefreshTokenError) {
            return;
        }

        console.error(error);

        if (error.response) {
            console.error('API Response:', error.response);

            // PM - If the `errors` array is non-empty, then there are field-level
            // errors and we do not show a dialog.
            if (!error.response.errors || error.response.errors.length === 0) {
                dispatch(updateErrorDialogError(error.response));
                dispatch(updateErrorDialogState(true));
            }
        }

        if (appState.isAuthenticated === true) {
            const user = appState.user || {};
            window.bugsnagClient.user = {
                id: user.id,
                name: user.fullName,
                email: user.email
            };

            window.bugsnagClient.metaData = {
                react: {
                    appState: appState
                }
            };
        }

        window.bugsnagClient.notify(error, {
            beforeSend: function(report) {
                report.metaData = {
                    react: {
                        apiResponse: error.response
                    }
                };
            }
        });
    }

    showFieldError(fieldName, errorResourceId) {
        const newState = { loading: false };
        newState[fieldName + ERROR_SUFFIX] = errorResourceId;
        this.setState(newState);
        if (this.validator) {
            this.validator.setFocus(fieldName);
            this.validator.maybeFocus();
        }
    }
}
