import Account from './Account';
import AppConstants from '../AppConstants';
import BannerNotificationType from './BannerNotificationType';
import dayjs from 'dayjs';
import Membership from './Membership';
import { OutageStatuses } from '../features/home/components/OutageStatus';
import { wizardContextFromAppState } from '../features/wizard/WizardContextBuilder';

export const DEFAULT_APP_STATE = {
    version: process.env.REACT_APP_VERSION,
    isAuthenticated: false,
    isImpersonated: false,
    hasSessionExpired: false,
    lastAccountsRefresh: dayjs().toISOString()
};

const alphaSort = (a, b, t) => {
    const nameA = a.getDisplayName(t).toUpperCase();
    const nameB = b.getDisplayName(t).toUpperCase();
    if (nameA < nameB) {
        return -1;
    }
    if (nameA > nameB) {
        return 1;
    }
    return 0;
};

export default class AppState {
    constructor(rawData) {
        Object.assign(this, rawData);
        if (this.isAuthenticated) {
            this.accounts = this.accounts.map(it => new Account(it));
            this.memberships = this.memberships.map(it => new Membership(it));
            this.wizardContext = wizardContextFromAppState(this);
        }
    }

    getNotificationBannerMessage(t) {
        if (this.accounts) {
            this.accounts.forEach(account => {
                switch (account.outageStatus) {
                    case OutageStatuses.PLANNED:
                        return {
                            message: t('notificationBanner.plannedOutage'),
                            type: BannerNotificationType.NOTICE,
                            open: true
                        };

                    case OutageStatuses.CONFIRMED:
                        return {
                            message: t('notificationBanner.confirmedOutage'),
                            type: BannerNotificationType.CONFIRMATION,
                            open: true
                        };
                    default:
                }
            });
        }

        return { message: '', type: null, open: false };
    }

    accountsSorted(t) {
        return this.accounts.sort((a, b) => alphaSort(a, b, t));
    }

    membershipsSorted(t) {
        return this.memberships.sort((a, b) => alphaSort(a, b, t));
    }

    hasAccountType(accountType) {
        const account = this.accounts.find(account => {
            return account.type === accountType;
        });
        return !!account;

    }

    isStale() {
        const staleLimit = dayjs().subtract(
            AppConstants.APP_STATE_TIME_TO_LIVE_MINUTES,
            'minute'
        );
        return dayjs(this.lastAccountsRefresh).isBefore(staleLimit);
    }
}
