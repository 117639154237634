import PropTypes from 'prop-types';
import React from 'react';
import styled from 'theme';

const LineContainer = styled.div`
    flex: 1 1 auto;
    padding: 0 16px;
    min-width: 0;
`;

const PrimaryLine = styled.span`
    color: rgba(0, 0, 0, 0.87);
    font-size: 1rem;
    font-weight: 400;
    font-family: Soleil, sans-serif;
    line-height: 1.5em;
    margin: 0;
    display: block;
`;

const AdditionalLine = styled.p`
    color: rgba(0, 0, 0, 0.54);
    font-size: 0.875rem;
    font-weight: 400;
    font-family: Soleil, sans-serif;
    line-height: 1.46429em;
    margin: 0;
    display: block;
`;

function ThreeLineListItemText({ primary, secondary, tertiary }) {
    return (
        <LineContainer>
            <PrimaryLine>{primary}</PrimaryLine>
            <AdditionalLine>{secondary}</AdditionalLine>
            <AdditionalLine>{tertiary}</AdditionalLine>
        </LineContainer>
    );
}

ThreeLineListItemText.propTypes = {
    primary: PropTypes.string.isRequired,
    secondary: PropTypes.string.isRequired,
    tertiary: PropTypes.string.isRequired
};

export default ThreeLineListItemText;
