import React, { Fragment } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CurrencyAmount from 'common/components/CurrencyAmount';
import FormattedDate from 'common/components/FormattedDate';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import styled from 'theme';
import Table from '@material-ui/core/Table/Table';
import TableBody from '@material-ui/core/TableBody/TableBody';
import TableCell from '@material-ui/core/TableCell/TableCell';
import TableHead from '@material-ui/core/TableHead/TableHead';
import TableRow from '@material-ui/core/TableRow/TableRow';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';

const MessageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 600px;
`;

function DataTable(props) {
    const {
        bills = props.bills || [],
    } = props;

    const { t } = useTranslation();

    const format = {
        year: 'numeric',
        month: 'long'
    };

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>
                        {t('billing.dataTable.headers.month')}
                    </TableCell>
                    <TableCell>
                        {t('billing.dataTable.headers.amount')}
                    </TableCell>
                    <TableCell />
                </TableRow>
            </TableHead>
            <TableBody>
                {bills.map((bill, i) => {
                    const date = (
                        <FormattedDate dateString={bill.bill_date} format={format} />
                    );

                    return (
                        <TableRow key={i}>
                            <TableCell>{date}</TableCell>
                            <TableCell>
                                <CurrencyAmount amount={bill.current_charges} />
                            </TableCell>
                            <TableCell align="right">
                                <Tooltip title={t('billing.history.viewBillButton')} placement="bottom">
                                    <IconButton
                                        aria-label={t('billing.history.viewBillButton')}
                                        href={bill.bill_view_url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <CloudDownloadIcon />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    );
}

class AccountBillsList extends React.Component {
    render() {
        const {
            bills,
            loading,
            t,
        } = this.props;

        return (
            <Fragment>
                {loading ? (
                    <MessageContainer>
                        <CircularProgress />
                    </MessageContainer>
                ) : (
                    <DataTable t={t} bills={bills} />
                )}
            </Fragment>
        );
    }
}

AccountBillsList.propTypes = {
    loading: PropTypes.bool,
    bills: PropTypes.array.isRequired
};

AccountBillsList.defaultProps = {
    loading: false,
    bills: []
};

export default withTranslation()(AccountBillsList);
