import { ThemedListContainer, ThemedListItem } from 'common/styles';
import AccountTypeIcon from 'common/components/AccountTypeIcon';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Displays a row in an {@link AccountList}.
 */
function AccountListItem(props) {
    const {
        account = props.account || {},
        onClickAccount
    } = props;

    const { t } = useTranslation();
    const title = account.getDisplayName && account.getDisplayName(t);
    const subtitle = account.getSubTitle &&  account.getSubTitle(t);

    return (
        <ThemedListItem
            key={account.accountNumber}
            button
            onClick={() => onClickAccount(account.accountNumber)}
        >
            <AccountTypeIcon accountType={account.type} />
            <ListItemText primary={title} secondary={subtitle} />
        </ThemedListItem>
    );
}

AccountListItem.propTypes = {
    account: PropTypes.object.isRequired,
    onClickAccount: PropTypes.func.isRequired
};

/**
 * Displays a list of accounts in session.
 */
function AccountList(props) {
    const {
        accounts = props.accounts || [],
        onClickAccount,
    } = props;

    return (
        <ThemedListContainer>
            <List>
                {accounts.map(account => {
                    return (
                        <AccountListItem
                            key={account.accountNumber}
                            account={account}
                            onClickAccount={onClickAccount}
                        />
                    );
                })}
            </List>
        </ThemedListContainer>
    );
}

AccountList.propTypes = {
    accounts: PropTypes.array.isRequired,
    onClickAccount: PropTypes.func.isRequired
};

export default AccountList;
