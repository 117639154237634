import GridContainer from 'common/components/GridContainer';
import GridItem from 'common/components/GridItem';
import Hidden from '@material-ui/core/Hidden';
import Page from 'common/components/Page';
import React from 'react';
import styled from 'theme';
import { ThemedPageHeader } from 'common/styles';
import { withTranslation } from 'react-i18next';

const CenterCol = styled(GridItem)`
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const CenteredHeader = styled(ThemedPageHeader)`
    margin-top: 100px;
    text-align: center;
`;
const BadRecptionImage = styled.img`
    height: 154px;
    width: 154px;
    margin-top: 20px;
    margin-bottom: 20px;
`;
const Message = styled.p`
    text-align: center;
    font-size: 20px;
`;

class NotFoundPage extends React.Component {
    render() {
        const { t } = this.props;

        return (
            <Page>
                <GridContainer>
                    <Hidden smDown={true}>
                        <GridItem xs={3} />
                    </Hidden>
                    <CenterCol xs={12} md={6}>
                        <CenteredHeader>{t('notFound.header')}</CenteredHeader>
                        <BadRecptionImage src="/img/bad_reception.svg" />
                        <Message>{t('notFound.message')}</Message>
                    </CenterCol>
                </GridContainer>
            </Page>
        );
    }
}

export default withTranslation()(NotFoundPage);
