import AppConstants from 'AppConstants.js';
import Divider from '@material-ui/core/Divider';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'theme';
import { useTranslation } from 'react-i18next';

const DividerContainer = styled.div`
    margin: 16px 0 8px 0;
`;

const Subheader = styled.div`
    color: ${AppConstants.COLOR_TEXT_SUB};
    font-size: 12px;
`;

function DividerWithSubheader({ resourceId }) {
    const { t } = useTranslation();

    return (
        <DividerContainer>
            <Divider />
            <Subheader>{t(resourceId)}</Subheader>
        </DividerContainer>
    );
}

DividerWithSubheader.propTypes = {
    resourceId: PropTypes.string.isRequired
};

export default DividerWithSubheader;
