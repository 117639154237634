/**
 * A comparator for sorting payment methods.
 *
 * @param a {PaymentMethod} The first payment method.
 * @param b {PaymentMethod} The second payment method.
 * @param t The i18next instance.
 * @returns {number} The result of comparison.
 */
function comparePaymentMethods(a, b, t) {
    const titleA = a.getDisplayName(t);
    const titleB = b.getDisplayName(t);

    if (titleA < titleB) {
        return -1;
    }

    if (titleA > titleB) {
        return 1;
    }

    return 0;
}

export { comparePaymentMethods };
