import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import ContainerBase from 'common/containers/ContainerBase';
import GridContainer from 'common/components/GridContainer';
import GridItem from 'common/components/GridItem';
import Hidden from '@material-ui/core/Hidden';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { mapStateToProps } from 'reducers';
import Markdown from 'markdown-to-jsx';
import MobileStepper from '@material-ui/core/MobileStepper';
import Page from 'common/components/Page';
import React from 'react';
import styled from 'theme';
import SwipeableViews from 'react-swipeable-views';
import { withTranslation } from 'react-i18next';
import { WizardLaunchSource } from 'features/wizard/WizardContextBuilder';

const WizardIconHolder = styled(GridItem)`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const WizardIcon = styled.img`
    height: 150px;
    width: 150px;
`;

const TitleAndDescription = styled(GridItem)`
    display: flex;
    justify-content: center;
    flex-direction: column;
`;

const Title = styled.h3`
    font-weight: 500;
    text-transform: uppercase;
`;

const Description = styled(Markdown)`
    padding-bottom: 27px;
`;

const ItemWrapper = styled.div`
    margin-top: 24px;
`;

const ActionButton = styled(Button)`
    && {
        margin-right: 10px;
        margin-left: 10px;
    }
`;

const ActionBar = styled.div`
    margin-top: 20px;
    margin-bottom: 100px;
    display: flex;
    justify-content: center;
`;

const Wrapper = styled.div`
    margin-top: 75px;
`;

const SlideContentWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    min-height: 80vh;
`;

const SlideTitle = styled(Title)`
    flex-grow: 1;
    display: flex;
    align-items: center;
`;

const SlideDescription = styled(Markdown)`
    text-align: center;
    flex-grow: 3;
    padding-left: 24px;
    padding-right: 24px;
`;

const LastSlideDescription = styled(SlideDescription)`
    align-items: center;
    display: flex;
    && {
        flex-grow: 10;
    }
`;

const SlideWizardIconHolder = styled(WizardIconHolder)`
    && {
        flex-grow: 3;
    }
`;

const SlideButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
`;

const FormattedPage = styled(Page)`
    flex-direction: column;
`;

const SlideButtons = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-around;
`;

const Spacer = styled.div`
    flex-grow: 5;
`;

const CustomMobileStepper = styled(MobileStepper)`
    && {
        background-color: #fff;
    }
`;

/**
 * An action button that is rendered in the last step (slide) of the mobile
 * view. Used for navigating the user to the next part of the wizard process
 * (link membership or link account).
 */
function SlideActionButton(props) {
    const {
        label,
        onClick,
        style
    } = props;

    return (
        <SlideButtonWrapper>
            <ActionButton
                style={{ ...style, width: '100%' }}
                variant="contained"
                onClick={onClick}
                color="primary"
            >
                {label}
            </ActionButton>
        </SlideButtonWrapper>
    );
}

/**
 * Renders a step in the mobile view, which is depicted as a slide in a swipe
 * view container.
 */
function Slide(props) {
    const {
        description,
        src,
        title
    } = props;

    return (
        <SlideContentWrapper>
            <SlideTitle>{title}</SlideTitle>
            <SlideWizardIconHolder xs={12} sm={false}>
                <WizardIcon src={src} />
            </SlideWizardIconHolder>
            <SlideDescription>{description}</SlideDescription>
        </SlideContentWrapper>
    );
}

/**
 * Renders the last step in the mobile view, which has a different content
 * structure from a normal <Slide/>.
 */
function LastSlide(props) {
    const {
        children,
        description
    } = props;

    return (
        <SlideContentWrapper>
            <LastSlideDescription>{description}</LastSlideDescription>
            <SlideButtons>{children}</SlideButtons>
            <Spacer />
        </SlideContentWrapper>
    );
}

/**
 * Renders a step in the tablet and desktop views, which is depicted as a row in
 * a vertical list.
 */
function Row(props) {
    const {
        description,
        src,
        title
    } = props;

    return (
        <ItemWrapper>
            <GridContainer>
                <GridItem xs={false} sm={1} md={3} />
                <TitleAndDescription xs={false} sm={6} md={4}>
                    <Title>{title}</Title>
                    <Description>{description}</Description>
                </TitleAndDescription>
                <WizardIconHolder xs={false} sm={4} md={2}>
                    {src && <WizardIcon src={src} />}
                </WizardIconHolder>
                <GridItem xs={false} sm={1} md={3} />
            </GridContainer>
        </ItemWrapper>
    );
}

class WizardIntroContainer extends ContainerBase {
    constructor(props) {
        super(props, {
            activeStep: 0,
            maxSteps: 4
        });
    }

    handleNext = () => {
        this.setState(prevState => ({ activeStep: prevState.activeStep + 1 }));
    };

    handleBack = () => {
        this.setState(prevState => ({ activeStep: prevState.activeStep - 1 }));
    };

    handleStepChange = activeStep => {
        this.setState({ activeStep });
    };

    navToLinkMembership = () => {
        const { history } = this.props;

        history.push('/wizard/link-membership', { launchSource: WizardLaunchSource.WIZARD_LINK_MEMBERSHIP });
    };

    navToLinkAccount = () => {
        const { history } = this.props;

        history.push('/wizard/link-account', { launchSource: WizardLaunchSource.WIZARD_LINK_ACCOUNT });
    };

    render() {
        const { t } = this.props;

        const {
            activeStep,
            maxSteps
        } = this.state;

        const rows = [
            {
                title: t('wizard.link.header'),
                description: t('wizard.link.body'),
                src: '/img/wizard_intro_link.svg'
            },
            {
                title: t('wizard.linkMembership.header'),
                description: t('wizard.linkMembership.body'),
                src: '/img/wizard_intro_membership.svg'
            },
            {
                title: t('wizard.linkAccount.header'),
                description: t('wizard.linkAccount.body'),
                src: '/img/wizard_intro_account.svg'
            }
        ];

        const Rows = rows.map((value, key) => (
            <Row
                key={`Row${key}`}
                title={value.title}
                description={value.description}
                src={value.src}
            />
        ));

        const Slides = rows.map((value, key) => (
            <div key={`SlideWrapper${key}`}>
                <Slide title={value.title} description={value.description} src={value.src} />
            </div>
        ));

        return (
            <FormattedPage>
                <Hidden xsDown={true} key="DesktopView">
                    <Wrapper>
                        {Rows}
                        <ActionBar>
                            <ActionButton
                                key="Button0"
                                variant="contained"
                                onClick={this.navToLinkMembership}
                                color="primary"
                            >
                                {t('wizard.linkMembership.header')}
                            </ActionButton>
                            <ActionButton
                                key="Button1"
                                variant="contained"
                                onClick={this.navToLinkAccount}
                                color="secondary"
                            >
                                {t('wizard.linkAccount.header')}
                            </ActionButton>
                        </ActionBar>
                    </Wrapper>
                </Hidden>
                <Hidden smUp={true} key="MobileView">
                    <SwipeableViews
                        key="swipeableViews"
                        index={activeStep}
                        onChangeIndex={this.handleStepChange}
                        enableMouseEvents
                    >
                        {Slides}
                        <div key="SlideWrapperLast">
                            <LastSlide title="" description={t('wizard.addAfterWizard')} src="">
                                <SlideActionButton
                                    key="Button2"
                                    variant="contained"
                                    onClick={this.navToLinkMembership}
                                    color="primary"
                                    label={t('wizard.linkMembership.header')}
                                />
                                <SlideActionButton
                                    key="Button3"
                                    variant="contained"
                                    onClick={this.navToLinkAccount}
                                    color="primary"
                                    label={t('wizard.linkAccount.header')}
                                />
                            </LastSlide>
                        </div>
                    </SwipeableViews>
                    <CustomMobileStepper
                        key="mobileStepper"
                        variant="dots"
                        steps={maxSteps}
                        position="static"
                        activeStep={activeStep}
                        nextButton={
                            <Button
                                key="Button4"
                                size="small"
                                onClick={this.handleNext}
                                disabled={activeStep === maxSteps - 1}
                            >
                                {t('common.next')}
                                <KeyboardArrowRight />
                            </Button>
                        }
                        backButton={
                            <Button
                                key="Button5"
                                size="small"
                                onClick={this.handleBack}
                                disabled={activeStep === 0}
                            >
                                <KeyboardArrowLeft />
                                {t('common.back')}
                            </Button>
                        }
                    />
                </Hidden>
            </FormattedPage>
        );
    }
}

export default connect(mapStateToProps)(withTranslation()(WizardIntroContainer));
