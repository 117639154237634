import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import React from 'react';
import { ThemedTextField } from 'common/styles';

/**
 * See https://github.com/s-yadav/react-number-format#readme for more
 * information about NumberFormat options.
 * @param {*} props
 */
function NumberFormatCustom(props) {
    const {
        inputRef,
        onChange,
        ...other
    } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => { onChange({ target: { value: values.value }}); }}
            thousandSeparator={true}
            prefix="$"
            decimalScale={2}
            fixedDecimalScale={true}
        />
    );
}

NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired
};

export default function CurrencyField(props) {
    return (
        <ThemedTextField
            {...props}
            type="text"
            InputProps={{ inputComponent: NumberFormatCustom }}
        />
    );
}
