import { DialogBasic, DialogConfirm } from '../../../common/components/dialogs';
import { EmptyContainer, EmptyImage, EmptyMessage } from 'common/styles';
import React, { Fragment } from 'react';
import { refreshData, updateOutageStatus } from '../../../actions';
import styled, { materialUiTheme } from '../../../theme';
import AccountTypeIcon from '../../../common/components/AccountTypeIcon';
import AppConstants from '../../../AppConstants.js';
import Card from '@material-ui/core/Card/Card';
import { connect } from 'react-redux';
import ContainerBase from '../../../common/containers/ContainerBase';
import CurrencyAmount from '../../../common/components/CurrencyAmount';
import { DueDate } from '../../../common/components/due-date';
import FireflyAuthenticatedApi from '../../../api/FireflyAuthenticatedApi';
import GetUserTask from '../../../common/tasks/GetUserTask';
import GridContainer from '../../../common/components/GridContainer';
import GridItem from '../../../common/components/GridItem';
import Hidden from '@material-ui/core/Hidden/Hidden';
import IconButton from '@material-ui/core/IconButton';
import LoadingIndicator from '../../../common/components/LoadingIndicator';
import { mapStateToProps } from '../../../reducers';
import { NavButton } from '../../../common/components/nav-button';
import OutageStatus from '../components/OutageStatus';
import Page from '../../../common/components/Page';
import RefreshIcon from '@material-ui/icons/Refresh';
import { ThemedPageHeader } from '../../../common/styles';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import { withTranslation } from 'react-i18next';

const LeftColumnButtonWidth = '40%';

const MobilePage = styled(Page)`
    && {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        background-color: ${AppConstants.COLOR_TEXT_INPUT_BACKGROUND};
        padding: 16px;
    }
`;

const RightColumn = styled.div`
    background-color: ${AppConstants.COLOR_TEXT_INPUT_BACKGROUND};
    width: 66.6666%;
    padding: 30px 36px;
    ${materialUiTheme.breakpoints.down('xs')} {
        width: 100%;
    }
`;

const LeftColumn = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 33.3333%;
`;

const LeftColumnCard = styled(LeftColumn)`
    width: 41px;
    && {
        margin-right: 16px;
    }
`;

const CustomHeader = styled(ThemedPageHeader)`
    margin-bottom: 0;
`;

const FlexRowCentered = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
`;

const HeaderRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    margin: -8px 0 4px 0;
`;

const LoadingContainer = styled(FlexRowCentered)`
    padding-top: 140px;
    ${materialUiTheme.breakpoints.down('xs')} {
        padding-top: 60px;
    }
`;

const AmountDueContainer = styled(FlexRowCentered)`
    flex-wrap: wrap;
    width: 80%;
    ${materialUiTheme.breakpoints.down('xs')} {
        width: 100%;
    }
`;

const AmountDueAmountWrapper = styled(FlexRowCentered)`
    width: 100%;
    margin-bottom: -8px;
`;

const AmountDueCurrencySign = styled.div`
    font-size: 1.6rem;
    color: ${AppConstants.COLOR_PRIMARY};
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 3px;
    ${materialUiTheme.breakpoints.down('xs')} {
        color: white;
    }
`;

const AmountDueAmount = styled.div`
    font-size: 3rem;
    color: ${AppConstants.COLOR_PRIMARY};
    display: inline-flex;
    justify-content: flex-start;
    align-content: baseline;
    ${materialUiTheme.breakpoints.down('xs')} {
        color: white;
    }
`;

const AmountDueSubtext = styled(FlexRowCentered)`
    width: 100%;
    color: ${AppConstants.COLOR_TEXT_SUB};
    font-style: italic;
    ${materialUiTheme.breakpoints.down('xs')} {
        color: white;
    }
`;

const PayBillButton = styled(NavButton)`
    width: ${LeftColumnButtonWidth};
    && {
        min-width: 165px;
        ${materialUiTheme.breakpoints.down('xs')} {
            min-width: 145px;
            margin-top: 16px;
        }
    }
`;

const OutageMapButton = styled(NavButton)`
    width: ${LeftColumnButtonWidth};
    && {
        min-width: 175px;
        ${materialUiTheme.breakpoints.down('xs')} {
            margin-top: 16px;
        }
    }
`;

const OutageMapIcon = styled.img`
    height: 18px;
    width: 18px;
    margin-right: 4px;
`;

const FlexCard = styled(Card)`
    && {
        display: flex;
        padding: 16px;
        margin-bottom: 8px;
        &:last-child {
            margin-bottom: 0;
        }
        ${materialUiTheme.breakpoints.down('xs')} {
            padding: 16px;
            width: 100%;
        }
    }
`;

const TitleCard = styled(FlexCard)`
    position: relative;
    && {
        background-image: url('/img/dashboard.png');
        background-size: cover;
        background-position: bottom calc(100% + 61px) right 0;
        min-height: 300px;
        padding-top: 48px;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }
`;

const IconButtonPhone = styled(IconButton)`
    && {
        position: absolute;
        right: 16px;
        top: 16px;
        color: #ffffff;
    }
`;

const Spacer = styled.div`
    height: ${props => props.height};
`;

const StyledTitle = styled.div`
    font-size: 1rem;
    font-weight: 500;
    width: 100%;
`;

const StyledSubtitle = styled.div`
    font-size: 0.8rem;
    color: ${AppConstants.COLOR_TEXT_SUB};
    padding-bottom: 5px;
`;

const StyledAccountWrapper = styled.div`
    display: flex;
    flex-grow: 10;
    flex-direction: column;
`;

const StyledAmountDueColumn = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex-grow: 1;
    min-width: 60px;
`;

const AccountListContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
`;

const AccountTitle = ({ account, t }) => {
    return <StyledTitle>{account.getDisplayName(t)}</StyledTitle>;
};

const AccountSubtitle = ({ account, t }) => {
    return (
        <StyledSubtitle>
            {t('common.accounts.subtitle', { accountNumber: account.accountNumber })}
        </StyledSubtitle>
    );
};

const AccountDetailWrapper = ({ account, t, onclick }) => {
    return (
        <StyledAccountWrapper>
            <AccountTitle account={account} t={t} />
            <AccountSubtitle account={account} t={t} />
            <OutageStatus account={account} t={t} onclick={onclick} />
        </StyledAccountWrapper>
    );
};

const AmountDueColumn = ({ account }) => {
    return (
        <StyledAmountDueColumn>
            <CurrencyAmount amount={account.amountDue} />
        </StyledAmountDueColumn>
    );
};

class HomeContainer extends ContainerBase {
    constructor(props, state) {
        const {
            appState,
            dispatch
        } = props;

        super(props);
        this.state = {
            ...state,
            accountDisconnectedDialogIsOpen: false,
            basicDialogIsOpen: false,
            errorMessageTitle: '',
            errorMessageBody: '',
            outageReportedDialogIsOpen: false,
            processing: false,
            reportOutageDialogIsOpen: false,
            selectedAccountLabel: null
        };

        this.state.noMoreCharge = appState.noMoreCharge;
        this.state.noMoreChecks = appState.noMoreChecks;

        this.api = new FireflyAuthenticatedApi({ appState, dispatch });
    }

    componentDidMount() {
        const {
            appState = this.props.appState || {}
        } = this.props;

        if (appState.isStale && appState.isStale()) {
            this.refreshData();
        }
    }

    handlePayBill = () => {
        const { noMoreCharge, noMoreChecks } = this.state;
        const { history } = this.props;

        if (noMoreCharge && noMoreChecks) {
            this.setState({
                errorMessageTitle: 'billing.common.unableToPay.title',
                errorMessageBody: 'billing.common.unableToPay.message'
            });
            this.showBasicDialog();
        } else {
            history.push('/billing/make-payment');
        }
    }

    render() {
        const {
            appState,
            t
        } = this.props;

        const {
            accountDisconnectedDialogIsOpen,
            basicDialogIsOpen,
            errorMessageBody,
            errorMessageTitle,
            processing,
            reportOutageDialogIsOpen,
            selectedAccountLabel,
            outageReportedDialogIsOpen
        } = this.state;

        this.t = t;

        const accounts = (appState && appState.accounts) || [];
        const hasAccountsToDisplay = accounts.length > 0;

        const amountDue = appState.amountDue;
        const amountDueDate = (appState && appState.amountDueDate) || undefined;
        const amountDueDateType = (appState && appState.amountDueDateType) || '';
        const amountDueLabel = <DueDate amountDueDate={amountDueDate} amountDueDateType={amountDueDateType} />;

        return (
            <Fragment>
                <Hidden xsDown={true}>
                    <Page padding="none">
                        <LeftColumn>
                            <Spacer height="112px" />
                            <AmountDueContainer>
                                <AmountDueAmountWrapper>
                                    <AmountDueCurrencySign>
                                        $
                                    </AmountDueCurrencySign>
                                    <AmountDueAmount>
                                        <CurrencyAmount amount={amountDue} includeCurrencySymbol={false} />
                                    </AmountDueAmount>
                                </AmountDueAmountWrapper>
                                <AmountDueSubtext>
                                    {amountDueLabel}
                                </AmountDueSubtext>
                            </AmountDueContainer>
                            <Spacer height="32px" />

                            {amountDue > 0 ? (
                                <div>
                                    <PayBillButton
                                        color="primary"
                                        disabled={false}
                                        onClick={this.handlePayBill}
                                        variant="contained"
                                    >
                                        {t('home.payBill')}
                                    </PayBillButton>
                                    <Spacer height="12px" />
                                </div>
                            ) : (
                                ''
                            )}

                            <OutageMapButton
                                color="primary"
                                disabled={false}
                                href={AppConstants.OUTAGE_MAP_URL}
                                rel="noopener noreferrer"
                                target="_blank"
                                variant="outlined"
                            >
                                <OutageMapIcon src="/img/outage_map_marker.svg" alt={t('home.outageMap')} />
                                {t('home.outageMap')}
                            </OutageMapButton>
                        </LeftColumn>
                        <RightColumn>
                            <HeaderRow>
                                <CustomHeader>{t('home.header')}</CustomHeader>
                                {!processing && (
                                    <Tooltip title={t('home.refresh')} placement="bottom">
                                        <IconButton onClick={this.refreshData}>
                                            <RefreshIcon />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </HeaderRow>
                            {processing ? (
                                <LoadingContainer>
                                    <LoadingIndicator />
                                </LoadingContainer>
                            ) : hasAccountsToDisplay ? (
                                appState && (appState
                                    .accountsSorted(t)
                                    .map(this.mapAccountsToCards)
                                )
                            ) : (
                                <EmptyContainer>
                                    <EmptyImage src="/img/empty_state_default.svg" />
                                    <EmptyMessage>
                                        {t('common.noAccountsLinkedMessage')}
                                    </EmptyMessage>
                                </EmptyContainer>
                            )}
                        </RightColumn>
                    </Page>
                </Hidden>
                <Hidden smUp={true}>
                    <MobilePage padding="none">
                        <TitleCard>
                            <AmountDueContainer>
                                {!processing && (
                                    <Tooltip title={t('home.refresh')} placement="bottom">
                                        <IconButtonPhone onClick={this.refreshData}>
                                            <RefreshIcon />
                                        </IconButtonPhone>
                                    </Tooltip>
                                )}
                                <AmountDueAmountWrapper>
                                    <AmountDueCurrencySign>
                                        $
                                    </AmountDueCurrencySign>
                                    <AmountDueAmount>
                                        <CurrencyAmount amount={amountDue} includeCurrencySymbol={false} />
                                    </AmountDueAmount>
                                </AmountDueAmountWrapper>
                                <AmountDueSubtext>
                                    {amountDueLabel}
                                </AmountDueSubtext>
                            </AmountDueContainer>
                            {amountDue > 0 ? (
                                <PayBillButton
                                    color="secondary"
                                    disabled={false}
                                    to="/billing/make-payment"
                                    variant="contained"
                                >
                                    {t('home.payBill')}
                                </PayBillButton>
                            ) : (
                                ''
                            )}

                            <OutageMapButton
                                color="secondary"
                                disabled={false}
                                href={AppConstants.OUTAGE_MAP_URL}
                                rel="noopener noreferrer"
                                target="_blank"
                                variant="contained"
                            >
                                <OutageMapIcon src="/img/outage_map_marker.svg" alt={t('home.outageMap')} />
                                {t('home.outageMap')}
                            </OutageMapButton>
                        </TitleCard>
                        <Spacer height="8px" />
                        {processing ? (
                            <LoadingContainer>
                                <LoadingIndicator />
                            </LoadingContainer>
                        ) : hasAccountsToDisplay ? (
                            <AccountListContainer>
                                {appState && appState
                                    .accountsSorted(t)
                                    .map(this.mapAccountsToCards)}
                            </AccountListContainer>
                        ) : (
                            <GridContainer direction="column" alignItems="center">
                                <GridItem xs={12}>
                                    <EmptyImage src="/img/empty_state_default.svg" />
                                </GridItem>
                                <GridItem xs={6}>
                                    <EmptyMessage>
                                        {t('common.noAccountsLinkedMessage')}
                                    </EmptyMessage>
                                </GridItem>
                            </GridContainer>
                        )}
                    </MobilePage>
                </Hidden>
                <DialogBasic
                    message={t(errorMessageBody)}
                    onCloseDialog={this.closeBasicDialog}
                    open={basicDialogIsOpen}
                    title={t(errorMessageTitle)}
                />
                <DialogBasic
                    message={t('home.outageReportedBasicModal.message', { account: selectedAccountLabel })}
                    onCloseDialog={this.closeOutageReportedDialog}
                    open={outageReportedDialogIsOpen}
                    title={t('home.outageReportedBasicModal.title')}
                />
                <DialogBasic
                    message={t('home.accountDisconnectedBasicModal.message')}
                    onCloseDialog={this.closeAccountDisconnectedDialog}
                    open={accountDisconnectedDialogIsOpen}
                    title={t('home.accountDisconnectedBasicModal.title')}
                />
                <DialogConfirm
                    confirmButtonLabel={t('home.reportOutageConfirmModal.confirmButton')}
                    message={t('home.reportOutageConfirmModal.message', { account: selectedAccountLabel })}
                    onCloseDialog={this.closeReportOutageDialog}
                    open={reportOutageDialogIsOpen}
                    title={t('home.reportOutageConfirmModal.title')}
                />
            </Fragment>
        );
    }

    mapAccountsToCards = it => {
        return (
            <FlexCard key={`card_${it.accountNumber}`}>
                <LeftColumnCard>
                    <AccountTypeIcon
                        key={`typeIcon_${it.accountNumber}`}
                        accountType={it.type}
                        alt={it.type}
                    />
                </LeftColumnCard>
                <AccountDetailWrapper
                    key={`accountWrapper_(${it.accountNumber}`}
                    account={it}
                    t={this.t}
                    onclick={this.onClickReportOutage}
                />
                <AmountDueColumn account={it} />
            </FlexCard>
        );
    }

    onClickReportOutage = (e, account = {}) => {
        const {
            t
        } = this.props;

        const {
            accountNumber,
            alias,
            disconnected,
            type,
            outageStatus,
        } = account;

        e.preventDefault();

        let accountLabel = alias;
        if (accountLabel === null) {
            accountLabel = t('common.accounts.titleNoAlias', {
                accountNumber,
                type: t('common.accounts.types.' + type)
            });
        }

        if (disconnected === true) {
            this.setState({ accountDisconnectedDialogIsOpen: true });
        } else if (outageStatus === 'unknown') {
            this.showReportOutageDialog(accountNumber, accountLabel);
        } else {
            this.showOutageReportedDialog(accountLabel);
        }
    }

    showReportOutageDialog = (accountNumber, accountLabel) => {
        this.setState({
            reportOutageDialogIsOpen: true,
            selectedAccountNumber: accountNumber,
            selectedAccountLabel: accountLabel
        });
    }

    closeReportOutageDialog = wasConfirmed => {
        const { selectedAccountNumber } = this.state;

        const accountNumber = selectedAccountNumber;

        this.setState({
            reportOutageDialogIsOpen: false,
            selectedAccountNumber: null,
            selectedAccountLabel: null
        });

        if (wasConfirmed) {
            this.onReportOutageDialogClosed(accountNumber);
        }
    }

    onReportOutageDialogClosed(accountNumber) {
        this.reportOutage(accountNumber);
    }

    showOutageReportedDialog = accountLabel => {
        this.setState({
            outageReportedDialogIsOpen: true,
            selectedAccountLabel: accountLabel
        });
    }

    closeOutageReportedDialog = () => {
        this.setState({
            outageReportedDialogIsOpen: false,
            selectedAccountLabel: null
        });
    }

    closeAccountDisconnectedDialog = () => {
        this.setState({
            accountDisconnectedDialogIsOpen: false,
        });
    }

    reportOutage = accountNumber => {
        this.setState({ processing: true });

        this.api
            .reportOutage(accountNumber)
            .then(() => {
                this.props.dispatch(
                    updateOutageStatus(accountNumber, 'assumed')
                );

                this.setState({
                    processing: false,
                    isReportOutageSuccessSnackbarOpen: true,
                    smsPhoneNumber: null
                });
            })
            .catch(error => {
                this.setState({ processing: false });
                this.showErrorDialog(error);
            });
    }

    refreshData = () => {
        const { dispatch } = this.props;

        this.setState({ processing: true });

        const getUserTask = new GetUserTask(this.api);
        getUserTask
            .execute()
            .then(response => {
                dispatch(refreshData(response.user, response.accountDetails));
                this.setState({ processing: false });
            })
            .catch(error => {
                this.setState({ processing: false });
                this.showErrorDialog(error);
            });
    }
}

export default connect(mapStateToProps)(withTranslation()(HomeContainer));
