import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Hidden from '@material-ui/core/Hidden';
import { mapStateToProps } from 'reducers';
import { Redirect } from 'react-router-dom';


class UsageContainer extends React.Component {
    render() {
        return (
            <Fragment>
                <Hidden xsDown={true}>
                    <Redirect to="/usage/electric" />
                </Hidden>
            </Fragment>
        );
    }
}

export default connect(mapStateToProps)(UsageContainer);
