import PropTypes from 'prop-types';

/**
 * @return {string}
 */
function CurrencyAmount(props) {
    const { amount, includeCurrencySymbol } = props;

    if (amount === null || amount === undefined) {
        return '';
    }

    const currencySymbol = includeCurrencySymbol ? '$' : '';
    let amountToFormat = amount;
    let amountSuffix = '';
    if (amount < 0) {
        amountToFormat = amount * -1;
        amountSuffix = ' CR';
    }

    const formattedAmount = amountToFormat.toFixed(2);

    return `${currencySymbol}${formattedAmount}${amountSuffix}`;
}

CurrencyAmount.propTypes = {
    amount: PropTypes.number,
    includeCurrencySymbol: PropTypes.bool
};

CurrencyAmount.defaultProps = {
    includeCurrencySymbol: true
};

export default CurrencyAmount;
