import Button from '@material-ui/core/Button';
import CircularProgress from "@material-ui/core/CircularProgress";
import PropTypes from "prop-types";
import React from "react";
import styled from "../../theme";

const DebounceButtonContainer = styled.span`
    position: relative;
`;
const CenteredCircularProgress = styled(CircularProgress)`
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -12px;
    margin-left: -12px;
`;

/*
* This component uses JS Timeouts to prevent multiple successive button clicks.
* Set the `debounceTime` prop to the desired amount of wait-time between
* clicks.
*/
class DebounceButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            waiting: false,
            debounceTimeout: -1
        };
    }

    componentWillUnmount() {
        const { debounceTimeout } = this.state;
        clearTimeout(debounceTimeout);
    }

    handleClick = (e) => {
        const { waiting } = this.state;
        const {
            debounceTime,
            onClick
        } = this.props;

        if (waiting) {
            return;
        }

        this.wait();
        const timeout = setTimeout(this.stopWaiting, debounceTime);
        this.setState(prevState => ({...prevState, debounceTimeout: timeout}));

        onClick(e);
    }

    wait = () => {
        this.setState(prevState => ({ ...prevState, waiting: true }));
    }

    stopWaiting = () => {
        this.setState(prevState => ({ ...prevState, waiting: false }));
    }

    render() {
        const {
            children,
            color,
            loading
        } = this.props;

        return (
            <DebounceButtonContainer>
                <Button color={color} disabled={loading} onClick={e => this.handleClick(e)}>
                    {children}
                </Button>
                {loading && <CenteredCircularProgress size={24} />}
            </DebounceButtonContainer>
        );
    }
}

DebounceButton.propTypes = {
    debounceTime: PropTypes.number,
    loading: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
};

DebounceButton.defaultProps = {
    debounceTime: 0,
    loading: false
};

export default DebounceButton;
