import { SingleColumnForm, ThemedPageHeader, ThemedTextField } from 'common/styles';
import { connect } from 'react-redux';
import ContainerBase from 'common/containers/ContainerBase';
import ErrorMessage from 'common/components/ErrorMessage';
import FireflyAuthenticatedApi from 'api/FireflyAuthenticatedApi';
import HelperText from 'common/components/HelperText';
import LeftNav from 'common/components/LeftNav';
import { mapStateToProps } from 'reducers';
import { NavButton } from 'common/components/nav-button';
import { navigations } from 'features/settings/containers/SettingsContainer';
import Page from 'common/components/Page';
import ProgressButton from 'common/components/ProgressButton';
import React from 'react';
import styled from 'theme';
import Validator from 'validation/Validator';
import { withTranslation } from 'react-i18next';

const ButtonRow = styled.div`
    display: flex;
    justify-content: flex-start;
    margin-top: 16px;
`;
const CancelButton = styled(NavButton)`
    && {
        margin-left: 20px;
    }
`;
const SaveButton = styled(ProgressButton)`
    && {
        width: 100px;
    }
`;

class ChangePasswordContainer extends ContainerBase {
    constructor(props) {
        super(props, {
            oldPassword: '',
            oldPasswordError: null,
            newPassword: '',
            newPasswordError: null,
            confirmPassword: '',
            confirmPasswordError: null
        });

        this.validator = new Validator(this);
    }

    componentDidMount() {
        this.validator.maybeFocus();
    }

    componentDidUpdate() {
        this.validator.maybeFocus();
    }

    render() {
        const {
            t
        } = this.props;

        const {
            confirmPassword,
            confirmPasswordError,
            loading,
            newPassword,
            newPasswordError,
            oldPassword,
            oldPasswordError
        } = this.state;

        return (
            <Page>
                <LeftNav
                    title="settings.header"
                    to="/settings"
                    navigations={navigations}
                    select="/settings/user-profile"
                >
                    <SingleColumnForm>
                        <ThemedPageHeader>
                            {t('settings.changePassword.header')}
                        </ThemedPageHeader>

                        <form onSubmit={this.submit}>
                            <ThemedTextField
                                id="oldPassword"
                                inputRef={this.oldPasswordFocus}
                                type="password"
                                label={t('settings.changePassword.oldPasswordLabel')}
                                disabled={loading}
                                value={oldPassword}
                                onChange={this.handleInputChange('oldPassword')}
                                error={oldPasswordError !== null}
                            />

                            <ErrorMessage resourceId={oldPasswordError} />

                            <ThemedTextField
                                id="newPassword"
                                inputRef={this.newPasswordFocus}
                                type="password"
                                label={t('settings.changePassword.newPasswordLabel')}
                                disabled={loading}
                                value={newPassword}
                                onChange={this.handleInputChange('newPassword')}
                                error={newPasswordError !== null}
                            />

                            <ErrorMessage resourceId={newPasswordError} />

                            <HelperText resourceId="createUser.passwordHelperText" />

                            <ThemedTextField
                                id="confirmPassword"
                                inputRef={this.confirmPasswordFocus}
                                type="password"
                                label={t('settings.changePassword.confirmPasswordLabel')}
                                disabled={loading}
                                value={confirmPassword}
                                onChange={this.handleInputChange('confirmPassword')}
                                error={confirmPasswordError !== null}
                            />

                            <ErrorMessage resourceId={confirmPasswordError} />

                            <ButtonRow>
                                <SaveButton
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    loading={loading}
                                    onClick={this.submit}
                                >
                                    {t('common.saveButton')}
                                </SaveButton>

                                <CancelButton color="primary" disabled={loading} to="/settings/user-profile">
                                    {t('common.cancel')}
                                </CancelButton>
                            </ButtonRow>
                        </form>
                    </SingleColumnForm>
                </LeftNav>
            </Page>
        );
    }

    handleInputChange = name => ({ target } = {}) => {
        this.setState({ [name]: target.value });
    }

    configureValidations(config) {
        config.requireNotEmpty('oldPassword');
        config.requirePassword('newPassword');
        config.requireConfirmPassword('confirmPassword', 'newPassword');
    }

    submit = e => {
        const {
            appState,
            dispatch,
            history,
        } = this.props;

        const {
            confirmPassword,
            newPassword,
            oldPassword
        } = this.state;
        e.preventDefault();

        if (!this.validator.validate()) {
            return;
        }

        this.setState({ loading: true });
        const api = new FireflyAuthenticatedApi({ appState, dispatch });
        api.updatePassword(oldPassword, newPassword, confirmPassword)
            .then(() => {
                history.push('/settings/user-profile', { showChangePasswordSuccessSnackbar: true });
            })
            .catch(error => {
                switch (error.status) {
                    case 422:
                        if (
                            error.response.errors &&
                            error.response.errors[0].field === 'old_password' &&
                            error.response.errors[0].error_code === 'invalid'
                        ) {
                            this.showFieldError(
                                'oldPassword',
                                'settings.changePassword.error.oldPasswordIncorrect'
                            );
                        } else {
                            this.setState({ loading: false });
                            this.showErrorDialog(error);
                        }
                        break;
                    default:
                        this.setState({ loading: false });
                        this.showErrorDialog(error);
                }
            });
    }
}

export default connect(mapStateToProps)(withTranslation()(ChangePasswordContainer));
